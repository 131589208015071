import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import useIsTabletSize from "../../../customHooks/isTabletSizeHook";
import "./FullscreenControlButtons.css";
const FullscreenControlButtons = ({
  isFullscreen,
  enterFullscreen,
  exitFullscreen,
}) => {
  const isTabletSize = useIsTabletSize();

  return (
    <div
      className={`w-100 ${
        isTabletSize ? "" : "full-screen-btn-cont"
      } text-center`}
    >
      {isFullscreen ? (
        <button
          aria-label="exit"
          onClick={exitFullscreen}
          className={`btn btn-small exit-full-screen-btn ${
            isTabletSize ? "ex-full-scrn-btn" : "ex-full-scrn-btn-mob"
          } `}
        >
          <FontAwesomeIcon icon={faCompress} className="mx-1" />
          Exit Fullscreen
        </button>
      ) : (
        <button
          aria-label="exit"
          onClick={enterFullscreen}
          className={`btn btn-small enter-full-screen-btn ${
            isTabletSize ? "ent-full-scrn-btn" : "ent-full-scrn-btn-mob"
          } `}
        >
          <FontAwesomeIcon icon={faExpand} className="mx-1" />
          Enter Fullscreen
        </button>
      )}
    </div>
  );
};

export default FullscreenControlButtons;
FullscreenControlButtons.propTypes = {
  isFullscreen: PropTypes.bool.isRequired,
  enterFullscreen: PropTypes.func.isRequired,
  exitFullscreen: PropTypes.func.isRequired,
};
