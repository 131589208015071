import { Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import PropTypes from "prop-types";
import React from "react";
import ModalCloseButton from "../../Buttons/ModalCloseButton/ModalCloseButton";
import ReportObjectiveQuestionForm from "../../Forms/ReportQuestionForm/ReportQuestionForm";
import "./ReportAQuestionModal.css";

export default function ReportAQuestionModal({
  questionId,
  userUid,
  shouldShowReportModal,
  setShouldShowReportModal,
  dBPath,
}) {
  ReportAQuestionModal.propTypes = {
    questionId: PropTypes.string.isRequired,
    userUid: PropTypes.string.isRequired,
    shouldShowReportModal: PropTypes.bool.isRequired,
    setShouldShowReportModal: PropTypes.func.isRequired,
    dBPath: PropTypes.string.isRequired,
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "80vh",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={shouldShowReportModal}
      onClose={() => {
        setShouldShowReportModal(false);
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={shouldShowReportModal}>
        <Box sx={modalStyle}>
          <div className="modal-content report-form-main">
            <div className="d-flex justify-content-between pb-1">
              <p className="report-form-heading modal-header mb-0">
                Report Question
              </p>
              <ModalCloseButton
                onModalClose={() => setShouldShowReportModal(false)}
              />
            </div>
            <ReportObjectiveQuestionForm
              questionId={questionId}
              userUid={userUid}
              setShouldShowReportModal={setShouldShowReportModal}
              dBPath={dBPath}
            />
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
