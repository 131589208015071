import SendIcon from "@mui/icons-material/Send";
import { Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useNetworkHook from "../../../customHooks/useNetworkHook";
import { updateVariableATL1CollectionFStore } from "../../../utils/firebaseFunctions/firestoreFunctions";
import ModalCloseButton from "../../Buttons/ModalCloseButton/ModalCloseButton";
import "./FeedbackFormModal.css";

export default function FeedbackFormModal({
  shouldShowFeedbackModal,
  setShouldShowFeedbackModal,
  userUid,
  interviewFailureErrorData = {},
  roundType,
}) {
  const [interviewFeedback, setInterviewFeedback] = useState(null);
  const [evaluAItorFeedback, setevaluAItoFeedback] = useState(null);
  const [featureSuggestions, setfeatureSuggestions] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { makeNetworkCall } = useNetworkHook();
  const handleFeedbackChange = (value, delongsTo) => {
    if (delongsTo == "interview") {
      setInterviewFeedback(value);
    } else {
      setevaluAItoFeedback(value);
    }
  };

  const renderRadioButtons = (delongsTo) => {
    const radioButtons = [];
    for (let i = 1; i <= 10; i++) {
      radioButtons.push(
        <label key={i} className="radio-button">
          <input
            type="radio"
            name="feedback"
            className="radio-input"
            required
            value={i}
            checked={
              delongsTo == "interview"
                ? interviewFeedback === i
                : evaluAItorFeedback === i
            }
            onChange={() => handleFeedbackChange(i, delongsTo)}
          />
          <span
            className={`radio-circle ${
              (
                delongsTo == "interview"
                  ? interviewFeedback === i
                  : evaluAItorFeedback === i
              )
                ? "radio-selected"
                : ""
            }`}
          >
            {i}
          </span>
        </label>
      );
    }
    return radioButtons;
  };

  const handleSubmitFeedbackForm = async (e) => {
    e.preventDefault();
    makeNetworkCall({
      funcWithInternet: async () => {
        const formData = {
          interviewFeedback: interviewFeedback,
          evaluAItorFeedback: evaluAItorFeedback,
          featureSuggestions: featureSuggestions,
          interviewFailureErrorData: interviewFailureErrorData,
          roundType: roundType,
        };
        console.log("Dwaraka Siri form data: ", formData);
        await updateVariableATL1CollectionFStore({
          data: formData,
          collectionName: `feedback_submissions`,
          documentName: userUid,
          variableName: roundType,
        });
        setShouldShowFeedbackModal(false);
        enqueueSnackbar("Thanks for submitting your feedback", {
          variant: "success", // You can customize the variant
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    });
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "80vh",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={shouldShowFeedbackModal}
      onClose={() => {
        setShouldShowFeedbackModal(false);
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={shouldShowFeedbackModal}>
        <Box sx={modalStyle}>
          <div className="modal-content feedback-form-main">
            <div className="d-flex justify-content-between pb-1">
              <p className="feedback-form-heading modal-header mb-0">
                Feedback Form
              </p>
              <ModalCloseButton
                onModalClose={() => setShouldShowFeedbackModal(false)}
              />
            </div>
            <form onSubmit={handleSubmitFeedbackForm} className="modal-body">
              <h4 className="feedback-form-subheading mt-3">
                Rate your experience in this interview
                <span className="theme-12009d-color">*</span>
              </h4>
              <div className="radio-group my-3 mb-4">
                <div className="radio-line">
                  {renderRadioButtons("interview")}
                </div>
              </div>
              <h4 className="feedback-form-subheading mt-5">
                How likely are you to recommend
                <span className="fW-900"> EvaluAItor </span> to a friend or
                colleague?
                <span className="theme-12009d-color">*</span>
              </h4>
              <div className="radio-group mt-3">
                <div className="radio-line">
                  {renderRadioButtons("evaluAItor")}
                </div>
              </div>
              <h4 className="feedback-form-subheading mt-3 mb-2">
                How could we improve your experience? <small>(optional)</small>
              </h4>
              <textarea
                className="feedback-form-suggestion"
                rows={5}
                value={featureSuggestions}
                placeholder="We'd love to hear your suggestions"
                wrap="soft"
                name="Job Description"
                minLength={150}
                maxLength={200}
                onChange={(e) => {
                  setfeatureSuggestions(e.target.value);
                }}
              />
              <div className="text-center mt-2">
                <button
                  type="submit"
                  aria-label="startSolving"
                  disabled={!interviewFeedback || !evaluAItorFeedback}
                  className={`btn btn-primary my-2 resume-evaluaitor-btn px-4 py-2`}
                  onClick={handleSubmitFeedbackForm}
                >
                  Submit <SendIcon />
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
FeedbackFormModal.propTypes = {
  shouldShowFeedbackModal: PropTypes.bool.isRequired,
  setShouldShowFeedbackModal: PropTypes.func.isRequired,
  interviewFailureErrorData: PropTypes.object,
  roundType: PropTypes.string.isRequired,
  userUid: PropTypes.string.isRequired,
};
