import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./StepperTimer.css";

import {
  faCircleExclamation,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import useIsTabletSize from "../../customHooks/isTabletSizeHook";

export default function StepperTimer({
  steps,
  backgroundColor,
  activeStep,
  percentage,
  timerTime,
}) {
  StepperTimer.propTypes = {
    steps: PropTypes.array,
    backgroundColor: PropTypes.string,
    activeStep: PropTypes.number,
    percentage: PropTypes.number,
    timerTime: PropTypes.number,
  };
  const totalSteps = steps.length;
  const isTabletSize = useIsTabletSize();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flex: "0 0 auto",
          marginTop: "10px",
        }}
      >
        <div style={{ width: !isTabletSize ? "100%" : "80%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                {index < totalSteps - 1 && (
                  <div
                    style={{ flex: 1, marginLeft: "20px", marginRight: "20px" }}
                  ></div>
                )}
              </Step>
            ))}
          </Stepper>
          {activeStep === totalSteps ? (
            <div>
              <Typography variant="h5">Done with the interview</Typography>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                size="xl"
                icon={percentage > 20 ? faStopwatch : faCircleExclamation}
                style={{
                  margin: "0 20px",
                  color: percentage > 20 ? "#12009d" : "red",
                }}
              />
              <ProgressBar
                animated
                now={percentage}
                color={`${percentage > 20 ? "primary" : "danger"}`}
                className="w-100 my-6"
                style={{ backgroundColor, height: "10px" }}
              />

              <strong
                className="bg-white px-3 pill rounded-pill"
                style={{
                  whiteSpace: "nowrap",
                  margin: "0 0 0 20px",
                  color: percentage > 20 ? "#12009d" : "red",
                }}
              >
                {Math.floor(timerTime / 60)}:
                {(timerTime % 60).toString().padStart(2, "0")}
              </strong>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
