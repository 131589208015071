import PropTypes from "prop-types";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import AvatarCallieCoffeeVideo from "../../assets/Videos/AvatarVideos/AvatarCallieCoffeeVideo.webm";
import AvatarCallieListeningVideo from "../../assets/Videos/AvatarVideos/AvatarCallieListeningVideo.webm";
import AvatarCallieNotingVideo from "../../assets/Videos/AvatarVideos/AvatarCallieNotingVideo.webm";
import AvatarCalliePoster from "../../assets/Videos/AvatarVideos/AvatarCalliePoster.png";
import AvatarCallieSpeakingVideo from "../../assets/Videos/AvatarVideos/AvatarCallieSpeakingVideo.webm";
import bgCity from "../../assets/Videos/AvatarVideos/bg_city.mp4";
import bgGround from "../../assets/Videos/AvatarVideos/bg_ground.mp4";
// import bgRain from "../../assets/Videos/AvatarVideos/bg_rain.mp4";
import bgRoad from "../../assets/Videos/AvatarVideos/bg_road.mp4";
import bgSnow from "../../assets/Videos/AvatarVideos/bg_snow.mp4";
import useIsTabletSize from "../../customHooks/isTabletSizeHook";
import { handleMediaPlayError } from "../../utils/helperFunctions";
import "./AvatarVideoPlayer.css";

const AvatarVideoPlayer = React.forwardRef((props, ref) => {
  AvatarVideoPlayer.propTypes = {
    avatarName: PropTypes.string.isRequired,
    onTogglePlay: PropTypes.func,
  };
  AvatarVideoPlayer.displayName = "AvatarVideoPlayer";
  const isTabletSize = useIsTabletSize();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [randomBgVideo, setRandomBgVideo] = useState();
  const [currentAvatarVideo, setCurrentAvatarVideo] = useState();
  const [avatarState, setAvatarState] = useState("speaking");
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timer >= 30) {
        console.log("Timer completed");
        if (avatarState === "listening") {
          setCurrentAvatarVideo(
            avatarNameVideoMap[props.avatarName.toLowerCase()]["coffee"]
          );
          setAvatarState("coffee");
        } else if (avatarState === "coffee") {
          setCurrentAvatarVideo(
            avatarNameVideoMap[props.avatarName.toLowerCase()]["listening"]
          );
        }
        setTimer(0);
      } else {
        setTimer(timer + 1);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timer]);

  const avatarNameVideoMap = {
    arjun: {
      speaking: AvatarCallieSpeakingVideo,
      noting: AvatarCallieNotingVideo,
      listening: AvatarCallieListeningVideo,
      poster: AvatarCalliePoster,
      coffee: AvatarCallieCoffeeVideo,
    },
    callie: {
      speaking: AvatarCallieSpeakingVideo,
      noting: AvatarCallieNotingVideo,
      listening: AvatarCallieListeningVideo,
      poster: AvatarCalliePoster,
      coffee: AvatarCallieCoffeeVideo,
    },
    mark: {
      speaking: AvatarCallieSpeakingVideo,
      noting: AvatarCallieNotingVideo,
      listening: AvatarCallieListeningVideo,
      poster: AvatarCalliePoster,
      coffee: AvatarCallieCoffeeVideo,
    },
    shreya: {
      speaking: AvatarCallieSpeakingVideo,
      noting: AvatarCallieNotingVideo,
      listening: AvatarCallieListeningVideo,
      poster: AvatarCalliePoster,
      coffee: AvatarCallieCoffeeVideo,
    },
  };
  const bgVideosArray = [bgRoad, bgSnow, bgCity, bgGround];
  // useEffect to update video source when avatarName changes
  useEffect(() => {
    setRandomBgVideo(
      bgVideosArray[Math.floor(Math.random() * bgVideosArray.length)]
    );
    if (videoRef.current) {
      videoRef.current.src =
        avatarNameVideoMap[props.avatarName.toLowerCase()]["speaking"];
      // Optionally, you can load and play the new video immediately
      videoRef.current.load();
      if (isPlaying) {
        try {
          videoRef?.current?.play();
        } catch (error) {
          handleMediaPlayError(error, "avatar_video");
        }
      }
    }
  }, [props.avatarName]);

  const startVideo = (videoType) => {
    console.log(`DWaraka Siri playing ${videoType}`);
    try {
      setCurrentAvatarVideo(
        avatarNameVideoMap[props.avatarName.toLowerCase()][videoType]
      );
      setAvatarState(videoType);
      videoRef?.current?.play();
      setIsPlaying(true);
      setTimer(0);
    } catch (error) {
      handleMediaPlayError(error, "avatar_video");
    }
  };

  useImperativeHandle(ref, () => ({
    startSpeech: () => startVideo("speaking"),
    startListening: () => startVideo("listening"),
    startNoting: () => startVideo("noting"),
    endSpeech: () => {
      console.log("DWaraka pausing 1");
      try {
        if (videoRef.current) {
          videoRef.current.currentTime = 0;
          videoRef.current.pause();
        }
        setIsPlaying(false);
        setTimer(0);
      } catch (error) {
        console.error("Error occurred while pausing video:", error);
      }
    },
  }));

  return (
    <div
      className={`${
        isTabletSize ? "avt-vid-cont" : "avt-vid-cont-mob"
      } avatar-video-container rounded-top`}
    >
      <video
        key={currentAvatarVideo}
        className="w-100 avatar-video"
        ref={videoRef}
        autoPlay
        muted
        poster={avatarNameVideoMap[props.avatarName.toLowerCase()]["poster"]}
        onEnded={() => {
          if (avatarState === "speaking") {
            setIsPlaying(false);
            try {
              videoRef?.current?.play(); // Autoloop if video ends
            } catch (error) {
              handleMediaPlayError(error, "avatar_video");
            }
          }
        }}
      >
        <source src={currentAvatarVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video
        loop
        autoPlay
        className="avatar-bg-video"
        playsInline
        key={randomBgVideo}
      >
        <source src={randomBgVideo} type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
});

export default AvatarVideoPlayer;
