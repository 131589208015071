import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import homePagePreloaderAnimation from "../../../Global/assets/LottieFiles/homePagePreloaderAnimation.json";
import CheckMicCamPermission from "../../../Global/components/CheckMicCamPermission/CheckMicCamPermission";
import CodingRoundComponent, {
  getNCodingQuestions,
} from "../../../Global/components/CodingRoundComponent/CodingRoundComponent";
import InterviewBot from "../../../Global/components/InterviewBot/InterviewBot";
import { roundStatus } from "../../../Global/components/InterviewBot/InterviewBotConstants/InterviewBotConstants";
import {
  fetchAllDSAQuestions,
  getRandomHRRoundQuestions,
  getRandomTheoryRoundQuestions,
} from "../../../Global/components/InterviewBot/InterviewBotCoreHelperFunctions";
import OnlineTestComponent from "../../../Global/components/OnlineTestComponent/OnlineTestComponent";
import PageHelmet from "../../../Global/components/PageHelmet/PageHelmet";
import PreloaderSection from "../../../Global/components/PreloaderSection/PreloaderSection";
import useIsTabletSize from "../../../Global/customHooks/isTabletSizeHook";
import useFullscreen from "../../../Global/customHooks/useFullscreen";
import { fetchAllDataForAllRounds } from "../../../Global/utils/OnlineTestPageSupportFunctions/OnlineTestQuestionsFetcher";
import {
  capitalizeFirstLetter,
  convertToTitleCaseInReverse,
} from "../../../Global/utils/stringExtensionFunctions";
import RemainingTimeCounter from "../../components/RemainingTimeCounter/RemainingTimeCounter";
import BusinessFooter from "../../layouts/BusinessFooter/BusinessFooter";
import BusinessNavBar from "../../layouts/BusinessNavBar/BusinessNavBar";
import {
  editCandidateStatusInRealTimeDatabase,
  fetchAllBusinessPageData,
  findIfValidBusinessUser,
} from "../../utils/FirebaseFunctions/realtimeDBFunctions";
import {
  extractEvaluaitionsIDFromURL,
  getSiteBusinessName,
} from "../../utils/stringExtensionFunction";
import "./TestPage.css";

const TestPage = () => {
  const navigate = useNavigate();
  const isTabletSize = useIsTabletSize();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => ({ ...state }));
  const { isFullscreen } = useFullscreen();
  const [pageData, setPageData] = useState({});
  const [landingPageData, setLandingPageData] = useState({});
  const [codingRoundQuestions, setCodingRoundQuestions] = useState([]);
  const [theoryRoundQuestions, setTheoryRoundQuestions] = useState([]);
  const [hrRoundQuestions, setHRRoundQuestions] = useState([]);
  const [dsaQuestionsIdArray, setDsaQuestionsIdArray] = useState([]);
  const [dsaQuestionsArray, setDsaQuestionsArray] = useState([]);
  const [onlineTestQuestionsDataArray, setOnlineTestQuestionsDataArray] =
    useState({});
  const [showOnlineTestComponent, setShowOnlineTestComponent] = useState(false);
  const [showInterviewComponent, setShowInterviewComponent] = useState(false);
  const [showCodingRoundComponent, setShowCodingRoundComponent] =
    useState(false);
  const [triggerMediaCheck, setTriggerMediaCheck] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [roundName, setRoundName] = useState("");
  const [roundData, setRoundData] = useState("");
  const [cardTitle, setCardTitle] = useState("");
  const [isMediaCheckSuccessful, setIsMediaCheckSuccessful] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [keyOfSelectedRound, setKeyOfSelectedRound] = useState("");
  const onlineTestRoundComponentRef = useRef(null);
  const codingRoundComponentRef = useRef(null);
  useEffect(() => {
    const getLandingPageData = async () => {
      setIsLoading(true);
      const data = await fetchAllBusinessPageData({
        realTimeDBPath: `${capitalizeFirstLetter(
          getSiteBusinessName()
        )}_all_pages_data`,
        categoryName: "landing_page",
      });
      // console.log("DWaraka Siri company data: ", data);
      setLandingPageData(data);
    };
    const getPageData = async () => {
      setIsLoading(true);
      const data = await fetchAllBusinessPageData({
        realTimeDBPath: `${capitalizeFirstLetter(
          getSiteBusinessName()
        )}_all_pages_data`,
        categoryName: extractEvaluaitionsIDFromURL(window.location.pathname),
      });
      await checkStatus(data.isPrivateAccess);
      // console.log("DWaraka Siri all pages data: ", data);
      if (!data?.roundsData) {
        return;
      }
      let firstRoundName = Object.keys(data.roundsData)[0];
      // console.log("DWaraka Siri firstRoundName: ", firstRoundName);

      setRoundName(firstRoundName);
      setRoundData(data.roundsData[firstRoundName]);
      // console.log(
      //   "DWaraka Siri firstRoundData: ",
      //   data.roundsData[firstRoundName]
      // );
      if (firstRoundName.startsWith("onlineTest")) {
        setCardTitle("Objective Round");
      } else if (firstRoundName === "codingRound") {
        setCardTitle("Online Coding Round");
      } else if (firstRoundName.startsWith("interview")) {
        setCardTitle(convertToTitleCaseInReverse(firstRoundName));
      }
      // console.log("Dwaraka Siri cardTitle: ", cardTitle);
      setPageData(data);
    };
    const checkStatus = async (isPrivate) => {
      setIsLoading(true);
      const interviewStatus = await findIfValidBusinessUser({
        email: user.email,
        realTimeDBPath: `${capitalizeFirstLetter(
          getSiteBusinessName()
        )}_candidates_list`,
        categoryName: extractEvaluaitionsIDFromURL(window.location.pathname),
      });
      if (!interviewStatus && isPrivate) {
        navigate("/");
        return;
      }
      setUserStatus(interviewStatus?.status || roundStatus.Unattempted);
    };
    const loadData = async () => {
      setIsLoading(true);
      await Promise.all([getPageData(), getLandingPageData()]);
      setIsLoading(false);
    };
    if (user?.uid) {
      loadData();
    }
    let busName = capitalizeFirstLetter(getSiteBusinessName());
    setBusinessName(busName);
  }, [user]);
  const permissionsCheckDone = async ({ isSuccessful }) => {
    setTriggerMediaCheck(false);
    setIsMediaCheckSuccessful(isSuccessful);
    if (isSuccessful) {
      if (keyOfSelectedRound.startsWith("onlineTest")) {
        handleStartOnlineTest({
          companyName: roundData.companyName || "TCS - Foundation",
          isASingleTest: roundData.isASingleTest || false,
          configArray: roundData.configArray || [
            {
              category: "TCS_Verbal_Ability",
              numberOfDocuments: 20,
              timeForThisRound: 1500,
            },
          ],
        });
      } else if (keyOfSelectedRound === "codingRound") {
        handleStartCodingRound();
      } else {
        handleStartInterview();
      }
    }
  };

  const handleStartInterview = async () => {
    if (roundData?.numberOfTheoryQuestions) {
      let selectedTheoryQuestions = getRandomTheoryRoundQuestions({
        selectedCompanyCard: roundData?.selectedCompanyCard || "CREST",
        numberOfQuestions: roundData?.numberOfTheoryQuestions || 0,
        difficultyLevel: roundData?.difficultyLevel || "Beginner",
      });
      setTheoryRoundQuestions(selectedTheoryQuestions);
    }
    if (roundData?.numberOfHRQuestions) {
      let selectedHRQuestions = getRandomHRRoundQuestions({
        numberOfQuestions: roundData?.numberOfHRQuestions || 0,
      });
      setHRRoundQuestions(selectedHRQuestions);
    }
    if (roundData?.numberOfHRQuestions) {
      let selectedDSAQuestions = await fetchAllDSAQuestions({
        totalNumberOfDSAQuestions: roundData?.totalNumberOfDSAQuestions || 0,
      });
      setDsaQuestionsIdArray(selectedDSAQuestions.dsaQuestionsIdArray);
      setDsaQuestionsArray(selectedDSAQuestions.dsaQuestionsArray);
    }
    if (roundData?.totalNumberOfDSAQuestions) {
      let selectedDSAQuestions = await fetchAllDSAQuestions({
        totalNumberOfDSAQuestions: roundData?.totalNumberOfDSAQuestions,
      });
      setDsaQuestionsIdArray(selectedDSAQuestions.dsaQuestionsIdArray);
      setDsaQuestionsArray(selectedDSAQuestions.dsaQuestionsArray);
    }
    setShowOnlineTestComponent(false);
    setShowCodingRoundComponent(false);
    setShowInterviewComponent(true);
  };

  const handleStartOnlineTest = async (data) => {
    setShowOnlineTestComponent(false);
    setShowInterviewComponent(false);
    setShowCodingRoundComponent(false);
    setIsLoading(true);
    const fetchedData = await fetchAllDataForAllRounds({
      companyName: data.companyName,
      isASingleTest: data.isASingleTest,
      configArray: data.configArray,
    });
    setOnlineTestQuestionsDataArray(fetchedData);
    setIsLoading(false);
    setShowOnlineTestComponent(true);
  };
  const handleStartCodingRound = async () => {
    setShowCodingRoundComponent(false);
    setShowInterviewComponent(false);
    setShowOnlineTestComponent(false);
    setIsLoading(true);
    setCodingRoundQuestions(
      getNCodingQuestions({
        numberOfQuestions: roundData.numberOfQuestions || 3,
        companyName: roundData.companyName || "generic-crest",
      })
    );
    editCandidateStatusInRealTimeDatabase({
      email: user.email,
      variableName: "status",
      updatedValue: roundStatus.Incomplete,
      realTimeDBPath: `${businessName}_candidates_list`,
      categoryName: extractEvaluaitionsIDFromURL(window.location.pathname),
    });
    setIsLoading(false);
    setShowCodingRoundComponent(true);
  };
  return (
    <div className="business-test-page tyn-root tyn-notifications-page">
      <main className="landingPage mb-5">
        <PageHelmet
          title={"Business: Evaluaition Test Page"}
          description={
            "Elevate your interview skills with AI. Master software engineering interviews with AI-driven mock interviews, personalized feedback, and instant insights"
          }
        />
        {!isFullscreen && !isLoading && (
          <BusinessNavBar
            sticky={true}
            useAnchorTag={false}
            restrictRedirection={false}
            landingPageData={landingPageData}
          />
        )}
        {isLoading ? (
          <PreloaderSection
            animationData={homePagePreloaderAnimation}
            textData={"Awakening your AI interview partner..."}
          />
        ) : (
          <div className="jumbotron">
            {!isMediaCheckSuccessful &&
            !showInterviewComponent &&
            !showOnlineTestComponent &&
            !showCodingRoundComponent ? (
              <div className="select-page-main-container">
                <div className="container card select-page-justify-container">
                  <div className="page_text_content my-2 pt-2">
                    <h4 className="text-center mb-4 theme-12009d-color font-bold">
                      {pageData.title}
                    </h4>
                    <p className="text-justify">{pageData.description}</p>
                  </div>
                  <div className="row mb-5 mt-2">
                    <div className="col-lg-6 order-2 order-lg-1 my-2">
                      <h3 className="text-center theme-12009d-color font-semibold my-2">
                        {cardTitle}
                      </h3>
                      <div>
                        <ul>
                          <li>
                            <strong>Technical Setup:</strong>
                          </li>
                          <li>
                            Ensure your computer, webcam, and microphone are in
                            working order. Test them in advance to avoid
                            last-minute technical glitches.
                          </li>
                          <li>
                            Test your microphone and camera to ensure they are
                            functioning correctly before starting the interview
                            or assessment.
                          </li>
                          <li>
                            Connect to a stable and reliable internet network to
                            prevent interruptions during the interview or
                            assessment.
                          </li>
                          <li>
                            Choose a quiet and distraction-free environment for
                            the interview rounds.
                          </li>
                          <li>
                            Ensure your device is fully charged or connected to
                            a power source to avoid sudden shutdowns.
                          </li>
                        </ul>
                      </div>
                      <span
                        className="border-bottom border-blue-gray-300 my-2 d-inline-block w-25"
                        style={{ height: 1 }}
                      >
                        &nbsp;
                      </span>
                      <div className="row ps-3">
                        <div className="py-md-0 py-2 col-md-4 col-6">
                          <span className="d-block text-quill-lighter">
                            Question count:
                          </span>
                          <div className="mt-2 fw-bold">
                            <span>{pageData.numberOfQuestions} </span>
                            <span className="pl-1">Questions</span>
                          </div>
                        </div>
                        <div className="py-md-0 py-2 col-md-4 col-6">
                          <span className="d-block text-quill-lighter">
                            Test Duration
                          </span>
                          <div className="mt-2 fw-bold">
                            <span />
                            <span>{(pageData.totalTime / 60).toFixed(0)} </span>
                            <span className="pl-1">Minutes </span>
                          </div>
                        </div>
                        <div className="py-md-0 py-2 col-md-4 col-6">
                          <span className="d-block text-quill-lighter">
                            Timer
                          </span>
                          <div className="mt-2">
                            <span />
                            <RemainingTimeCounter
                              startTime={pageData.accessTime.startTime}
                              endTime={pageData.accessTime.endTime}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-center practice-card-footer bg-white mt-4">
                        <button
                          disabled={
                            userStatus != roundStatus.Unattempted ||
                            !(
                              new Date(pageData.accessTime.startTime) <=
                                new Date() &&
                              new Date() < new Date(pageData.accessTime.endTime)
                            )
                          }
                          onClick={() => {
                            setTriggerMediaCheck(true);
                            setKeyOfSelectedRound(roundName);
                          }}
                          className="resume-evaluaitor-btn px-5 py-1 h5 rounded"
                        >
                          {userStatus == roundStatus.Unattempted
                            ? "Start"
                            : userStatus == roundStatus.Incomplete
                            ? "Attempted"
                            : "Submitted"}
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                      <Carousel data-bs-theme="dark" className="border rounded">
                        <Carousel.Item>
                          <img
                            className="d-block w-100 practice-carousel-image"
                            src="https://static.mettl.com/rr-web/images/carousel1.svg"
                            alt="Second slide"
                          />
                          <h5 className="text-center text-quill">
                            Internet Connectivity
                          </h5>
                          <p className="text-center text-quill-lighter px-3 px-md-5 mx-md-5 mb-5">
                            Ensure that you have a stable internet connection
                            with a minimum speed of 512 kbps
                          </p>
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100 practice-carousel-image"
                            src="https://static.mettl.com/rr-web/images/carousel2.svg"
                            alt="Third slide"
                          />
                          <h5 className="text-center text-quill">
                            Don’t Refresh
                          </h5>
                          <p className="text-center text-quill-lighter px-3 px-md-5 mx-md-5 mb-5">
                            Please refrain from refreshing the webpage during
                            the assessment, as doing so will result in the loss
                            of all your progress.
                          </p>
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100 practice-carousel-image"
                            src="https://static.mettl.com/rr-web/images/carousel3.svg"
                            alt="First slide"
                          />
                          <h5 className="text-center text-quill">Auto Save</h5>
                          <p className="text-center text-quill-lighter px-3 px-md-5 mx-md-5 mb-5">
                            All your responses are saved automatically. In case
                            of disconnection or shutdown, you will still be able
                            to resume easily.
                          </p>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            ) : showInterviewComponent ? (
              <InterviewBot
                firestoreCollectionId={`${businessName}_interview_submissions`}
                firestoreDocumentId={user.uid}
                firestoreChildCollectionId={extractEvaluaitionsIDFromURL(
                  window.location.pathname
                )}
                selectedCompanyCard={roundData.selectedCompanyCard || "CREST"}
                selectedAvatar={{
                  name: "Callie",
                  voiceLanguage: "en-us",
                  voiceName: "Google US English",
                }}
                interviewType={businessName}
                doesRequireResume={roundData.doesRequireResume || false}
                sendInterviewRounds={
                  roundData.rounds || [
                    {
                      roundTime: roundData.totalTime || 600,
                      roundTitle: "Theory",
                      roundType: "normal",
                      weightage: 1.0,
                    },
                  ]
                }
                setThisInterviewFirestoreIDForHiringProcess={() => {
                  editCandidateStatusInRealTimeDatabase({
                    email: user.email,
                    variableName: "status",
                    updatedValue: roundStatus.Incomplete,
                    realTimeDBPath: `${businessName}_candidates_list`,
                    categoryName: extractEvaluaitionsIDFromURL(
                      window.location.pathname
                    ),
                  });
                }}
                setThisRoundReportForHiringProcess={() => {
                  editCandidateStatusInRealTimeDatabase({
                    email: user.email,
                    variableName: "status",
                    updatedValue: roundStatus.Completed,
                    realTimeDBPath: `${businessName}_candidates_list`,
                    categoryName: extractEvaluaitionsIDFromURL(
                      window.location.pathname
                    ),
                  });
                }}
                theoryRoundQuestions={theoryRoundQuestions}
                hrRoundQuestions={hrRoundQuestions}
                totalNumberOfDSAQuestions={
                  roundData.totalNumberOfDSAQuestions || 0
                }
                dsaQuestionsArray={dsaQuestionsArray}
                dsaQuestionsIdArray={dsaQuestionsIdArray}
                shouldShowReportAtEnd={roundData.shouldShowReportAtEnd || false}
                shouldVideoProctor={roundData.shouldVideoProctor || false}
                shouldRecordAudio={roundData.shouldRecordAudio || false}
                shouldRecordScreen={roundData.shouldRecordScreen || false}
                proctoringScreenshotGap={
                  roundData.proctoringScreenshotGap || 10000
                }
                user={user}
              />
            ) : showOnlineTestComponent ? (
              <OnlineTestComponent
                ref={onlineTestRoundComponentRef}
                onlineTestQuestionsDataArray={onlineTestQuestionsDataArray}
                setThisOnlineTestFirestoreIdForHiringProcess={() => {
                  // console.log("Dwarkaa Siri interview started");
                  editCandidateStatusInRealTimeDatabase({
                    email: user.email,
                    variableName: "status",
                    updatedValue: roundStatus.Incomplete,
                    realTimeDBPath: `${businessName}_candidates_list`,
                    categoryName: extractEvaluaitionsIDFromURL(
                      window.location.pathname
                    ),
                  });
                }}
                setThisRoundReportForHiringProcess={() => {
                  // console.log("Dwarkaa Siri interview done");
                  editCandidateStatusInRealTimeDatabase({
                    email: user.email,
                    variableName: "status",
                    updatedValue: roundStatus.Completed,
                    realTimeDBPath: `${businessName}_candidates_list`,
                    categoryName: extractEvaluaitionsIDFromURL(
                      window.location.pathname
                    ),
                  });
                }}
                shouldShowReportAtEnd={roundData.shouldShowReportAtEnd || false}
                shouldVideoProctor={roundData.shouldVideoProctor || false}
                shouldRecordAudio={roundData.shouldRecordAudio || false}
                shouldRecordScreen={roundData.shouldRecordScreen || false}
                proctoringScreenshotGap={
                  roundData.proctoringScreenshotGap || 10000
                }
                firestoreCollectionId={`${businessName}_online_test_submissions`}
                firestoreChildCollectionId={extractEvaluaitionsIDFromURL(
                  window.location.pathname
                )}
              />
            ) : (
              showCodingRoundComponent && (
                <CodingRoundComponent
                  ref={codingRoundComponentRef}
                  questions={codingRoundQuestions}
                  codingRoundTime={roundData.totalTime || 3300}
                  setThisCodingRoundFirestoreID={() => {
                    // console.log("Dwarkaa Siri interview started");
                  }}
                  setThisRoundReportForHiringProcess={() => {
                    // console.log("Dwarkaa Siri interview done");
                    editCandidateStatusInRealTimeDatabase({
                      email: user.email,
                      variableName: "status",
                      updatedValue: roundStatus.Completed,
                      realTimeDBPath: `${businessName}_candidates_list`,
                      categoryName: extractEvaluaitionsIDFromURL(
                        window.location.pathname
                      ),
                    });
                  }}
                  firestoreCollectionId={`${businessName}_coding_round_submissions`}
                  firestoreChildCollectionId={extractEvaluaitionsIDFromURL(
                    window.location.pathname
                  )}
                  shouldShowReportAtEnd={
                    roundData.shouldShowReportAtEnd || false
                  }
                  shouldVideoProctor={roundData.shouldVideoProctor || false}
                  shouldRecordAudio={roundData.shouldRecordAudio || false}
                  shouldRecordScreen={roundData.shouldRecordScreen || false}
                  proctoringScreenshotGap={
                    roundData.proctoringScreenshotGap || 10000
                  }
                />
              )
            )}
          </div>
        )}
        <CheckMicCamPermission
          checkFor="camera & microphone"
          triggerMediaCheck={triggerMediaCheck}
          permissionsCheckDone={permissionsCheckDone}
        />
      </main>
      {!showInterviewComponent &&
        !showOnlineTestComponent &&
        !showCodingRoundComponent && (
          <BusinessFooter isTabletSize={isTabletSize} />
        )}
    </div>
  );
};

export default TestPage;
