import { Alert, Dialog, DialogContent, Fade, Snackbar } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Discuss } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchDocumentFromFireStore,
  fetchValueFromRealTimeDB,
  findIfUserAttemptedThisPotd,
  getDataFromRealtimeDatabase,
} from "../../../Consumer/supportFunctions/FirebaseFunctions";
import {
  getRoundEvaluation,
  handleSendAlertMessageToSystem,
  handleSendUserResponse,
} from "../../../Consumer/supportFunctions/InterviewBotFunctions";
import CameraAvatarVideoComponent from "../../../Global/components/CameraAvatarVideoComponent/CameraAvatarVideoComponent";
import ScreenRecorder from "../../../Global/components/ScreenRecorder/ScreenRecorder";
import useBeforeComponentUnload from "../../../Global/customHooks/useBeforeComponentUnload";
import CodeInputSection from "../../components/CodeInputSection/CodeInputSection";
import MainChatSection from "../../components/MainChatSection/MainChatSection";
import FullscreenExitModal from "../../components/Modals/ExitFullscreenModal/ExitFullscreenModal";
import FeedbackFormModal from "../../components/Modals/FeedbackFormModal/FeedbackFormModal";
import InterviewRoundsModal from "../../components/Modals/InterviewRoundsModal/InterviewRoundsModal";
import useIsTabletSize from "../../customHooks/isTabletSizeHook";
import useFullscreen from "../../customHooks/useFullscreen";
import useNetworkHook from "../../customHooks/useNetworkHook";
import { overrideVariableATL1orL2CollectionFStore } from "../../utils/firebaseFunctions/firestoreFunctions";
import { playAudio, stopAudio } from "../../utils/speechSynthesizer";
import PageHelmet from "../PageHelmet/PageHelmet";
import ReportForASingleInterviewComponent from "../ReportComponents/InterviewRoundReportComponent/InterviewRoundReportComponent";
import StepperTimer from "../StepperTimer/StepperTimer";
import "./InterviewBot.css";
import {
  interviewStates,
  loadingMessage,
  roundStatus,
} from "./InterviewBotConstants/InterviewBotConstants";
import {
  calculateInterviewScoreSummary,
  checkIsInterviewSlugValid,
  handleCodeSubmission,
  handlePostDSAQuestionSubmissionTasks,
  initialSetupForInterview,
  setConversationAndTimeStamps,
  updateRoundStatus,
  updateSeveralFirestoreVariables,
} from "./InterviewBotCoreHelperFunctions";

const InterviewBot = ({
  interviewType = "MOCK",
  difficultyLevel = "Beginner",
  firestoreCollectionId,
  firestoreChildCollectionId = "evaluAItor",
  firestoreDocumentId,
  selectedCompanyCard,
  selectedAvatar,
  theoryRoundQuestions = [],
  hrRoundQuestions = [],
  totalNumberOfDSAQuestions = 0,
  dsaQuestionsArray = [],
  dsaQuestionsIdArray = [],
  doesRequireResume = false,
  sendInterviewRounds,
  setThisInterviewFirestoreIDForHiringProcess,
  setThisRoundReportForHiringProcess,
  sendResumeText,
  interviewIDSlug,
  shouldShowReportAtEnd = false,
  shouldVideoProctor = false,
  shouldRecordAudio = false,
  shouldRecordScreen = false,
  proctoringScreenshotGap = 10000,
  user,
}) => {
  const navigate = useNavigate();
  const mainChatSectionRef = useRef(null);
  const {
    isFullscreen,
    enterFullscreen,
    exitFullscreen,
    showExitFullscreenAlertModal,
    setShowExitFullscreenAlertModal,
    handleConfirmExit,
  } = useFullscreen();
  const [currentInterviewState, setCurrentInterviewState] = useState(
    interviewStates.interviewNotStarted
  );
  const currentInterviewRoundIndexRef = useRef(1);
  const recorderRef = useRef();
  const { networkState, makeNetworkCall } = useNetworkHook();
  const [internalDifficultyLevel, setInternalDifficultyLevel] =
    useState(difficultyLevel);
  const [internalTheoryRoundQuestions, setInternalTheoryRoundQuestions] =
    useState(theoryRoundQuestions);
  const [internalHRRoundQuestions, setInternalHRRoundQuestions] =
    useState(hrRoundQuestions);
  const [internalDSARoundQuestions, setInternalDSARoundQuestions] =
    useState(dsaQuestionsArray);
  const [internalDSARoundQuestionIDs, setInternalDSARoundQuestionIDs] =
    useState(dsaQuestionsIdArray);
  const [internalDSARoundQuestionsCount, setInternalDSARoundQuestionsCount] =
    useState(totalNumberOfDSAQuestions);
  const [interviewRounds, setInterviewRounds] = useState([]);
  const [retakeInitialSetupDone, setRetakeInitialSetupDone] = useState(false);
  const [userData, setUserData] = useState();
  const [shouldShowFeedbackModal, setShouldShowFeedbackModal] = useState(false);
  const [firstBotMsgAudioUrls, setFirstBotMsgAudioUrls] = useState([]);
  const [inputDataForSystemPrompt, setInputDataForSystemPrompt] = useState();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [proctoringData, setProctoringData] = useState([]);
  const chatSectionRef = useRef(null); // Ref to the chat section
  const [currentDSAQuestionNumber, setCurrentDSAQuestionNumber] = useState(0);
  const [dSAQuestionsReportDataArray, setdSAQuestionsReportDataArray] =
    useState([]);
  const [dsaQuestionsScores, setDsaQuestionsScores] = useState([]);
  const [interviewFirestoreID, setInterviewFirestoreID] = useState("");
  const [resumeText, setResumeText] = useState("");
  const [overallInterviewScore, setOverallInterviewScore] = useState(0);
  const [dataForReportRound, setDataForReportRound] = useState();
  const [showMiniTimer, setShowMiniTimer] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [timeStamps, setTimeStamps] = useState([new Date(), new Date()]);
  const [currentPlayingAudio, setCurrentPlayingAudio] = useState(null);
  const [userChoicesForInterview, setUserChoicesForInterview] = useState({
    interviewRounds: {},
    interviewType: "MOCK",
  });
  const miniTimerDuration = 5;
  const twoMinTimerDuration = 120;
  const [twoMinTimerTime, setTwoMinTimerTime] = useState(twoMinTimerDuration);
  const [isTwoMinTimerRunning, setIsTwoMinTimerRunning] = useState(false);
  const [showTimerAlertSnackbar, setShowTimerAlertSnackbar] = useState(false);

  const [totalTimeTaken, setTotalTimeTaken] = useState(0);
  const [timeTakenForRound, setTimeTakenForRound] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timerTime, setTimerTime] = useState(
    interviewRounds[currentInterviewRoundIndexRef?.current - 1]?.roundTime
  );
  const [isTimerPaused, setIsTimerPaused] = useState(false);
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const [hasBotAskedFirstQuestion, setHasBotAskedFirstQuestion] =
    useState(false);

  const [interviewFailureErrorData, setInterviewFailureErrorData] = useState(
    {}
  );
  const isTabletSize = useIsTabletSize();
  useBeforeComponentUnload(
    currentInterviewState !== interviewStates.interviewEnded,
    () => {
      // Additional logic to execute beforeunload if needed
    }
  );

  useEffect(() => {
    let twoMinTimer;
    if (isTwoMinTimerRunning && twoMinTimerTime > 0) {
      twoMinTimer = setInterval(() => {
        setTwoMinTimerTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    }
    if (twoMinTimerTime <= twoMinTimerDuration / 2) {
      setShowTimerAlertSnackbar(true);
    }
    if (twoMinTimerTime === 0) {
      setTimerTime(0);
      setShowTimerAlertSnackbar(false);
    }
    return () => {
      clearInterval(twoMinTimer);
    };
  }, [twoMinTimerTime, isTwoMinTimerRunning]);

  const resetTwoMinutesTimer = () => {
    setShowTimerAlertSnackbar(false);
    setTwoMinTimerTime(twoMinTimerDuration);
  };

  useEffect(() => {
    setInternalDifficultyLevel(difficultyLevel);
    setInternalTheoryRoundQuestions(theoryRoundQuestions);
    setInternalHRRoundQuestions(hrRoundQuestions);
    setInternalDSARoundQuestions(dsaQuestionsArray);
    setInternalDSARoundQuestionIDs(dsaQuestionsIdArray);
    setInternalDSARoundQuestionsCount(totalNumberOfDSAQuestions);
  }, [
    theoryRoundQuestions,
    hrRoundQuestions,
    dsaQuestionsArray,
    dsaQuestionsIdArray,
    totalNumberOfDSAQuestions,
  ]);

  useEffect(() => {
    if (
      setThisInterviewFirestoreIDForHiringProcess &&
      interviewFirestoreID &&
      !interviewIDSlug
    ) {
      setThisInterviewFirestoreIDForHiringProcess({
        [firestoreCollectionId]: interviewFirestoreID,
      });
    }
  }, [interviewFirestoreID]);
  useEffect(() => {
    console.log(
      "DWAraka Siri currentDSAQuestionNumber updated",
      currentDSAQuestionNumber
    );
  }, [currentDSAQuestionNumber]);
  useEffect(() => {
    const roundsWithStatus =
      sendInterviewRounds?.map((round) => ({
        ...round,
        roundStatus: "Unattempted",
      })) || [];
    setInterviewRounds(roundsWithStatus);
    if (sendResumeText) {
      setResumeText(sendResumeText);
    }
    setUserChoicesForInterview({
      interviewRounds: roundsWithStatus,
      selectedCompanyCard: selectedCompanyCard,
      interviewType: interviewType || "MOCK",
    });
  }, [sendInterviewRounds, interviewType, user]);
  useEffect(() => {
    const fetchData = async () => {
      if (user?.uid) {
        if (interviewIDSlug) {
          setCurrentInterviewState(interviewStates.interviewSettingUp);
          checkIsInterviewSlugValid({
            firestoreCollectionId,
            firestoreChildCollectionId,
            firestoreDocumentId: firestoreDocumentId,
            interviewIDSlug,
            retakeInitialSetupDone,
            navigate,
            setInterviewFirestoreID,
            setOverallInterviewScore,
            setInterviewRounds,
            setUserChoicesForInterview,
            setResumeText,
            setCurrentDSAQuestionNumber,
            currentInterviewRoundIndexRef,
            initialCoreSetupForDSARound,
            setConversationAndTimeStamps,
            setDsaQuestionsScores,
            setdSAQuestionsReportDataArray,
            setRetakeInitialSetupDone,
            commonParamsForSetConversationAndTimeStamps,
            setInternalDifficultyLevel,
            setInternalTheoryRoundQuestions,
            setInternalHRRoundQuestions,
            setInternalDSARoundQuestions,
            setInternalDSARoundQuestionIDs,
            setInternalDSARoundQuestionsCount,
          });
        }
        const userFetchedData = await getDataFromRealtimeDatabase(
          `/AllUsersData/${user.uid}`
        );
        setUserData(userFetchedData);
      }
    };
    fetchData();
  }, [user, interviewIDSlug]);

  const startInterview = async ({ currentInterviewRoundIndex, resumeText }) => {
    console.log("DWaraka interviewFirestoreID: ", interviewFirestoreID);
    setIsTwoMinTimerRunning(true);
    currentInterviewRoundIndexRef.current = currentInterviewRoundIndex;
    interviewRounds[currentInterviewRoundIndex - 1].roundType == "coding"
      ? await initialCoreSetupForDSARound({
          thisInterviewFirestoreID: interviewFirestoreID,
          roundTitle:
            interviewRounds[currentInterviewRoundIndex - 1].roundTitle,
          interviewRounds: interviewRounds,
          currentDSAQuestionNumber: 1,
        })
      : setConversationAndTimeStamps({
          ...commonParamsForSetConversationAndTimeStamps,
          currentInterviewRoundIndex: currentInterviewRoundIndex,
          resumeText: resumeText,
          interviewFirestoreID: interviewFirestoreID,
        });
  };

  const avatarVideoPlayerRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSpeechStart = () => {
    resetTwoMinutesTimer();
    if (avatarVideoPlayerRef.current) {
      avatarVideoPlayerRef.current.startSpeech();
    }
  };

  const handleSpeechEnd = ({ isStreamEnded }) => {
    resetTwoMinutesTimer();
    if (isStreamEnded) {
      setTimeout(() => {
        mainChatSectionRef?.current?.startMicAndListen();
        setHasBotAskedFirstQuestion(true);
        if (avatarVideoPlayerRef.current) {
          avatarVideoPlayerRef.current.startListening();
        }
      }, 250);
    }
    if (avatarVideoPlayerRef.current) {
      avatarVideoPlayerRef.current.endSpeech();
    }
  };

  const miniTimerDone = async () => {
    setHasBotAskedFirstQuestion(false);
    setCurrentInterviewState(interviewStates.interviewInProgress);
    setShowMiniTimer(false);
    startTimer();
    for (const audioURL of firstBotMsgAudioUrls) {
      await playAudio(audioURL, setCurrentPlayingAudio);
    }
    setTimeout(() => {
      mainChatSectionRef?.current?.startMicAndListen();
      if (avatarVideoPlayerRef?.current) {
        avatarVideoPlayerRef?.current?.endSpeech();
      }
    }, 250);
  };

  const initialSetupForReportsRound = async () => {
    setCurrentInterviewState(interviewStates.interviewPaused);
    let recordedUrl = await recorderRef?.current?.stopRecording();
    console.log("DWaraka Siri recordedUrl: ", recordedUrl);
    const loadingTimeout = setTimeout(async () => {
      const updatedRounds = updateRoundStatus(
        currentInterviewRoundIndexRef.current - 1,
        interviewRounds,
        roundStatus.Completed
      );
      setInterviewRounds(updatedRounds);
      console.log("DWaraka latest Siri interviewRounds: ", interviewRounds);
      console.log("DWaraka latest Siri updatedRounds: ", updatedRounds);
      const variablesToUpdate = [
        {
          variableToUpdate: "interviewRounds",
          updatedValue: updatedRounds,
        },
        {
          variableToUpdate: "OverAllInterviewStatus",
          updatedValue: roundStatus.Completed,
        },
        {
          variableToUpdate: "isThisEntirelyDone",
          updatedValue: true,
        },
        {
          variableToUpdate: "recordedUrl",
          updatedValue: recordedUrl || "",
        },
      ];

      await updateSeveralFirestoreVariables({
        firestoreCollectionId: firestoreCollectionId,
        firestoreChildCollectionId: firestoreChildCollectionId,
        firestoreDocumentId: firestoreDocumentId,
        interviewFirestoreID: interviewFirestoreID,
        variablesToUpdate: variablesToUpdate,
      });

      const reportsData = await fetchDocumentFromFireStore({
        parent_collection: firestoreCollectionId,
        parent_document: firestoreDocumentId,
        child_collection: firestoreChildCollectionId,
        child_document: interviewFirestoreID,
      });
      if (setThisRoundReportForHiringProcess) {
        setThisRoundReportForHiringProcess({
          reportData: reportsData,
          firestoreId: interviewFirestoreID,
        });
      }
      setDataForReportRound(reportsData);
      setCurrentInterviewState(interviewStates.interviewEnded);
      if (shouldShowReportAtEnd) {
        setShouldShowFeedbackModal(true);
      }
      handleConfirmExit();
    }, 1);
    return () => {
      clearTimeout(loadingTimeout); // Clean up the timeout when the component unmounts
    };
  };

  const storeIncompleteDSAQuestionData = async () => {
    await storeThisDataInFirestore(
      "",
      "",
      internalDSARoundQuestionIDs[currentDSAQuestionNumber - 1],
      0
    );
    await overrideVariableATL1orL2CollectionFStore({
      parent_collection: firestoreCollectionId,
      parent_document: firestoreDocumentId,
      child_collection: firestoreChildCollectionId,
      child_document: interviewFirestoreID,
      variableToUpdate: "totalTimeTaken",
      updatedValue: totalTimeTaken + timeTakenForRound,
    });
  };
  const commonParamsForSetConversationAndTimeStamps = {
    ...userChoicesForInterview,
    avatarName: selectedAvatar.name,
    internalTheoryRoundQuestions: internalTheoryRoundQuestions,
    internalHRRoundQuestions: internalHRRoundQuestions,
    firestoreCollectionId: firestoreCollectionId,
    firestoreChildCollectionId: firestoreChildCollectionId,
    firestoreDocumentId: firestoreDocumentId,
    user: user,
    interviewRounds: interviewRounds,
    setInterviewRounds: setInterviewRounds,
    interviewFirestoreID: interviewFirestoreID,
    internalDSARoundQuestions: internalDSARoundQuestions,
    currentDSAQuestionNumber: currentDSAQuestionNumber,
    setCurrentDSAQuestionNumber: setCurrentDSAQuestionNumber,
    resumeText: resumeText,
    setConversation: setConversation,
    setTimeStamps: setTimeStamps,
    setCurrentInterviewState: setCurrentInterviewState,
    setShowMiniTimer: setShowMiniTimer,
    setFirstBotMsgAudioUrls: setFirstBotMsgAudioUrls,
    setTimerTime: setTimerTime,
    enterFullscreen: enterFullscreen,
    setInputDataForSystemPrompt: setInputDataForSystemPrompt,
  };

  async function initialCoreSetupForDSARound({
    timerTimeLeft,
    interviewRounds,
    roundTitle,
    currentDSAQuestionNumber,
  }) {
    handlePreDSAQuestionTasks({
      interviewUniqueID: interviewFirestoreID,
      internalDSARoundQuestions: internalDSARoundQuestions,
      roundTitle: roundTitle,
      interviewRounds: interviewRounds,
      currentDSAQuestionNumber: currentDSAQuestionNumber,
      setTimerTimeTo: timerTimeLeft
        ? timerTimeLeft
        : currentDSAQuestionNumber == 0
        ? interviewRounds[currentInterviewRoundIndexRef.current - 1].roundTime
        : timerTime,
    });
  }
  useEffect(() => {
    let timer;

    if (isTimerRunning && !isTimerPaused && timerTime > 0) {
      timer = setInterval(() => {
        setTimerTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        setTimeTakenForRound((prevTime) => prevTime + 1);
      }, 1000);
    }

    if (isTimerRunning && timerTime === 0) {
      resetTwoMinutesTimer();
      setIsTwoMinTimerRunning(false);
      setIsTimerRunning(false);
      mainChatSectionRef.current.muteMicAndResetStates();
      if (timerTime === 0) {
        makeNetworkCall({
          funcWithInternet: () => {
            stopAudio({ currentPlayingAudio, setCurrentPlayingAudio });
            if (userChoicesForInterview.interviewType === "DIP") {
              setCurrentInterviewState(interviewStates.dipTimerOut);
              return;
            }
            setCurrentInterviewState(interviewStates.interviewPaused);
            if (
              interviewRounds[currentInterviewRoundIndexRef.current - 1]
                .roundType == "normal"
            ) {
              sendEvaluationRequestToBackend({
                currentDSAQuestionNumber: currentDSAQuestionNumber,
              });
            } else if (
              interviewRounds[currentInterviewRoundIndexRef.current - 1]
                .roundType == "coding"
            ) {
              storeIncompleteDSAQuestionData();
              initialSetupForReportsRound();
            }
          },
          shouldRetry: true,
        });
      }
      clearInterval(timer);
    }

    // Show the timer when 10 minutes have passed
    if (timerTime <= 120) {
      setIsTimerVisible(true);
    } else {
      setIsTimerVisible(false);
    }

    if (timerTime === 60) {
      addTimeAlertMessage();
    }

    return () => {
      clearInterval(timer);
    };
  }, [isTimerRunning, isTimerPaused, timerTime]);

  const startTimer = () => {
    setIsTimerRunning(true);
    setIsTimerPaused(false);
  };

  const pauseTimer = () => {
    setIsTimerPaused(true);
    setIsTimerRunning(false);
  };

  useEffect(() => {
    if (chatSectionRef.current) scrollToBottomMessage();
  }, [conversation]);

  function scrollToBottomMessage() {
    if (chatSectionRef.current) {
      chatSectionRef.current.scrollTop = chatSectionRef.current.scrollHeight;
    }
  }

  const makeInitialSetupForInterview = async (resumeText) => {
    console.log("DWaraka makeInitialSetupForInterview");
    setCurrentInterviewState(interviewStates.interviewSettingUp);
    let currentFirestoreId;
    if (userChoicesForInterview.interviewType === "DIP") {
      const todaysDIPProblemId = await fetchValueFromRealTimeDB(
        "DIPTodaysProblemId"
      );
      if (userData?.uid) {
        const isThisSolvedPrevously = await findIfUserAttemptedThisPotd(
          "submittedDIPsList",
          todaysDIPProblemId,
          user.uid
        );
        if (isThisSolvedPrevously) {
          setCurrentInterviewState(interviewStates.dipAlreadySolved);
          return;
        } else {
          currentFirestoreId = todaysDIPProblemId.toString();
          setInterviewFirestoreID(currentFirestoreId);
        }
      } else {
        return;
      }
    } else {
      const initialSetupResult = await initialSetupForInterview({
        firestoreCollectionId: firestoreCollectionId,
        firestoreChildCollectionId: firestoreChildCollectionId,
        resumeText: resumeText,
        setResumeText: setResumeText,
        userData: userData,
        userChoicesForInterview: userChoicesForInterview,
        setInterviewFirestoreID: setInterviewFirestoreID,
        difficultyLevel: internalDifficultyLevel,
        numberOfDSAQuestions: internalDSARoundQuestionsCount || 0,
        numberOfTheoryRoundQuestions: internalTheoryRoundQuestions.length || 0,
        numberOfHrRoundQuestions: internalHRRoundQuestions.length || 0,
      });
      currentFirestoreId = initialSetupResult.thisFirestoreID;
    }
    setCurrentInterviewState(interviewStates.interviewSettingDone);
    return currentFirestoreId;
  };

  async function handleInputSubmit({ e, isCode, userMsg }) {
    if (e) {
      e.preventDefault();
    }
    if (avatarVideoPlayerRef.current) {
      avatarVideoPlayerRef.current.startNoting();
    }
    if (userMsg?.trim() !== "" || code !== "") {
      const newUserMessage = {
        role: "user",
        content: isCode ? `//code\n${code}` : userMsg.trim(),
      };
      const newUserMessageTimeStamp = new Date();
      var latestConversation = [...conversation, newUserMessage];
      var latestTimestamps = [...timeStamps, newUserMessageTimeStamp];
      setConversation([...latestConversation, loadingMessage]);
      setTimeStamps([...latestTimestamps]);
      await sendConvoToBackend({
        latestConversation: latestConversation,
        latestTimestamps: latestTimestamps,
      });
    }
  }

  async function skipToNextQuestion() {
    const newUserMessage = {
      role: "user",
      content:
        interviewRounds[currentInterviewRoundIndexRef.current - 1]
          ?.roundTitle === "Theory"
          ? "Lets skip to next question"
          : "Skip question",
    };
    const newUserMessageTimeStamp = new Date(0);
    var latestConversation = [...conversation, newUserMessage];
    var latestTimestamps = [...timeStamps, newUserMessageTimeStamp];
    setConversation([...latestConversation, loadingMessage]);
    setTimeStamps([...latestTimestamps]);
    await sendConvoToBackend({
      latestConversation: latestConversation,
      latestTimestamps: latestTimestamps,
    });
  }

  function handleEditorChange(value) {
    setCode(value);
  }

  const sendUpdateTimerTimeForHandleSendUserResponse = () => timerTime;

  const addTimeAlertMessage = handleSendAlertMessageToSystem({
    conversation: conversation,
    setConversation: setConversation,
    setTimeStamps: setTimeStamps,
  });

  const sendEvaluationRequestToBackend = async ({
    currentDSAQuestionNumber,
  }) => {
    stopAudio({ currentPlayingAudio, setCurrentPlayingAudio });
    const loadingTimeout = setTimeout(async () => {
      let roundRating = 0;
      let roundOverallReview = { overall_summary: "" };
      try {
        let result;

        // Determine the type of round (Theory, HR, Resume)
        const roundTitle =
          interviewRounds[currentInterviewRoundIndexRef.current - 1]
            ?.roundTitle || "";

        // Send evaluation prompt based on round type
        result = await triggerRoundEvaluation(roundTitle);
        roundOverallReview = result;
        var scoreSummary;

        // Process evaluation result
        if (result.data) {
          try {
            console.log("DWaraka theory eval result: " + result.data);
            console.log(
              "DWaraka theory eval JSON.parse(result): " +
                JSON.parse(result.data)
            );
            roundOverallReview = JSON.parse(result.data);
            scoreSummary = calculateInterviewScoreSummary(roundOverallReview);
            roundOverallReview = {
              ...roundOverallReview,
              totalNumberOfQuestions: scoreSummary.totalNumberOfQuestions,
              aggregateScore: scoreSummary.aggregateScore,
              averageScore: scoreSummary.averageScore,
              proctoringData: proctoringData,
              timeTakenForRound: timeTakenForRound,
            };
            roundRating = parseFloat(scoreSummary.averageScore) || 0;
          } catch (error) {
            console.log(
              "Error while JSONifying the evaluaition response: ",
              error
            );
            setInterviewFailureErrorData({
              message: "Error while JSONifying the evaluaition response.",
              error: error,
              responseData: result.data,
              wasConnectedToInternet: navigator.onLine,
            });
            setCurrentInterviewState(interviewStates.interviewFailed);
            return;
          }
        } else {
          console.log(
            "Error getting evaluaition response: returning interview failed state with network on? ",
            navigator.onLine,
            networkState.online
          );
          setInterviewFailureErrorData({
            message: "Error while making round evaluaition request.",
            error: result.error,
            wasConnectedToInternet: navigator.onLine,
          });
          setCurrentInterviewState(interviewStates.interviewFailed);
          return;
        }

        // Update overall interview score
        const latestOverallInterviewScore = parseFloat(
          (
            parseFloat(overallInterviewScore) +
            parseFloat(roundRating) *
              interviewRounds[currentInterviewRoundIndexRef.current - 1]
                .weightage
          ).toFixed(2)
        );
        setOverallInterviewScore(latestOverallInterviewScore);

        // Update Firestore variables
        const variablesToUpdate = [
          {
            variableToUpdate: `${
              interviewRounds[currentInterviewRoundIndexRef.current - 1]
                .roundTitle
            }RoundReview`,
            updatedValue: roundOverallReview,
          },
          {
            variableToUpdate: "overallInterviewScore",
            updatedValue: latestOverallInterviewScore,
          },
          {
            variableToUpdate: "overAllScorePercentage",
            updatedValue: latestOverallInterviewScore * 10,
          },
          {
            variableToUpdate: "totalTimeTaken",
            updatedValue: totalTimeTaken + timeTakenForRound,
          },
        ];
        await updateSeveralFirestoreVariables({
          firestoreCollectionId: firestoreCollectionId,
          firestoreChildCollectionId: firestoreChildCollectionId,
          firestoreDocumentId: firestoreDocumentId,
          interviewFirestoreID: interviewFirestoreID,
          variablesToUpdate: variablesToUpdate,
        });

        // Reset variables
        setTotalTimeTaken((prev) => prev + timeTakenForRound);
        setTimeTakenForRound(0);
        setProctoringData([]);

        // Proceed to the next round or end the interview
        if (currentInterviewRoundIndexRef.current < interviewRounds.length) {
          currentInterviewRoundIndexRef.current += 1;
          interviewRounds[currentInterviewRoundIndexRef.current - 1]
            .roundType == "coding"
            ? await initialCoreSetupForDSARound({
                interviewRounds: interviewRounds,
                currentDSAQuestionNumber: currentDSAQuestionNumber + 1,
              })
            : setConversationAndTimeStamps({
                ...commonParamsForSetConversationAndTimeStamps,
                currentInterviewRoundIndex:
                  currentInterviewRoundIndexRef.current,
                currentDSAQuestionNumber: currentDSAQuestionNumber + 1,
              });
        } else {
          initialSetupForReportsRound();
        }
      } catch (error) {
        console.log(error.message);
        toast.error(error.message);
        setCurrentInterviewState(interviewStates.interviewFailed);
        return;
      }
    }, 1000);

    // Cleanup timeout when component unmounts
    return () => {
      clearTimeout(loadingTimeout);
    };
  };

  const triggerRoundEvaluation = getRoundEvaluation({
    conversation: conversation,
    userUid: user.uid,
    userName:
      user.displayName.split(" ")[0].charAt(0).toUpperCase() +
      user.displayName.split(" ")[0].slice(1),
    interviewId: interviewFirestoreID,
    inputDataForSystemPrompt: inputDataForSystemPrompt,
  });

  const sendConvoToBackend = async ({
    latestConversation,
    latestTimestamps,
  }) => {
    resetTwoMinutesTimer();
    try {
      await handleSendMessage({ latestConversation, latestTimestamps });
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const sharedConfig = {
    makeNetworkCall: makeNetworkCall,
    userUid: user.uid,
    userName:
      user.displayName.split(" ")[0].charAt(0).toUpperCase() +
      user.displayName.split(" ")[0].slice(1),
    interviewId: interviewFirestoreID,
    firestoreCollectionId: firestoreCollectionId,
    avatarName: selectedAvatar.name,
    setLoading: setLoading,
    setConversation: setConversation,
    setTimeStamps: setTimeStamps,
    setCode: setCode,
    getTimerTime: sendUpdateTimerTimeForHandleSendUserResponse,
    handleSpeechStart: handleSpeechStart,
    handleSpeechEnd: handleSpeechEnd,
    setCurrentPlayingAudio: setCurrentPlayingAudio,
    inputDataForSystemPrompt: inputDataForSystemPrompt,
    roundTitle:
      interviewRounds[currentInterviewRoundIndexRef.current - 1]?.roundTitle,
  };

  const handleSendMessage = handleSendUserResponse({
    ...sharedConfig,
    isThisCodeEvaluation: false,
  });

  const storeThisDataInFirestore = handlePostDSAQuestionSubmissionTasks({
    firestoreCollectionId: firestoreCollectionId,
    firestoreChildCollectionId: firestoreChildCollectionId,
    firestoreDocumentId: firestoreDocumentId,
    setLoading: setLoading,
    dSAQuestionsReportDataArray: dSAQuestionsReportDataArray,
    dsaQuestionsScores: dsaQuestionsScores,
    setdSAQuestionsReportDataArray: setdSAQuestionsReportDataArray,
    setDsaQuestionsScores: setDsaQuestionsScores,
    overallInterviewScore: overallInterviewScore,
    interviewFirestoreID: interviewFirestoreID,
    userData: userData,
    setOverallInterviewScore: setOverallInterviewScore,
    interviewType: userChoicesForInterview?.interviewType,
    currentDSAQuestionNumber: currentDSAQuestionNumber,
    weightage:
      interviewRounds[currentInterviewRoundIndexRef.current - 1]?.weightage,
  });
  const onSubmitCodeHandler = handleCodeSubmission(
    interviewRounds[currentInterviewRoundIndexRef.current - 1]?.roundTitle,
    code,
    setCode,
    internalDSARoundQuestionIDs,
    internalDSARoundQuestions,
    currentDSAQuestionNumber,
    setCurrentInterviewState,
    user.uid,
    storeThisDataInFirestore,
    interviewRounds[currentInterviewRoundIndexRef.current - 1]?.roundTime -
      timerTime,
    roundStatus,
    initialCoreSetupForDSARound,
    interviewFirestoreID,
    firestoreCollectionId,
    firestoreChildCollectionId,
    firestoreDocumentId,
    interviewRounds,
    conversation
  );

  return (
    <div className="interview-page">
      <PageHelmet
        title={"EvaluAItor: Interview Page"}
        description={
          "Elevate your interview skills with AI. Master software engineering interviews with AI-driven mock interviews, personalized feedback, and instant insights"
        }
      />
      {(shouldRecordAudio || shouldRecordScreen) && (
        <ScreenRecorder
          ref={recorderRef}
          userUid={user.uid}
          firestoreCollectionId={firestoreCollectionId}
          roundFirestoreID={interviewFirestoreID}
          recordAudio={shouldRecordAudio}
          recordScreen={shouldRecordScreen}
        />
      )}
      {showTimerAlertSnackbar && twoMinTimerTime <= twoMinTimerDuration / 2 && (
        <div className="interview-timer-alert-snackbar">
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            TransitionComponent={Fade}
            onClose={() => {}}
          >
            <Alert
              severity={
                twoMinTimerTime <= twoMinTimerDuration / 4 ? "error" : "warning"
              }
              variant="filled"
              sx={{ width: "100%" }}
            >
              Are you still there? Please respond with in{" "}
              <strong> {twoMinTimerTime} seconds</strong> or this interview will
              timeout and exits automatically
            </Alert>
          </Snackbar>
        </div>
      )}

      {loading && (
        <Dialog
          open={loading}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            ".MuiPaper-root": {
              backgroundColor: "transparent",
              borderColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <DialogContent>
            <Discuss
              visible={true}
              height="40"
              width="40"
              ariaLabel="discuss-loading"
              wrapperStyle={{}}
              wrapperClass="discuss-wrapper"
              color="#fff"
              backgroundColor="#F4442E"
              colors={["#12009d", "#12009d"]}
            />
          </DialogContent>
        </Dialog>
      )}
      {currentInterviewState == interviewStates.interviewEnded &&
      shouldShowReportAtEnd ? (
        <div className="interview-reports-state justify-content-center">
          <ReportForASingleInterviewComponent
            interviewRecord={dataForReportRound}
            shouldShowCertificate={true}
            showBackBtn={true}
            interViewId={interviewFirestoreID}
          />
        </div>
      ) : (
        <div
          className={`interview-core-container ${
            isFullscreen == false ? "interview-timer-extra-margin-top" : ""
          } ${isTabletSize ? "int-core-cont" : "int-core-cont-mob"}`}
        >
          {currentInterviewState == interviewStates.interviewInProgress && (
            <StepperTimer
              steps={interviewRounds.map((round) => round.roundTitle)}
              backgroundColor="lightgrey"
              activeStep={currentInterviewRoundIndexRef.current - 1}
              percentage={
                (timerTime /
                  interviewRounds[currentInterviewRoundIndexRef.current - 1]
                    .roundTime) *
                100
              }
              timerTime={timerTime}
            />
          )}
          {currentInterviewState == interviewStates.interviewNotStarted ||
          currentInterviewState == interviewStates.interviewPaused ||
          currentInterviewState == interviewStates.dipTimerOut ||
          currentInterviewState == interviewStates.interviewSettingUp ||
          currentInterviewState == interviewStates.interviewSettingDone ||
          currentInterviewState == interviewStates.dipAlreadySolved ||
          currentInterviewState == interviewStates.interviewFailed ||
          (currentInterviewState == interviewStates.interviewEnded &&
            !shouldShowReportAtEnd) ? (
            <InterviewRoundsModal
              userData={userData}
              doesRequireResume={doesRequireResume}
              setShouldShowFeedbackModal={setShouldShowFeedbackModal}
              currentInterviewState={currentInterviewState}
              miniTimerDuration={miniTimerDuration}
              showMiniTimer={showMiniTimer}
              miniTimerDone={miniTimerDone}
              makeInitialSetupForInterview={makeInitialSetupForInterview}
              interviewFirestoreID={interviewFirestoreID}
              startInterview={({ currentInterviewRoundIndex, resumeText }) => {
                if (shouldRecordAudio || shouldRecordScreen) {
                  recorderRef.current
                    .startRecording()
                    .then((screenShared) => {
                      if (screenShared) {
                        // Screen sharing started
                        console.log("User has selected a screen to share.");
                        startInterview({
                          currentInterviewRoundIndex,
                          resumeText,
                        });
                      } else {
                        // Screen sharing cancelled
                        console.log("User has cancelled screen sharing.");
                      }
                    })
                    .catch((error) => {
                      console.error("Error:", error);
                    });
                } else {
                  startInterview({
                    currentInterviewRoundIndex,
                    resumeText,
                  });
                }
              }}
            />
          ) : (
            <div
              className={`d-flex w-100 justify-content-center align-items-center ${
                isTabletSize ? "int-eve-cont" : "int-eve-cont-mob"
              } interview-everything-container`}
            >
              <div
                className={`interview-video-container h-100 ${
                  isTabletSize ? "int-vid-cont" : "int-vid-cont-mob"
                }`}
              >
                {isTabletSize && (
                  <p
                    className={`bg-light ${
                      isTabletSize ? "user-name-text" : "user-name-text-mob"
                    } text-center p-1 border m-0 border border-end-0 theme-12009d-color theme-12009d-border-color`}
                  >
                    <strong>Interview ID:</strong> {interviewFirestoreID}
                  </p>
                )}
                <CameraAvatarVideoComponent
                  userAvatar={user.profilepic}
                  userDisplayName={user.displayName}
                  userUid={user.uid}
                  firestoreCollectionId={firestoreCollectionId}
                  interviewFirestoreID={interviewFirestoreID}
                  avatarVideoPlayerRef={avatarVideoPlayerRef}
                  avatarName={selectedAvatar.name}
                  isFullscreen={isFullscreen}
                  enterFullscreen={enterFullscreen}
                  exitFullscreen={exitFullscreen}
                  proctoringData={proctoringData}
                  setProctoringData={setProctoringData}
                  shouldVideoProctor={shouldVideoProctor}
                  proctoringScreenshotGap={proctoringScreenshotGap}
                />
              </div>
              <span className={`d-flex px-0 interview-chat-main-container  `}>
                <MainChatSection
                  ref={mainChatSectionRef}
                  scrollToBottomMessage={scrollToBottomMessage}
                  chatSectionRef={chatSectionRef}
                  conversation={conversation}
                  timeStamps={timeStamps}
                  user={user}
                  handleInputSubmit={handleInputSubmit}
                  loading={loading}
                  timerTime={timerTime}
                  isTimerVisible={isTimerVisible}
                  setTimerTime={setTimerTime}
                  skipToNextQuestion={skipToNextQuestion}
                  showSkipBtn={
                    hasBotAskedFirstQuestion &&
                    (interviewRounds[currentInterviewRoundIndexRef.current - 1]
                      ?.roundTitle == "Theory" ||
                      interviewRounds[currentInterviewRoundIndexRef.current - 1]
                        ?.roundTitle == "Resume")
                  }
                />
              </span>
              <div
                className={`interview-code-main-container ${
                  interviewRounds[currentInterviewRoundIndexRef.current - 1]
                    .roundType === "coding"
                    ? "col-lg-4 px-0 d-flex"
                    : "d-none"
                } `}
              >
                {interviewRounds[currentInterviewRoundIndexRef.current - 1]
                  .roundType === "coding" ? (
                  <CodeInputSection
                    code={code}
                    handleEditorChange={handleEditorChange}
                    loading={loading}
                    onSubmitCodeHandler={() => {
                      pauseTimer();
                      mainChatSectionRef.current.muteMicAndResetStates();
                      console.log(
                        "Dwaraka in onSubmitCodeHandler for currentDSAQuestionNumber: ",
                        currentDSAQuestionNumber
                      );
                      onSubmitCodeHandler();
                    }}
                    handleInputSubmit={(event) => {
                      mainChatSectionRef.current.muteMicAndResetStates();
                      handleInputSubmit({ event: event, isCode: true });
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <FeedbackFormModal
        shouldShowFeedbackModal={shouldShowFeedbackModal}
        setShouldShowFeedbackModal={setShouldShowFeedbackModal}
        interviewFailureErrorData={interviewFailureErrorData}
        roundType="Interview"
        userUid={user.uid}
      />
      <FullscreenExitModal
        show={showExitFullscreenAlertModal}
        onReject={() => setShowExitFullscreenAlertModal(false)}
        onConfirm={handleConfirmExit}
        text={"It is recommended to not exit fullscreen during the interview."}
        heading={"Exit Fullscreen?"}
        confirmBtnText={"Exit"}
        rejectBtntext={"Cancel"}
      />
    </div>
  );

  async function handlePreDSAQuestionTasks({
    internalDSARoundQuestions,
    setTimerTimeTo,
    roundTitle,
    interviewRounds,
    interviewUniqueID,
    currentDSAQuestionNumber,
  }) {
    console.log(
      "DWaraka handlePreDSAQuestionTasks currentDSAQuestionNumber: ",
      currentDSAQuestionNumber
    );
    var interviewDBId = interviewFirestoreID;
    if (interviewUniqueID) {
      interviewDBId = interviewUniqueID;
    }
    setCurrentInterviewState(interviewStates.interviewPaused);
    if (roundTitle == "DIP") {
      setConversationAndTimeStamps({
        ...commonParamsForSetConversationAndTimeStamps,
        currentInterviewRoundIndex: currentInterviewRoundIndexRef.current,
        timerTimeToValue: setTimerTimeTo,
        internalDSARoundQuestions: internalDSARoundQuestions,
        currentDSAQuestionNumber: currentDSAQuestionNumber,
      });
    } else {
      const updatedRounds1 = updateRoundStatus(
        currentInterviewRoundIndexRef.current - 1,
        interviewRounds,
        roundStatus.Incomplete
      );
      setInterviewRounds(updatedRounds1);
      var updatedRounds2 = [];
      if (currentInterviewRoundIndexRef.current >= 2) {
        updatedRounds2 = updateRoundStatus(
          currentInterviewRoundIndexRef.current - 2,
          updatedRounds1,
          roundStatus.Completed
        );
        setInterviewRounds(updatedRounds2);
      }
      console.log(
        "DWaraka currentDSAQuestionNumber: ",
        currentDSAQuestionNumber
      );
      const variablesToUpdate = [
        {
          variableToUpdate: "interviewRounds",
          updatedValue: interviewRounds,
        },
        ...(currentDSAQuestionNumber <= internalDSARoundQuestionsCount
          ? [
              {
                variableToUpdate: `DSAQ${currentDSAQuestionNumber}Status`,
                updatedValue: roundStatus.Incomplete,
              },
            ]
          : []),
        ...(currentDSAQuestionNumber === 1
          ? [
              {
                variableToUpdate: "interviewRounds",
                updatedValue: updatedRounds1,
              },
            ]
          : [
              {
                variableToUpdate: `DSAQ${currentDSAQuestionNumber - 1}Status`,
                updatedValue: roundStatus.Completed,
              },
            ]),
        ...(currentDSAQuestionNumber === 1 &&
        currentInterviewRoundIndexRef.current >= 2
          ? [
              {
                variableToUpdate: "interviewRounds",
                updatedValue: updatedRounds2,
              },
            ]
          : []),
      ];
      await updateSeveralFirestoreVariables({
        firestoreCollectionId: firestoreCollectionId,
        firestoreChildCollectionId: firestoreChildCollectionId,
        firestoreDocumentId: firestoreDocumentId,
        interviewFirestoreID: interviewDBId,
        variablesToUpdate: variablesToUpdate,
      });

      if (currentDSAQuestionNumber <= internalDSARoundQuestionsCount) {
        setConversationAndTimeStamps({
          ...commonParamsForSetConversationAndTimeStamps,
          currentInterviewRoundIndex: currentInterviewRoundIndexRef.current,
          timerTimeToValue: setTimerTimeTo,
          interviewRounds: interviewRounds,
          internalDSARoundQuestions: internalDSARoundQuestions,
          currentDSAQuestionNumber: currentDSAQuestionNumber,
        });
      } else if (
        currentInterviewRoundIndexRef.current < interviewRounds.length
      ) {
        currentInterviewRoundIndexRef.current += 1;
        interviewRounds[currentInterviewRoundIndexRef.current - 1].roundType ==
        "coding"
          ? await initialCoreSetupForDSARound({
              thisInterviewFirestoreID: interviewFirestoreID,
              roundTitle:
                interviewRounds[currentInterviewRoundIndexRef.current - 1]
                  .roundTitle,
              interviewRounds: interviewRounds,
              currentDSAQuestionNumber: currentDSAQuestionNumber - 1,
            })
          : setConversationAndTimeStamps({
              ...commonParamsForSetConversationAndTimeStamps,
              interviewRounds: interviewRounds,
              currentInterviewRoundIndex: currentInterviewRoundIndexRef.current,
            });
      } else {
        await overrideVariableATL1orL2CollectionFStore({
          parent_collection: firestoreCollectionId,
          parent_document: firestoreDocumentId,
          child_collection: firestoreChildCollectionId,
          child_document: interviewFirestoreID,
          variableToUpdate: "totalTimeTaken",
          updatedValue: totalTimeTaken + timeTakenForRound,
        });
        initialSetupForReportsRound();
      }
    }
  }
};

export default InterviewBot;
InterviewBot.propTypes = {
  interviewType: PropTypes.string,
  difficultyLevel: PropTypes.string,
  firestoreChildCollectionId: PropTypes.string,
  firestoreCollectionId: PropTypes.string.isRequired,
  firestoreDocumentId: PropTypes.string,
  selectedCompanyCard: PropTypes.string,
  theoryRoundQuestions: PropTypes.array,
  hrRoundQuestions: PropTypes.array,
  totalNumberOfDSAQuestions: PropTypes.number,
  dsaQuestionsArray: PropTypes.array,
  dsaQuestionsIdArray: PropTypes.array,
  selectedAvatar: PropTypes.object.isRequired,
  doesRequireResume: PropTypes.bool,
  sendInterviewRounds: PropTypes.array,
  setThisInterviewFirestoreIDForHiringProcess: PropTypes.func,
  setThisRoundReportForHiringProcess: PropTypes.func,
  sendResumeText: PropTypes.string,
  interviewIDSlug: PropTypes.string,
  shouldShowReportAtEnd: PropTypes.bool,
  proctoringScreenshotGap: PropTypes.number,
  user: PropTypes.object.isRequired,
  shouldVideoProctor: PropTypes.bool,
  shouldRecordAudio: PropTypes.bool,
  shouldRecordScreen: PropTypes.bool,
};
