export const HiringCompanySpecificData = {
  wipro: {
    companyName: "Wipro",
    roundsData: {
      "onlineTest-Aptitude": {
        firestoreCollectionId: "hiring_online_test_submissions",
        companyName: "Wipro",
        isASingleTest: false,
        configArray: [
          {
            category: "WIPRO_Quantitative_Aptitude",
            numberOfDocuments: 16,
            timeForThisRound: 960,
          },
          {
            category: "WIPRO_Logical_Reasoning",
            numberOfDocuments: 14,
            timeForThisRound: 840,
          },
          {
            category: "WIPRO_Verbal_English",
            numberOfDocuments: 22,
            timeForThisRound: 1320,
          },
        ],
      },
      codingRound: {
        firestoreCollectionId: "hiring_coding_round_submissions",
        numberOfQuestions: 2,
        totalTime: 3600,
      },
      "interview-Technical": {
        firestoreCollectionId: "hiring_interview_submissions",
        selectedCompanyCard: "CREST",
        selectedAvatar: {
          name: "Callie",
          voiceLanguage: "en-us",
          voiceName: "Google US English",
        },
        doesRequireResume: false,
        numberOfTheoryQuestions: 4,
        numberOfHRQuestions: 0,
        totalNumberOfDSAQuestions: 0,
        difficultyLevel: "Beginner",
        rounds: [
          {
            roundTime: 600,
            roundTitle: "Theory",
            roundType: "normal",
            weightage: 0.5,
          },
          {
            roundTime: 480,
            roundTitle: "Resume",
            roundType: "normal",
            weightage: 0.5,
          },
        ],
      },
      "interview-HR": {
        firestoreCollectionId: "hiring_interview_submissions",
        selectedCompanyCard: "CREST",
        selectedAvatar: {
          name: "Callie",
          voiceLanguage: "en-us",
          voiceName: "Google US English",
        },
        doesRequireResume: false,
        numberOfTheoryQuestions: 0,
        numberOfHRQuestions: 3,
        totalNumberOfDSAQuestions: 0,
        difficultyLevel: "Beginner",
        rounds: [
          {
            roundTime: 480,
            roundTitle: "HR",
            roundType: "normal",
            weightage: 1.0,
          },
        ],
      },
    },
  },
  infosys: {
    companyName: "Infosys",
    roundsData: {
      "onlineTest-Aptitude": {
        firestoreCollectionId: "hiring_online_test_submissions",
        companyName: "Wipro",
        isASingleTest: false,
        configArray: [
          {
            category: "Infosys_Reasoning_Ability",
            numberOfDocuments: 15,
            timeForThisRound: 960,
          },
          {
            category: "Infosys_Mathematical_Ability",
            numberOfDocuments: 10,
            timeForThisRound: 840,
          },
          {
            category: "Infosys_Verbal_Ability",
            numberOfDocuments: 20,
            timeForThisRound: 1320,
          },
          {
            category: "Infosys_PseudoCode",
            numberOfDocuments: 5,
            timeForThisRound: 1320,
          },
          {
            category: "Infosys_Puzzle_Solving",
            numberOfDocuments: 4,
            timeForThisRound: 1320,
          },
        ],
      },
      "interview-Technical": {
        firestoreCollectionId: "hiring_interview_submissions",
        selectedCompanyCard: "CREST",
        selectedAvatar: {
          name: "Callie",
          voiceLanguage: "en-us",
          voiceName: "Google US English",
        },
        doesRequireResume: false,
        numberOfTheoryQuestions: 4,
        numberOfHRQuestions: 0,
        totalNumberOfDSAQuestions: 0,
        difficultyLevel: "Beginner",
        rounds: [
          {
            roundTime: 600,
            roundTitle: "Theory",
            roundType: "normal",
            weightage: 0.5,
          },
          {
            roundTime: 480,
            roundTitle: "Resume",
            roundType: "normal",
            weightage: 0.5,
          },
          // {
          //   roundTime: 60,
          //   roundTitle: "DSA",
          //   roundType: "coding",
          // },
        ],
      },
      codingRound: {
        firestoreCollectionId: "hiring_coding_round_submissions",
        numberOfQuestions: 1,
        totalTime: 900,
      },
      "interview-HR": {
        firestoreCollectionId: "hiring_interview_submissions",
        selectedCompanyCard: "CREST",
        selectedAvatar: {
          name: "Callie",
          voiceLanguage: "en-us",
          voiceName: "Google US English",
        },
        doesRequireResume: false,
        numberOfTheoryQuestions: 0,
        numberOfHRQuestions: 3,
        totalNumberOfDSAQuestions: 0,
        difficultyLevel: "Beginner",
        rounds: [
          {
            roundTime: 480,
            roundTitle: "HR",
            roundType: "normal",
            weightage: 1.0,
          },
        ],
      },
    },
  },
  tcs: {
    companyName: "TCS",
    roundsData: {
      "onlineTest-Aptitude": {
        firestoreCollectionId: "hiring_online_test_submissions",
        companyName: "TCS - Foundation",
        isASingleTest: false,
        configArray: [
          {
            category: "TCS_Verbal_Ability",
            numberOfDocuments: 20,
            timeForThisRound: 1500,
          },
          {
            category: "TCS_Numerical_Ability",
            numberOfDocuments: 20,
            timeForThisRound: 1500,
          },
          {
            category: "TCS_Logical_Ability",
            numberOfDocuments: 25,
            timeForThisRound: 1500,
          },
        ],
      },
      "onlineTest-Advanced-Aptitude": {
        firestoreCollectionId: "hiring_online_test_submissions",
        companyName: "TCS - Advanced",
        isASingleTest: false,
        configArray: [
          {
            category: "TCS_Advance_Aptitude",
            numberOfDocuments: 20,
            timeForThisRound: 1500,
          },
          {
            category: "TCS_Advanced_Reasoning_Ability",
            numberOfDocuments: 20,
            timeForThisRound: 1500,
          },
        ],
      },
      codingRound: {
        firestoreCollectionId: "hiring_coding_round_submissions",
        numberOfQuestions: 2,
        totalTime: 3300,
      },
      "interview-Technical": {
        firestoreCollectionId: "hiring_interview_submissions",
        selectedCompanyCard: "CREST",
        selectedAvatar: {
          name: "Callie",
          voiceLanguage: "en-us",
          voiceName: "Google US English",
        },
        doesRequireResume: false,
        numberOfTheoryQuestions: 4,
        numberOfHRQuestions: 0,
        totalNumberOfDSAQuestions: 0,
        difficultyLevel: "Beginner",
        rounds: [
          {
            roundTime: 600,
            roundTitle: "Theory",
            roundType: "normal",
            weightage: 0.5,
          },
          {
            roundTime: 480,
            roundTitle: "Resume",
            roundType: "normal",
            weightage: 0.5,
          },
        ],
      },
      "interview-HR": {
        firestoreCollectionId: "hiring_interview_submissions",
        selectedCompanyCard: "CREST",
        selectedAvatar: {
          name: "Callie",
          voiceLanguage: "en-us",
          voiceName: "Google US English",
        },
        doesRequireResume: false,
        numberOfTheoryQuestions: 0,
        numberOfHRQuestions: 3,
        totalNumberOfDSAQuestions: 0,
        difficultyLevel: "Beginner",
        rounds: [
          {
            roundTime: 480,
            roundTitle: "HR",
            roundType: "normal",
            weightage: 1.0,
          },
        ],
      },
    },
  },
  "generic-crest": {
    companyName: "generic-crest",
    roundsData: {
      "onlineTest-Aptitude": {
        firestoreCollectionId: "hiring_online_test_submissions",
        companyName: "CREST",
        isASingleTest: false,
        configArray: [
          {
            category: "WIPRO_Logical_Reasoning",
            numberOfDocuments: 14,
            timeForThisRound: 840,
          },
          {
            category: "WIPRO_Verbal_English",
            numberOfDocuments: 22,
            timeForThisRound: 1320,
          },
          {
            category: "Infosys_Puzzle_Solving",
            numberOfDocuments: 4,
            timeForThisRound: 1320,
          },
        ],
      },
      codingRound: {
        firestoreCollectionId: "hiring_coding_round_submissions",
        numberOfQuestions: 2,
        totalTime: 3600,
      },
      "interview-Technical": {
        firestoreCollectionId: "hiring_interview_submissions",
        selectedCompanyCard: "CREST",
        selectedAvatar: {
          name: "Callie",
          voiceLanguage: "en-us",
          voiceName: "Google US English",
        },
        doesRequireResume: false,
        numberOfTheoryQuestions: 4,
        numberOfHRQuestions: 0,
        totalNumberOfDSAQuestions: 0,
        difficultyLevel: "Beginner",
        rounds: [
          {
            roundTime: 600,
            roundTitle: "Theory",
            roundType: "normal",
            weightage: 0.5,
          },
          {
            roundTime: 480,
            roundTitle: "Resume",
            roundType: "normal",
            weightage: 0.5,
          },
        ],
      },
      "interview-HR": {
        firestoreCollectionId: "hiring_interview_submissions",
        selectedCompanyCard: "CREST",
        selectedAvatar: {
          name: "Callie",
          voiceLanguage: "en-us",
          voiceName: "Google US English",
        },
        doesRequireResume: false,
        numberOfTheoryQuestions: 0,
        numberOfHRQuestions: 3,
        totalNumberOfDSAQuestions: 0,
        difficultyLevel: "Beginner",
        rounds: [
          {
            roundTime: 480,
            roundTitle: "HR",
            roundType: "normal",
            weightage: 1.0,
          },
        ],
      },
    },
  },
};

export const HiringCompanySpecificObjectiveTestData = {
  wipro: [
    {
      name: "Quantitative_Aptitude",
      Number_Of_Questions: 16,
      Time_For_This_Round: "16 min",
      Cut_Off: "65%",
    },
    {
      name: "Logical_Reasoning",
      Number_Of_Questions: 14,
      Time_For_This_Round: "14 min",
      Cut_Off: "65%",
    },
    {
      name: "Verbal_English",
      Number_Of_Questions: 22,
      Time_For_This_Round: "18 min",
      Cut_Off: "65%",
    },
  ],
  infosys: [
    {
      name: "Reasoning_Ability",
      Number_Of_Questions: 15,
      Time_For_This_Round: "25 min",
      Cut_Off: "65%",
    },
    {
      name: "Mathematical_Ability",
      Number_Of_Questions: 10,
      Time_For_This_Round: "35 min",
      Cut_Off: "65%",
    },
    {
      name: "Verbal_Ability",
      Number_Of_Questions: 20,
      Time_For_This_Round: "20 min",
      Cut_Off: "65%",
    },
    {
      name: "Pseudo_Code",
      Number_Of_Questions: 5,
      Time_For_This_Round: "10 min",
      Cut_Off: "65%",
    },
    {
      name: "Puzzle_Solving",
      Number_Of_Questions: 4,
      Time_For_This_Round: "10 min",
      Cut_Off: "65%",
    },
  ],
  tcs: [
    {
      name: "Numerical_Ability",
      Number_Of_Questions: 25,
      Time_For_This_Round: "20 min",
      Cut_Off: "65%",
    },
    {
      name: "Logical_Ability",
      Number_Of_Questions: 25,
      Time_For_This_Round: "20 min",
      Cut_Off: "65%",
    },
    {
      name: "Verbal_Ability",
      Number_Of_Questions: 25,
      Time_For_This_Round: "25 min",
      Cut_Off: "65%",
    },
    {
      name: "Advance_Aptitude",
      Number_Of_Questions: 25,
      Time_For_This_Round: "20 min",
      Cut_Off: "65%",
    },
    {
      name: "Advanced_Reasoning_Ability",
      Number_Of_Questions: 25,
      Time_For_This_Round: "20 min",
      Cut_Off: "65%",
    },
  ],
  "generic-crest": [
    {
      name: "Logical_Reasoning",
      Number_Of_Questions: 14,
      Time_For_This_Round: "14 min",
      Cut_Off: "65%",
    },
    {
      name: "Verbal_English",
      Number_Of_Questions: 22,
      Time_For_This_Round: "22 min",
      Cut_Off: "65%",
    },
    {
      name: "Puzzle_Solving",
      Number_Of_Questions: 4,
      Time_For_This_Round: "22 min",
      Cut_Off: "65%",
    },
  ],
};
export const didClearRoundWiseCutoff = (
  inputString,
  totalQuestions,
  correctQuestions
) => {
  const firstUnderscoreIndex = inputString.indexOf("_");

  if (firstUnderscoreIndex !== -1) {
    const company = inputString.substring(0, firstUnderscoreIndex);
    const category = inputString.substring(firstUnderscoreIndex + 1);

    const companyData =
      HiringCompanySpecificObjectiveTestData[company.toLowerCase()];

    if (companyData) {
      const test = companyData.find(
        (test) => test.name.toLowerCase() === category.toLowerCase()
      );
      console.log(
        "DWaraka didClearRoundWiseCutoff correctQuestions, totalQuestions: ",
        correctQuestions,
        totalQuestions
      );
      const percentageCorrect = (correctQuestions / totalQuestions) * 100;
      console.log(
        "DWaraka didClearRoundWiseCutoff percentageCorrect: ",
        percentageCorrect
      );

      return percentageCorrect >= (test?.Cut_Off || 65);
    }
  }

  return false;
};
