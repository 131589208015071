import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { addReportAQuestionToRealTimeDB } from "../../../utils/firebaseFunctions/realtimeDBFunctions";
import "./ReportQuestionForm.css";

const ReportObjectiveQuestionForm = ({
  userUid,
  questionId,
  setShouldShowReportModal,
  dBPath,
}) => {
  ReportObjectiveQuestionForm.propTypes = {
    userUid: PropTypes.string.isRequired,
    questionId: PropTypes.string.isRequired,
    setShouldShowReportModal: PropTypes.func.isRequired,
    dBPath: PropTypes.string.isRequired,
  };
  const [reportReason, setReportReason] = useState("Content Error");
  const [additionalComments, setAdditionalComments] = useState("");
  const [severityLevel, setSeverityLevel] = useState("Low");
  // const [anonymity, setAnonymity] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const date = new Date();
    await addReportAQuestionToRealTimeDB({
      questionId: questionId,
      dBPath: dBPath,
      reportData: {
        userUid: userUid,
        reportReason: reportReason,
        additionalComments: additionalComments,
        severityLevel: severityLevel,
        reportedOn: date.toLocaleString(),
        // anonymity: anonymity,
      },
    });
    toast.success(`Question reported successfully`);
    setShouldShowReportModal(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="row my-2">
        <div className="feedback-form-subheading mt-3 mx-0 px-0 col-md-6">
          Report Reason:
        </div>
        <div className="d-inline px-0 mx-0 col-md-6 select-wrapper">
          <select
            className="form-control "
            id="reason-select"
            value={reportReason}
            onChange={(e) => setReportReason(e.target.value)}
          >
            <option value="">Select a reason...</option>
            <option value="Grammatical Error">Grammatical Error</option>
            <option value="Typographical Error">Typographical Error</option>
            <option value="Content Error">Content Error</option>
            <option value="Ambiguity">Ambiguity</option>
            <option value="Other">Other</option>
          </select>
          <span className="select-arrow">
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        </div>
      </Form.Group>
      <Form.Group className="row mb-2">
        <div className="feedback-form-subheading mt-3 mx-0 px-0 col-md-6">
          Severity Level:
        </div>
        <div className="d-inline px-0 mx-0 col-md-6 select-wrapper">
          <select
            className="form-control "
            id="severity-level-select"
            value={severityLevel}
            onChange={(e) => setSeverityLevel(e.target.value)}
          >
            <option>Low</option>
            <option>Medium</option>
            <option>High</option>
          </select>
          <span className="select-arrow">
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        </div>
      </Form.Group>
      <Form.Group className="row mb-2">
        <div className="feedback-form-subheading mt-3 mx-0 px-0 col-md-6">
          Additional Details:
        </div>
        <div className="d-inline px-0 mx-0 col-md-6">
          <Form.Control
            as="textarea"
            rows={3}
            value={additionalComments}
            onChange={(e) => setAdditionalComments(e.target.value)}
          />
        </div>
      </Form.Group>
      {/* <Form.Group className="row mb-2 align-items-center">
        <div className="feedback-form-subheading mt-3 mx-0 px-0 col-md-6">
          Anonymity:
        </div>
        <div className="d-inline px-0 mx-0 col-md-6">
          <Form.Check
            type="checkbox"
            label="Report anonymously"
            checked={anonymity}
            onChange={(e) => setAnonymity(e.target.checked)}
          />
        </div>
      </Form.Group> */}
      <div className="text-center mt-3">
        <button className="btn btn-primary" type="submit">
          Submit
        </button>
      </div>
    </Form>
  );
};

export default ReportObjectiveQuestionForm;
