const codingQuestionsList = {
  wipro: [
    {
      questionId: "wipro-1",
      question:
        "Given an integer N, count the numbers having an odd number of factors from 1 to N (inclusive).",
      inputFormat: "The input consists of a single integer N.",
      outputFormat:
        "Print an integer representing the count of numbers having an odd number of factors.",
      sampleInput: "5",
      sampleOutput: "2",
      testCases: [
        {
          input: "5",
          output: "2",
        },
        {
          input: "10",
          output: "3",
        },
        {
          input: "20",
          output: "4",
        },
        {
          input: "15",
          output: "3",
        },
        {
          input: "25",
          output: "5",
        },
        {
          input: "30",
          output: "5",
        },
        {
          input: "50",
          output: "7",
        },
        {
          input: "100",
          output: "10",
        },
        {
          input: "200",
          output: "14",
        },
        {
          input: "500",
          output: "22",
        },
      ],

      constraints: ["<p>0 ≤ N ≤ 10<sup>9</sup></p>"],
    },
    {
      questionId: "wipro-2",
      question:
        "Given a number N, find the minimum number of squares of any number that sum up to N.",
      inputFormat: "The input consists of an integer N.",
      outputFormat:
        "Print an integer representing the minimum number of squares.",
      sampleInput: "100",
      sampleOutput: "1",
      testCases: [
        {
          input: "100",
          output: "1",
        },
        {
          input: "6",
          output: "3",
        },
        {
          input: "17",
          output: "2",
        },
        {
          input: "2",
          output: "2",
        },
        {
          input: "4",
          output: "1",
        },
        {
          input: "9",
          output: "1",
        },
        {
          input: "15",
          output: "4",
        },
        {
          input: "25",
          output: "1",
        },
        {
          input: "50",
          output: "2",
        },
        {
          input: "72",
          output: "2",
        },
      ],
      constraints: ["<p>1 ≤ N ≤ 10000</p>"],
    },
    {
      questionId: "wipro-3",
      question:
        "Given an array Arr[] of N integers, find the contiguous sub-array with the maximum sum and print its sum.",
      inputFormat:
        "The input consists of an integer N denoting the size of the array, followed by N integers representing the array elements.",
      outputFormat:
        "print an integer representing the sum of the maximum sum contiguous sub-array.",
      sampleInput: "5\n1 2 3 -2 5",
      sampleOutput: "9",
      testCases: [
        {
          input: "5 1 2 3 -2 5",
          output: "9",
        },
        {
          input: "4 -1 -2 -3 -4",
          output: "-1",
        },
        {
          input: "6 -2 -3 4 -1 -2 1 5",
          output: "7",
        },
        {
          input: "5 -1 -2 -3 -4 -5",
          output: "-1",
        },
        {
          input: "6 1 2 3 4 5 6",
          output: "21",
        },
        {
          input: "3 -2 1 -3",
          output: "1",
        },
        {
          input: "5 -2 -3 4 -1 -2",
          output: "4",
        },
        {
          input: "8 1 -2 3 4 -5 6 7 -8",
          output: "15",
        },
        {
          input: "4 -1 -2 -3 -4",
          output: "-1",
        },
        {
          input: "5 1 2 3 -4 5",
          output: "7",
        },
      ],
      constraints: [
        "<p>1 ≤ N ≤ 10<sup>6</sup></p>",
        "<p>-10<sup>7</sup> ≤ A[i] ≤ 10<sup>7</sup></p>",
      ],
    },
    {
      questionId: "wipro-4",
      question: "Validate if the given string is a valid IPv4 address.",
      inputFormat:
        "The input consists of a string representing the IPv4 address to be validated.",
      outputFormat: "print 1 if the IPv4 address is valid, otherwise print 0.",
      sampleInput: "222.111.111.111",
      sampleOutput: "1",
      testCases: [
        {
          input: "5555..555",
          output: "0",
        },
        {
          input: "192.168.0.1",
          output: "1",
        },
        {
          input: "0.0.0.0",
          output: "1",
        },
        {
          input: "172.16.254.1",
          output: "1",
        },
        {
          input: "192.168.1.100",
          output: "1",
        },
        {
          input: "123.45.67.89",
          output: "1",
        },
        {
          input: "1.2.3.4",
          output: "1",
        },
        {
          input: "256.0.0.1",
          output: "0",
        },
        {
          input: "0.0.0.256",
          output: "0",
        },
        {
          input: "-1.0.0.0",
          output: "0",
        },
      ],
      constraints: ["<p>1 ≤ length of string ≤ 50</p>"],
    },
    {
      questionId: "wipro-5",
      question: "Reorder a Singly Linked List.",
      inputFormat:
        "A single line string representing the linked list values separated by space.  A0→A1→...→An-2→An-1, reorder it to: A0→An-1→A1→An-2→A2→An-3→...",
      outputFormat:
        "A single line string representing the reordered linked list values separated by space.",
      sampleInput: "1 2 3",
      sampleOutput: "1 3 2",
      testCases: [
        {
          input: "1 7 3 4",
          output: "1 4 7 3",
        },
        {
          input: "5 8 2 6 1 9",
          output: "5 9 8 1 2 6",
        },
        {
          input: "2 5 9",
          output: "2 9 5",
        },
        {
          input: "4 5 6 7",
          output: "4 7 5 6",
        },
        {
          input: "9 8 7 6 5 4",
          output: "9 4 8 5 7 6",
        },
        {
          input: "1 2 3 4 5",
          output: "1 5 2 4 3",
        },
        {
          input: "10 20 30 40 50 60",
          output: "10 60 20 50 30 40",
        },
        {
          input: "100 200 300 400 500",
          output: "100 500 200 400 300",
        },
        {
          input: "11 22 33 44 55 66 77 88 99 100",
          output: "11 100 22 99 33 88 44 77 55 66",
        },
        {
          input: "101 202 303 404 505 606 707 808 909 1000",
          output: "101 1000 202 909 303 808 404 707 505 606",
        },
      ],
      constraints: [
        "<p>1 ≤ N ≤ 5*10<sup>5</sup></p>",
        "<p>0 ≤ A[i] ≤ 10<sup>5</sup></p>",
      ],
    },
    {
      questionId: "wipro-6",
      question: "Fix the Swapped Nodes in a Binary Search Tree (BST).",
      inputFormat:
        "The root of the BST where exactly two nodes were swapped. The tree is represented in a format where each node is represented by its value, and the structure is described through nested brackets.",
      outputFormat: "Print 1 if the BST is successfully fixed, 0 otherwise.",
      sampleInput: "10 5 8 2 20",
      sampleOutput: "1",
      testCases: [
        {
          input: "11 3 4 17 10",
          output: "0",
        },
        {
          input: "15 5 2 10 8 12 20 18 22",
          output: "0",
        },
        {
          input: "7 3 1 4 2 9 8 11 10",
          output: "0",
        },
        {
          input: "100 50 150 40 60 30 70",
          output: "0",
        },
        {
          input: "10 5 20 8 15",
          output: "0",
        },
        {
          input: "50 30 70 25 35 60 80 20 40 55 65 75 85",
          output: "0",
        },
        {
          input: "200 100 300 50 150 250 350 25 75 125 175 225 275 325 375",
          output: "0",
        },
        {
          input: "500 250 750 125 375 625 875 50 175 325 425 575 675 825 925",
          output: "0",
        },
        {
          input: "20 15 25 10 30 18 40 5 12 22 28 35 45",
          output: "0",
        },
        {
          input:
            "150 100 200 75 125 175 225 50 60 70 80 90 110 120 130 140 160 170 180 190 210 220 230 240 250 260 270 280 290 300 310 320 330 340 350 360 370 380 390 400",
          output: "0",
        },
      ],
      constraints: ["<p>1 ≤ Number of nodes ≤ 10<sup>3</sup></p>"],
    },
    {
      questionId: "wipro-7",
      question:
        "Given a sorted array of size N and an integer K, find the position (0-based indexing) at which K is present in the array using binary search.",
      inputFormat:
        "The input consists of an integer N denoting the size of the array, followed by an integer K and then N sorted integers in the array.",
      outputFormat:
        "Print an integer representing the position (0-based indexing) where K is present in the array. If K is not present, Print -1.",
      sampleInput: "5\n41 2 3 4 5",
      sampleOutput: "3",
      testCases: [
        {
          input: "5 4 1 2 3 4 5",
          output: "3",
        },
        {
          input: "6 25 10 20 30 40 50",
          output: "-1",
        },
        {
          input: "4 6 1 3 5 7",
          output: "-1",
        },
        {
          input: "5 2 1 2 3 4 5",
          output: "1",
        },
        {
          input: "5 5 1 2 3 4 5",
          output: "4",
        },
        {
          input: "5 1 1 2 3 4 5",
          output: "0",
        },
        {
          input: "5 6 1 2 3 4 5",
          output: "-1",
        },
        {
          input: "8 10 2 4 6 8 10 12 14 16",
          output: "4",
        },
        {
          input: "6 3 1 2 3 4 5 6",
          output: "2",
        },
        {
          input: "7 4 1 2 3 4 5 6 7",
          output: "3",
        },
      ],
      constraints: [
        "<p>1 ≤ N ≤ 10<sup>5</sup></p>",
        "<p>1 ≤ arr[i] ≤ 10<sup>6</sup></p>",
        "<p>1 ≤ K ≤ 10<sup>6</sup></p>",
      ],
    },
    {
      questionId: "wipro-8",
      question:
        "Find the maximum of the minimum of every window size in the given integer array.",
      inputFormat:
        "An integer N representing the size of the array, followed by N integers representing the elements of the array.",
      outputFormat:
        "Print a space-separated string of integers representing the maximum of the minimums for each window size.",
      sampleInput: "7\n10 20 30 50 10 70 30",
      sampleOutput: "70 30 20 10 10 10 10",
      testCases: [
        {
          input: "3 10 20 30",
          output: "30 20 10",
        },
        {
          input: "5 5 4 3 2 1",
          output: "5 4 3 2 1",
        },
        {
          input: "1 100",
          output: "100",
        },
        {
          input: "5 10 20 30 40 50",
          output: "50 40 30 20 10",
        },
        {
          input: "8 5 10 5 8 10 5 5 8",
          output: "10 8 5 5 5 5 5 5",
        },
        {
          input: "7 5 10 15 10 5 20 25",
          output: "25 20 10 5 5 5 5",
        },
        {
          input: "10 1 2 3 4 5 6 7 8 9 10",
          output: "10 9 8 7 6 5 4 3 2 1",
        },
        {
          input: "9 9 8 7 6 5 4 3 2 1",
          output: "9 8 7 6 5 4 3 2 1",
        },
        {
          input: "9 21 43 45 4 45 4 5 23 56",
          output: "56 43 21 4 4 4 4 4 4",
        },
      ],
      constraints: [
        "<p>1 ≤ N ≤ 10<sup>5</sup></p>",
        "<p>1 ≤ arr[i] ≤ 10<sup>6</sup></p>",
      ],
    },
    {
      questionId: "wipro-9",
      question:
        "Given an array of positive numbers, find the number of possible contiguous subarrays having a product less than a given number k.",
      inputFormat:
        "Two integers n and k, followed by n integers representing the array elements.",
      outputFormat:
        "An integer representing the count of contiguous subarrays.",
      sampleInput: "4 10\n1 2 3 4",
      sampleOutput: "7",
      testCases: [
        {
          input: "7 100 1 9 2 8 6 4 3",
          output: "16",
        },
        {
          input: "5 5 1 2 3 4 5",
          output: "5",
        },
        {
          input: "3 12 4 6 3",
          output: "3",
        },
        {
          input: "5 15 1 2 3 4 5",
          output: "9",
        },
        {
          input: "8 50 1 2 3 4 5 6 7 8",
          output: "17",
        },
        {
          input: "4 20 1 5 2 4",
          output: "8",
        },
        {
          input: "6 30 2 5 3 10 4 15",
          output: "8",
        },
        {
          input: "8 50 3 8 2 10 6 5 4 7",
          output: "15",
        },
        {
          input: "10 40 4 3 2 5 7 6 10 8 9 1",
          output: "17",
        },
        {
          input: "9 75 1 2 3 4 5 6 7 8 9",
          output: "21",
        },
      ],
      constraints: [
        "<p>1 ≤ n ≤ 10<sup>6</sup></p>",
        "<p>1 ≤ k ≤ 10<sup>15</sup></p>",
        "<p>1 ≤ a[i] ≤ 10<sup>5</sup></p>",
      ],
    },
    {
      questionId: "wipro-10",
      question:
        "Given two strings wild and pattern. Determine if the given two strings can be matched given that wild string may contain * and ?.",
      inputFormat: "Two strings 'wild' and 'pattern' separated by a space.",
      outputFormat: "String 'Yes' or 'No'",
      sampleInput: "ge*ks geeks",
      sampleOutput: "Yes",
      testCases: [
        {
          input: "ge?ks* geeksforgeeks",
          output: "Yes",
        },
        {
          input: "ab*c abcd",
          output: "No",
        },
        {
          input: "abcde* fghij",
          output: "No",
        },
        {
          input: "aaaa*bbbb aaaaaaaaaa",
          output: "No",
        },
        {
          input: "a*bcd abcd",
          output: "Yes",
        },
        {
          input: "a?cd abcd",
          output: "Yes",
        },
        {
          input: "abc* xyz",
          output: "No",
        },
        {
          input: "a*a abac",
          output: "No",
        },
        {
          input: "abc* abcde",
          output: "Yes",
        },
        {
          input: "abc* abcdef",
          output: "Yes",
        },
      ],
      constraints: ["<p>1 ≤ length of the two string ≤ 10<sup>3</sup></p>"],
    },
    {
      questionId: "wipro-11",
      question: "Sort the given doubly linked list of size N using quicksort.",
      inputFormat:
        "A doubly linked list represented as a sequence of space-separated integers.",
      outputFormat:
        "Space-separated integers representing the sorted linked list.",
      sampleInput: "4 2 9",
      sampleOutput: "2 4 9",
      testCases: [
        {
          input: "1 4 9 2",
          output: "1 2 4 9",
        },
        {
          input: "7 3 5",
          output: "3 5 7",
        },
        {
          input: "10 5 8",
          output: "5 8 10",
        },
        {
          input: "2 3 1 5 4",
          output: "1 2 3 4 5",
        },
        {
          input: "5 9 8 7",
          output: "5 7 8 9",
        },
        {
          input: "10 1 3 2 5",
          output: "1 2 3 5 10",
        },
        {
          input: "7 6 2 9 1 5",
          output: "1 2 5 6 7 9",
        },
        {
          input: "3 5 4 1",
          output: "1 3 4 5",
        },
        {
          input: "2 1 3 5 4",
          output: "1 2 3 4 5",
        },
        {
          input: "8 3 6 7",
          output: "3 6 7 8",
        },
      ],
      constraints: [
        "<p>1 ≤ N ≤ 2000</p>",
        "<p>1 ≤ Each Element of Double Linked List ≤ 10<sup>5</sup></p>",
      ],
    },
    {
      questionId: "wipro-12",
      question:
        "Given an integer N, count the numbers having an odd number of factors from 1 to N (inclusive).",
      inputFormat:
        "The input consists of a single integer N, representing the range from 1 to N (inclusive).",
      outputFormat:
        "Print an integer, representing the count of numbers having an odd number of factors from 1 to N (inclusive).",
      sampleInput: "5",
      sampleOutput: "2",
      testCases: [
        {
          input: "5",
          output: "2",
        },
        {
          input: "1",
          output: "1",
        },
        {
          input: "10",
          output: "3",
        },
        {
          input: "15",
          output: "3",
        },
        {
          input: "20",
          output: "4",
        },
        {
          input: "30",
          output: "5",
        },
        {
          input: "100",
          output: "10",
        },
        {
          input: "200",
          output: "14",
        },
        {
          input: "500",
          output: "22",
        },
        {
          input: "1000",
          output: "31",
        },
      ],
      constraints: ["<p>0 ≤ N ≤ 10<sup>9</sup></p>"],
    },
    {
      questionId: "wipro-13",
      question:
        "Given a positive integer N, print the count of set bits in it.",
      inputFormat:
        "The input consists of a single line containing a positive integer N.",
      outputFormat:
        "Print a single integer representing the count of set bits in the binary representation of N.",
      sampleInput: "6",
      sampleOutput: "2",
      testCases: [
        {
          input: "6",
          output: "2",
        },
        {
          input: "8",
          output: "1",
        },
        {
          input: "15",
          output: "4",
        },
        {
          input: "42",
          output: "3",
        },
        {
          input: "127",
          output: "7",
        },
        {
          input: "255",
          output: "8",
        },
        {
          input: "536870911",
          output: "29",
        },
        {
          input: "2147483647",
          output: "31",
        },
        {
          input: "1000000000",
          output: "13",
        },
        {
          input: "123456789",
          output: "16",
        },
      ],
      constraints: ["<p>1 ≤ N ≤ 10<sup>9</sup></p>"],
    },
    {
      questionId: "wipro-14",
      question:
        "Given a string str of lowercase alphabets, find the maximum occurring character. If more than one character occurs the maximum number of times, print the lexicographically smaller character.",
      inputFormat:
        "The input consists of a single line containing a string str of lowercase alphabets.",
      outputFormat:
        "Print a single character representing the maximum occurring character in the string str.",
      sampleInput: "testsample",
      sampleOutput: "e",
      testCases: [
        {
          input: "testsample",
          output: "e",
        },
        {
          input: "output",
          output: "t",
        },
        {
          input: "aabbc",
          output: "a",
        },
        {
          input: "teststring",
          output: "t",
        },
        {
          input: "mississippi",
          output: "i",
        },
        {
          input: "abcdabcdabcd",
          output: "a",
        },
        {
          input: "abccbaabccba",
          output: "a",
        },
        {
          input: "azyxwvutsrqponmlkjihgfedcba",
          output: "a",
        },
        {
          input: "abcdefghijklmnopqrstuvwxyzzyxwvutsrqponmlkjihgfedcba",
          output: "a",
        },
        {
          input: "zxyxzyxyzxzyxy",
          output: "x",
        },
      ],
      constraints: ["<p>1 ≤ |s| ≤ 100</p>"],
    },
    {
      questionId: "wipro-15",
      question:
        "Given an array of words, find all shortest unique prefixes to represent each word in the given array. Assume that no word is a prefix of another.",
      inputFormat:
        "The first line contains an integer N, the number of words followed by N words separated by spaces.",
      outputFormat:
        "Print space-separated shortest unique prefixes for each word in the array.",
      sampleInput: "4\nzebra dog duck dove",
      sampleOutput: "z dog du dov",
      testCases: [
        {
          input: "4 zebra dog duck dove",
          output: "z dog du dov",
        },
        {
          input: "3 geeksgeeks geeksquiz geeksforgeeks",
          output: "geeksg geeksq geeksf",
        },
        {
          input: "5 apple apricot apron aprilia apricot",
          output: "app apricot apro april apricot",
        },
        {
          input: "5 abcde abcd abc ab ade",
          output: "abcde abcd abc ab ad",
        },
        {
          input: "4 one on only once",
          output: "one on onl onc",
        },
        {
          input: "6 alpha beta gamma goku gintama",
          output: "a b ga go gi",
        },
        {
          input: "3 xyz xy xylophone",
          output: "xyz xy xyl",
        },
        {
          input: "4 geeks geeksfor geeksforgeeks gfg",
          output: "geeks geeksfor geeksforg gf",
        },
        {
          input: "3 apple banana apricot",
          output: "app b apr",
        },
        {
          input: "5 cat dog duck dove deer",
          output: "c dog du dov de",
        },
      ],
      constraints: ["<p> 1 ≤ N, Length of each word ≤ 1000 </p>"],
    },
    {
      questionId: "wipro-16",
      question:
        "Given a string A and a dictionary of n words B, find out if A can be segmented into a space-separated sequence of dictionary words.",
      inputFormat:
        "The first line contains an integer n, the number of words in the dictionary followed by n words in the dictionary and followed by contains the input string A.",
      outputFormat:
        "Print 1 if A can be segmented into a space-separated sequence of dictionary words, and 0 otherwise.",
      sampleInput:
        "12\ni like sam sung samsung mobile ice cream icecream man go mango\nilike",
      sampleOutput: "1",
      testCases: [
        {
          input:
            "12 i like sam sung samsung mobile ice cream icecream man go mango ilike",
          output: "1",
        },
        {
          input:
            "12 i like sam sung samsung mobile ice cream icecream man go mango ilikesamsung",
          output: "1",
        },
        {
          input: "3 abc def ghi abcdefghi",
          output: "1",
        },
        {
          input: "4 apple pen pineapple applepen",
          output: "1",
        },
        {
          input: "2 car race carrace",
          output: "1",
        },
        {
          input: "3 alpha beta gamma alphabeta",
          output: "1",
        },
        {
          input: "4 cat dog duck dove catdogduckdove",
          output: "1",
        },
        {
          input: "4 cat dog duck dove catduck",
          output: "1",
        },
        {
          input: "4 water melon watermelon ice cream",
          output: "0",
        },
        {
          input: "2 hello world helloworld",
          output: "1",
        },
      ],
      constraints: ["<p> 1 <= N <= 12</p>", "<p>1 <= s <= 1000</p>"],
    },
    {
      questionId: "wipro-17",
      question:
        "Given a matrix cost of size n where cost[i][j] denotes the cost of moving from city i to city j. Your task is to complete a tour from the city 0 (0 based index) to all other cities such that you visit each city exactly once and then at the end come back to city 0 in min cost.",
      inputFormat:
        "The first line contains an integer n, the number of cities.and followed by n space-separated integers representing the cost matrix.",
      outputFormat:
        "Print an integer, the minimum cost required to complete the tour.",
      sampleInput: "2\n0 111 112 0",
      sampleOutput: "223",
      testCases: [
        {
          input: "2 0 111 112 0",
          output: "223",
        },
        {
          input: "3 0 1000 5000 5000 0 1000 1000 5000 0",
          output: "3000",
        },
        {
          input: "4 0 10 15 20 5 0 9 10 6 13 0 12 8 8 9 0",
          output: "35",
        },
        {
          input:
            "5 0 1 2 3 4 1 0 5 6 7 8 2 5 0 9 10 11 3 6 9 0 12 13 4 7 10 12 0",
          output: "14",
        },
        {
          input: "3 0 5 6 7 0 8 9 10 0",
          output: "22",
        },
        {
          input:
            "4 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000",
          output: "4000",
        },
        {
          input: "3 0 1 2 3 0 4 5 6 0",
          output: "10",
        },
        {
          input: "3 0 6 7 8 0 9 10 11 0",
          output: "25",
        },
        {
          input:
            "6 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000 1000",
          output: "6000",
        },
        {
          input:
            "5 0 10 20 30 40 10 0 15 25 35 20 15 0 45 55 30 25 45 0 65 40 35 55 65 0",
          output: "165",
        },
      ],
      constraints: [
        "<p>1 ≤ n ≤ 20</p>",
        "<p>1 ≤ cost[i][j] ≤ 10<sup>3</sup></p>",
      ],
    },
    {
      questionId: "wipro-18",
      question:
        "Given a string 'str' of lowercase ASCII characters, count the number of distinct continuous palindromic substrings present in the string.",
      inputFormat: "A string 'str' consisting of lowercase ASCII characters.",
      outputFormat:
        "An integer representing the count of distinct continuous palindromic substrings.",
      sampleInput: "abaaa",
      sampleOutput: "5",
      testCases: [
        {
          input: "geek",
          output: "4",
        },
        {
          input: "abcde",
          output: "5",
        },
        {
          input: "abba",
          output: "4",
        },
        {
          input: "aaaa",
          output: "4",
        },
        {
          input: "abccba",
          output: "6",
        },
        {
          input: "abcdcba",
          output: "7",
        },
        {
          input: "abbaed",
          output: "6",
        },
        {
          input: "aabbaa",
          output: "6",
        },
        {
          input: "bbbbb",
          output: "5",
        },
        {
          input: "mahend",
          output: "6",
        },
      ],
      constraints: [
        "<p> 1 ≤ N ≤ 3*10<sup>3</sup>, where N is the length of the string str.</p>",
      ],
    },
  ],
  infosys: [
    {
      questionId: "infosys-1",
      question:
        "Check if string 'b' can be obtained by rotating string 'a' by exactly 2 places in any direction.",
      inputFormat: "Two separated strings a and b.",
      outputFormat:
        "Print 1 if string 'b' can be obtained by rotating string 'a' by exactly 2 places, otherwise Print 0.",
      sampleInput: "amazon azonam",
      sampleOutput: "1",
      testCases: [
        {
          input: "amazon azonam",
          output: "1",
        },
        {
          input: "geeksforgeeks geeksgeeksfor",
          output: "0",
        },
        {
          input: "stackoverflow erflowstackov",
          output: "0",
        },
        {
          input: "abcde edcba",
          output: "0",
        },
        {
          input: "abcdefghij ijabcdefgh",
          output: "1",
        },
        {
          input: "abcde cdeab",
          output: "1",
        },
        {
          input: "abcde fghij",
          output: "0",
        },
        {
          input: "12345 45123",
          output: "1",
        },
        {
          input: "xyz zxy",
          output: "1",
        },
        {
          input: "abcdefghijklmnopqrstuvwxyz yzabcdefghijklmnopqrstuvwx",
          output: "1",
        },
      ],
      constraints: ["<p>1 ≤ length of a, b ≤ 10<sup>5</sup></p>"],
    },
    {
      questionId: "infosys-2",
      question:
        "Find the minimum number of jumps to reach the end of the array (starting from the first element) considering the maximum length of jumps from each element in the array.",
      inputFormat:
        "An integer N representing the number of elements in the array followed by N integers denoting the maximum length of the jump that can be made from each element.",
      outputFormat:
        "Print an integer representing the minimum number of jumps to reach the end of the array. Print -1 if unable to reach the end.",
      sampleInput: "11\n1 3 5 8 9 2 6 7 6 8 9",
      sampleOutput: "3",
      testCases: [
        {
          input: "11 1 3 5 8 9 2 6 7 6 8 9",
          output: "3",
        },
        {
          input: "6 1 4 3 2 6 7",
          output: "2",
        },
        {
          input: "5 1 1 1 1 0",
          output: "4",
        },
        {
          input: "5 0 0 0 0 0",
          output: "-1",
        },
        {
          input: "6 1 1 1 1 1 1",
          output: "5",
        },
        {
          input: "10 1 0 0 0 0 0 0 0 0 0",
          output: "-1",
        },
        {
          input: "10 5 5 5 5 5 5 5 5 5 5",
          output: "2",
        },
        {
          input: "7 3 1 3 1 3 1 3",
          output: "3",
        },
        {
          input: "10 10 1 10 1 10 1 10 1 10 1",
          output: "1",
        },
        {
          input: "5 5 5 5 5 5",
          output: "1",
        },
      ],
      constraints: [
        "<p>1 ≤ N ≤ 10<sup>7</sup></p>",
        "<p>0 ≤ arr[i] ≤ 10<sup>7</sup></p>",
      ],
    },
    {
      questionId: "infosys-3",
      question:
        "Find the minimum number of platforms required for the railway station to manage arrival and departure of all trains without any train waiting.",
      inputFormat:
        "An integer N representing the number of trains followed by two arrays of N elements each. The first array represents arrival times, and the second array represents departure times, all in four-digit format (HHMM).",
      outputFormat:
        "Print an integer representing the minimum number of platforms required.",
      sampleInput:
        "6\n0900 0940 0950 1100 1500 1800\n0910 1200 1120 1130 1900 2000",
      sampleOutput: "3",
      testCases: [
        {
          input:
            "6 0900 0940 0950 1100 1500 1800 0910 1200 1120 1130 1900 2000",
          output: "3",
        },
        -{
          input: "3 0900 1100 1235 1000 1200 1240",
          output: "1",
        },
        {
          input: "4 0100 0200 0300 0400 0250 0350 0450 0550",
          output: "2",
        },
        {
          input: "1 0900 0940",
          output: "1",
        },
        {
          input:
            "6 0900 0940 0950 1100 1500 1800 0910 1200 1120 1130 1900 2000",
          output: "3",
        },
        {
          input: "4 0900 0900 0900 0900 1000 1000 1000 1000",
          output: "4",
        },
        {
          input: "4 0800 0900 1000 1100 1200 1200 1200 1200",
          output: "4",
        },
        {
          input:
            "6 0900 1100 1300 1500 1700 1900 1000 1200 1400 1600 1800 2000",
          output: "1",
        },
        {
          input:
            "10 0900 1000 1100 1200 1300 1400 1500 1600 1700 1800 1000 1100 1200 1300 1400 1500 1600 1700 1800 1900",
          output: "2",
        },
        {
          input:
            "7 0900 0930 1000 1030 1100 1130 1200 0915 1000 1015 1045 1115 1145 1215",
          output: "2",
        },
      ],
      constraints: ["<p>1 ≤ n ≤ 50000</p>", "<p>0000 ≤ A[i] ≤ D[i] ≤ 2359</p>"],
    },
    {
      questionId: "infosys-4",
      question:
        "Find the longest chain of pairs possible from a given set, where each pair's second number is smaller than the next pair's first number.",
      inputFormat:
        "An integer N denoting the number of pairs, followed by 2N integers representing pairs of numbers (a total of 2N integers).",
      outputFormat:
        "Print an integer representing the length of the longest chain of pairs that can be formed.",
      sampleInput: "5\n5 24 39 60 15 28 27 40 50 90",
      sampleOutput: "3",
      testCases: [
        {
          input: "5 5 24 39 60 15 28 27 40 50 90",
          output: "3",
        },
        {
          input: "2 5 10 1 11",
          output: "1",
        },
        {
          input: "4 1 2 3 4 5 6 7 8",
          output: "4",
        },
        {
          input: "1 5 10",
          output: "1",
        },
        {
          input: "2 5 10 15 20",
          output: "2",
        },
        {
          input: "2 5 10 8 12",
          output: "1",
        },
        {
          input: "4 1 2 3 4 3 5 6 7",
          output: "3",
        },
        {
          input: "5 1 2 3 4 5 6 7 8 9 10",
          output: "5",
        },
        {
          input: "6 1 2 2 3 3 4 4 5 5 6 6 7",
          output: "3",
        },
        {
          input: "4 1 2 10000 10001 20000 20001 30000 30001",
          output: "4",
        },
      ],
      constraints: ["<p>1 ≤ N ≤ 10<sup>5</sup></p>"],
    },
    {
      questionId: "infosys-5",
      question:
        "Given an array Arr of N elements, find the majority element in the array. A majority element in an array Arr of size N is an element that appears strictly more than N/2 times in the array.",
      inputFormat:
        "An integer N representing the size of the array followed by N space-separated integers denoting the elements of the array A.",
      outputFormat: "Print the majority element if present, else print -1.",
      sampleInput: "3\n1 2 3",
      sampleOutput: "-1",
      testCases: [
        {
          input: "3 1 2 3",
          output: "-1",
        },
        {
          input: "5 3 1 3 3 2",
          output: "3",
        },
        {
          input: "7 1 1 2 2 2 1 1",
          output: "1",
        },
        {
          input: "5 1 1 1 2 2",
          output: "1",
        },
        {
          input: "6 2 2 2 2 1 1",
          output: "2",
        },
        {
          input: "7 3 3 3 3 2 2 2",
          output: "3",
        },
        {
          input: "8 4 4 4 4 3 3 3 3",
          output: "-1",
        },
        {
          input: "9 5 5 5 5 5 4 4 4 4",
          output: "5",
        },
        {
          input: "10 6 6 6 6 6 5 5 5 5 5",
          output: "-1",
        },
        {
          input: "5 2 3 4 0 1",
          output: "-1",
        },
      ],
      constraints: [
        "<p>1 ≤ N ≤ 10<sup>7</sup></p>",
        "<p>0 ≤ A<sub>i</sub> ≤ 10<sup>6</sup></p>",
      ],
    },
    {
      questionId: "infosys-6",
      question:
        "Given an array arr[] of size N, check if it can be partitioned into two parts such that the sum of elements in both parts is the same.",
      inputFormat:
        "The first number N denotes the size of the array, followed by N integers representing the elements of the array.",
      outputFormat:
        "Print a string 'YES' or 'NO' indicating whether the array can be partitioned or not.",
      sampleInput: "4\n1 5 5 11",
      sampleOutput: "YES",
      testCases: [
        {
          input: "3 2 3 5",
          output: "YES",
        },
        {
          input: "5 3 1 5 2 2",
          output: "NO",
        },
        {
          input: "6 1 2 3 4 5 15",
          output: "YES",
        },
        {
          input: "4 1 2 3 6",
          output: "YES",
        },
        {
          input: "5 3 0 0 3 0 3",
          output: "YES",
        },
        {
          input: "8 2 3 1 0 2 0 0 3 3",
          output: "NO",
        },
        {
          input: "9 1 2 3 4 5 6 7 89 9",
          output: "NO",
        },
        {
          input: "4 34 54 67 233",
          output: "NO",
        },
        {
          input: "6 1 2 3 4 5 20",
          output: "NO",
        },
        {
          input: "5 678 6565 345 3455 435",
          output: "NO",
        },
      ],
      constraints: [
        "<p>1 ≤ N ≤ 100</p>",
        "<p>1 ≤ arr[i] ≤ 1000</p>",
        "<p>N * sum of elements ≤ 5 * 10<sup>6</sup></p>",
      ],
    },
    {
      questionId: "infosys-7",
      question:
        "Given a square grid of size N, each cell containing the cost to traverse through that cell, find the minimum cost path from the top-left cell to the bottom-right cell.",
      inputFormat:
        "The first number denotes N, followed by the N*N space-separated integers representing the grid.",
      outputFormat: "Print an integer representing the minimum cost.",
      sampleInput: "4\n9 4 9 9 6 7 6 4 8 3 3 7 7 4 9 10",
      sampleOutput: "43",
      testCases: [
        {
          input: "4 9 4 9 9 6 7 6 4 8 3 3 7 7 4 9 10",
          output: "43",
        },
        {
          input: "2 4 4 3 7",
          output: "14",
        },
        {
          input: "3 1 2 3 4 5 6 7 8 9",
          output: "21",
        },
        {
          input: "3 1 2 3 4 8 2 1 5 3",
          output: "11",
        },
        {
          input: "3 1 3 1 1 5 1 4 2 1",
          output: "7",
        },
        {
          input: "2 1 2 3 4",
          output: "7",
        },
        {
          input: "2 1 100 100 1",
          output: "102",
        },
        {
          input: "3 23 34 54 67 54 56 67 12 34",
          output: "157",
        },
        {
          input: "4 0 9 6 7 2 7 2 4 0 1 6 7 3 8 5 6",
          output: "20",
        },
        {
          input:
            "6 6 7 8 9 0 1 5 4 3 6 7 2 0 1 5 23 5 7 8 9 3 6 0 2 5 8 6 2 8 0 4 7 6",
          output: "28",
        },
      ],
      constraints: ["<p>1 ≤ n ≤ 500</p>", "<p>1 ≤ cost of cells ≤ 1000</p>"],
    },
    {
      questionId: "infosys-8",
      question:
        "Check if string 'b' can be obtained by rotating string 'a' by exactly 2 places in any direction.",
      inputFormat: "Two separated strings a and b.",
      outputFormat:
        "Print 1 if string 'b' can be obtained by rotating string 'a' by exactly 2 places, otherwise Print 0.",
      sampleInput: "amazon azonam",
      sampleOutput: "1",
      testCases: [
        {
          input: "amazon azonam",
          output: "1",
        },
        {
          input: "geeksforgeeks geeksgeeksfor",
          output: "0",
        },
        {
          input: "stackoverflow erflowstackov",
          output: "0",
        },
        {
          input: "abcde edcba",
          output: "0",
        },
        {
          input: "abcdefghij ijabcdefgh",
          output: "1",
        },
        {
          input: "abcde cdeab",
          output: "1",
        },
        {
          input: "abcde fghij",
          output: "0",
        },
        {
          input: "12345 45123",
          output: "1",
        },
        {
          input: "xyz zxy",
          output: "1",
        },
        {
          input: "abcdefghijklmnopqrstuvwxyz yzabcdefghijklmnopqrstuvwx",
          output: "1",
        },
      ],
      constraints: ["<p>1 ≤ length of a, b ≤ 10<sup>5</sup></p>"],
    },
  ],
  tcs: [
    {
      questionId: "tcs-1",
      question:
        "Find the first non-repeating element in a given array 'Arr[]'  of integers of size 'N' ",
      inputFormat:
        "The first number denotes 'N', followed by 'N' elements representing the array 'Arr'.",
      outputFormat:
        "Print a single integer which is the first non-repeating element of the given Array",
      sampleInput: "5\n-1 2 -1 3 2",
      sampleOutput: "3",
      testCases: [
        {
          input: "5 -1 2 -1 3 2",
          output: "3",
        },
        {
          input: "3 1 2 1",
          output: "2",
        },
        {
          input: "6  4 -3 5 -2 4 -3",
          output: "5",
        },
        {
          input: "6 1 1 2 2 3 3",
          output: "None",
        },
        {
          input: "1 5",
          output: "5",
        },
        {
          input: "7 1 2 3 4 1 2 3",
          output: "4",
        },
        {
          input: "9 1 2 3 4 5 6 7 8 9",
          output: "1",
        },
        {
          input: "10 1 2 3 4 5 6 7 8 9 10",
          output: "1",
        },
        {
          input: "16 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 1",
          output: "2",
        },
      ],
      constraints: [
        "<p>1 <= N <= 10<sup>7</p>",
        "<p>-10<sup>16</sup> <= A<sup>i</sup> <= 10<sup>16<sup></p>",
        "<p>{Ai !=0 }</p>",
      ],
    },
    {
      questionId: "tcs-2",
      question:
        "Given an array 'Arr' of length 'N', determine if there exists an element in the array such that the sum of the elements on its left is equal to the sum of the elements on its right.",
      inputFormat:
        "The first number denotes 'N' is the size of array, followed by 'N' elements representing the array 'Arr'.",
      outputFormat:
        "Print a string 'YES' if there exists such an element, otherwise 'NO'.",
      sampleInput: "4\n1 2 3 3",
      sampleOutput: "YES",
      testCases: [
        {
          input: "4 1 2 3 3",
          output: "YES",
        },
        {
          input: "2 1 5",
          output: "NO",
        },
        {
          input: "5 2 2 2 2 2",
          output: "YES",
        },
        {
          input: "5 1 2 4 7 5",
          output: "NO",
        },
        {
          input: "1 5",
          output: "YES",
        },
        {
          input: "6 0 0 0 0 0 0",
          output: "YES",
        },
        {
          input: "5 3 3 3 3 3",
          output: "YES",
        },
        {
          input: "11 1 2 3 4 5 10 5 4 3 2 1",
          output: "YES",
        },
        {
          input: "10 1 2 3 4 5 6 7 8 9 10",
          output: "NO",
        },
        {
          input: "5 1 2 3 4 5",
          output: "NO",
        },
      ],
      constraints: [
        "<p>1 ≤ N ≤ 10<sup>5</sup></p>",
        "<p>1 ≤ Arr[i] ≤ 10<sup>6</sup></p>",
      ],
    },
    {
      questionId: "tcs-3",
      question: "Given a random set of numbers, print them in sorted order.",
      inputFormat:
        "The first number denotes 'N' is the size of array, followed by 'N' elements representing the array 'arr[]'.",
      outputFormat: "Print an array in sorted order.",
      sampleInput: "4\n1 5 3 2",
      sampleOutput: "1 2 3 5",
      testCases: [
        {
          input: "4 1 5 3 2",
          output: "1 2 3 5",
        },
        {
          input: "2 3 1",
          output: "1 3",
        },
        {
          input: "6 8 5 3 9 1 2",
          output: "1 2 3 5 8 9",
        },
        {
          input: "5 3 3 3 3 3",
          output: "3 3 3 3 3",
        },
        {
          input: "6 6 5 4 3 2 1",
          output: "1 2 3 4 5 6",
        },
        {
          input: "8 -2 5 -7 10 -3 8 -1 0",
          output: "-7 -3 -2 -1 0 5 8 10",
        },
        {
          input: "10 3 7 1 5 3 1 7 2 6 2",
          output: "1 1 2 2 3 3 5 6 7 7",
        },
        {
          input: "15 9 2 5 8 4 6 3 7 1 10 12 11 14 13 15",
          output: "1 2 3 4 5 6 7 8 9 10 11 12 13 14 15",
        },
        {
          input: "7 0 0 0 0 0 1 0",
          output: "0 0 0 0 0 0 1",
        },
        {
          input: "20 20 18 16 14 12 10 8 6 4 2 19 17 15 13 11 9 7 5 3 1",
          output: "1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20",
        },
      ],
      constraints: ["<p>1 ≤ N, A[i] ≤ 10<sup>5</sup></p>"],
    },
    {
      questionId: "tcs-4",
      question: "Reverse a given string.",
      inputFormat: "A single string denoting the input string 's'.",
      outputFormat: "A single string representing the reversed input string.",
      sampleInput: "Geeks",
      sampleOutput: "skeeG",
      testCases: [
        {
          input: "Geeks",
          output: "skeeG",
        },
        {
          input: "for",
          output: "rof",
        },
        {
          input: "12345",
          output: "54321",
        },
        {
          input:
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
          output:
            "0987654321ZYXWVUTSRQPONMLKJIHGFEDCBAzyxwvutsrqponmlkjihgfedcba",
        },
        {
          input: "!@#$%^&*()_+",
          output: "+_)(*&^%$#@!",
        },
        {
          input: "😀🌟🎉🍎🍕",
          output: "🍕🍎🎉🌟😀",
        },
        {
          input: "Hello123World",
          output: "dlroW321olleH",
        },
        {
          input: "This is a test!",
          output: "!tset a si sihT",
        },
        {
          input: "äöüß12345",
          output: "54321ßüöä",
        },
        {
          input: "Hello World",
          output: "dlroW olleH",
        },
      ],
      constraints: ["<p>1 ≤ |s| ≤ 10000</p>"],
    },
    {
      questionId: "tcs-5",
      question:
        "Given a sentence containing several words and numbers, find the largest number among them that does not contain the digit 9.",
      inputFormat:
        "The input consists of a single string 'Sentence' representing the sentence containing words and numbers.",
      outputFormat:
        "Print an integer representing the largest number that does not contain the digit 9. If no such number exists, output -1.",
      sampleInput: "This is alpha 5057 and 97",
      sampleOutput: "5057",
      testCases: [
        {
          input: "This is alpha 5057 and 97",
          output: "5057",
        },
        {
          input: "Another input 9007",
          output: "-1",
        },
        {
          input: "Some 123 numbers 456 without 789 9",
          output: "456",
        },
        {
          input: "There are 9 apples and 5 oranges",
          output: "5",
        },
        {
          input: "No numbers here",
          output: "-1",
        },
        {
          input: "The price is $99.99",
          output: "-1",
        },
        {
          input: "The temperature is 98.9°F",
          output: "-1",
        },
        {
          input: "Find the sum of 1, 2, and 3",
          output: "3",
        },
        {
          input: "The circumference of the Earth is about 40075 kilometers",
          output: "40075",
        },
        {
          input:
            "The Fibonacci sequence starts with 0, 1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233",
          output: "233",
        },
      ],
      constraints: [
        "<p>1 ≤ Length of Sentence ≤ 10<sup>6</sup></p>",
        "<p>1 ≤ answer ≤ 10<sup>18</sup></p>",
      ],
    },
    {
      questionId: "tcs-6",
      question:
        "Given a number N, replace all zeros in the number with 5 and return the modified number.",
      inputFormat:
        "The first line contains an integer T, the number of test cases. Each test case consists of an integer N.",
      outputFormat:
        "For each test case, output the modified number after replacing zeros with 5.",
      sampleInput: "2\n1004 121",
      sampleOutput: "1554 121",
      testCases: [
        {
          input: "2 1004 121",
          output: "1554 121",
        },
        {
          input: "3 105000 700 0",
          output: "155555 755 5",
        },
        {
          input: "1 0",
          output: "5",
        },
        {
          input: "1 100",
          output: "155",
        },
        {
          input: "2 0 10",
          output: "5 15",
        },
        {
          input: "3 505 5000500 5005",
          output: "555 5555555 5555",
        },
        {
          input: "1 123456789",
          output: "123456789",
        },
        {
          input: "2 101010 5050",
          output: "151515 5555",
        },
        {
          input: "2 1004 121",
          output: "1554 121",
        },
        {
          input: "3 0 0 0",
          output: "5 5 5",
        },
      ],
      constraints: [
        "<p>1 ≤ T ≤ 10<sup>3</sup></p>",
        "<p>1 ≤ N ≤ 10<sup>4</sup></p>",
      ],
    },
    {
      questionId: "tcs-7",
      question:
        "Given a string S consisting of lowercase English letters, arrange all its letters in lexicographical order using Counting Sort.",
      inputFormat:
        "The first line contains an integer N, the size of the string and followed by string S.",
      outputFormat: "Print the lexicographically sorted string.",
      sampleInput: "5\nedsab",
      sampleOutput: "abdes",
      testCases: [
        {
          input: "5 edsab",
          output: "abdes",
        },
        {
          input: "13 geeksforgeeks",
          output: "eeeefggkkorss",
        },
        {
          input: "10 zyxwvutsrq",
          output: "qrstuvwxyz",
        },
        {
          input: "10 zxvutpmnol",
          output: "lmnoptuvxz",
        },
        {
          input: "5 edsab",
          output: "abdes",
        },
        {
          input: "8 hgfedcba",
          output: "abcdefgh",
        },
        {
          input: "5 abcde",
          output: "abcde",
        },
        {
          input: "3 cba",
          output: "abc",
        },
        {
          input: "6 abcdab",
          output: "aabbcd",
        },
        {
          input: "4 dcba",
          output: "abcds",
        },
      ],
      constraints: ["<p>1 ≤ N ≤ 10<sup>5</sup></p>"],
    },
    {
      questionId: "tcs-8",
      question:
        "Help the thief steal gold coins from gold boxes based on plates and coins in each box.",
      inputFormat:
        "The first two space-separated integers represent T (maximum plates the thief can take) and N (number of gold boxes). Followed by N space-separated integers denoting the Ai plates in each gold box followed by N space-separated integers denoting the Bi gold coins in each plate.",
      outputFormat:
        "A single integer representing the maximum gold coins the thief can steal.",
      sampleInput: "3 3\n1 2 3\n3 2 1",
      sampleOutput: "7",
      testCases: [
        {
          input: "3 3 1 2 3 3 2 1",
          output: "7",
        },
        {
          input: "0 3 1 3 2 2 3 1",
          output: "0",
        },
        {
          input: "0 3 1 2 3 3 2 1",
          output: "0",
        },
        {
          input: "0 0",
          output: "0",
        },
        {
          input: "0 1 5 10",
          output: "0",
        },
        {
          input: "10000 1 5000 10000",
          output: "50000000",
        },
        {
          input: "3 3 1 1 1 2 2 2",
          output: "6",
        },
        {
          input: "10 3 2 3 4 5 4 3",
          output: "34",
        },
        {
          input: "10 3 2 2 2 5 5 5",
          output: "30",
        },
        {
          input: "3 3 2 2 2 3 3 3",
          output: "9",
        },
      ],
      constraints: [
        "<p>0 ≤ T, N ≤ 10<sup>4</sup></p>",
        "<p>1 ≤ A[i], B[i] ≤ 10<sup>4</sup></p>",
      ],
    },
    {
      questionId: "tcs-9",
      question:
        "You have N books, each with A[i] number of pages. M students need to be allocated contiguous books, with each student getting at least one book. Out of all the permutations, the goal is to find the permutation where the sum of the maximum number of pages in a book allotted to a student should be minimum, out of all possible permutations. Return -1 if a valid assignment is not possible, and allotment should be in contiguous order.",
      inputFormat:
        "The first line contains an integer N, the number of books a followed by N space-separated integers representing the number of pages in each book (A[i]) and followed by integer M, the number of students.",
      outputFormat:
        "Print the minimum sum of the maximum number of pages in a book allotted to a student. If a valid assignment is not possible, print -1.",
      sampleInput: "4\n12 34 67 90\n2",
      sampleOutput: "113",
      testCases: [
        {
          input: "4 12 34 67 90 2",
          output: "113",
        },
        {
          input: "3 15 17 20 2",
          output: "32",
        },
        {
          input: "5 5 10 15 20 25 3",
          output: "30",
        },
        {
          input: "3 1 1 1 1",
          output: "3",
        },
        {
          input: "4 2 2 2 2 4",
          output: "2",
        },
        {
          input: "5 1 1 1 1 1 5",
          output: "1",
        },
        {
          input: "6 10 20 30 40 50 60 1",
          output: "210",
        },
        {
          input: "4 50 60 70 80 4",
          output: "80",
        },
        {
          input: "8 1 2 3 4 5 6 7 8 4",
          output: "11",
        },
        {
          input: "4 12 34 67 90 2",
          output: "113",
        },
      ],
      constraints: [
        "<p>1 ≤ N ≤ 10<sup>5</sup></p>",
        "<p>1 ≤ A[i] ≤ 10<sup>6</sup></p>",
        "<p>1 ≤ M ≤ 10<sup>5</sup></p>",
      ],
    },
  ],
};

export default codingQuestionsList;
