import {
  faArrowRightFromBracket,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import useIsTabletSize from "../../customHooks/isTabletSizeHook";
import useNetworkHook from "../../customHooks/useNetworkHook";
import ChatBubble from "../ChatBubble/ChatBubble";
import FullscreenExitModal from "../Modals/ExitFullscreenModal/ExitFullscreenModal";
import SpeechToTextConverterInput from "../SpeechToTextConverterInput/SpeechToTextConverterInput";
import "./MainChatSection.css";

const MainChatSection = forwardRef(
  (
    {
      scrollToBottomMessage,
      chatSectionRef,
      conversation,
      timeStamps,
      user,
      handleInputSubmit,
      loading,
      timerTime,
      isTimerVisible,
      setTimerTime,
      skipToNextQuestion,
      showSkipBtn,
    },
    ref
  ) => {
    MainChatSection.displayName = "MainChatSection";
    const speechToTextConverterInputRef = useRef(null);
    const { makeNetworkCall } = useNetworkHook();
    const isTabletSize = useIsTabletSize();
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secondsLeft = seconds % 60;
      return `${minutes}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`;
    };
    useImperativeHandle(ref, () => ({
      muteMicAndResetStates,
      startMicAndListen,
    }));
    const muteMicAndResetStates = async () => {
      await speechToTextConverterInputRef.current.muteAndReset();
      setDisableSkipBtn(true);
    };
    const startMicAndListen = () => {
      speechToTextConverterInputRef.current.startTheListening();
      setDisableSkipBtn(false);
    };
    const [disableSkipBtn, setDisableSkipBtn] = useState(true);
    const [disableAllExceptMic, setDisableAllExceptMic] = useState(false);
    const [showEndInterviewAlertModal, setShowEndInterviewAlertModal] =
      useState(false);
    const timeArray = formatTime(timerTime).split("");
    const confirmAlertModal = () => {
      setTimerTime(0);
      setShowEndInterviewAlertModal(false);
    };
    return (
      <div className="tyn-chat-body w-100 mont-500 bg-white">
        <div className="subheadings w-100">
          <p
            className={`flex-fill ${
              isTabletSize ? "chat-heading my-2 " : "chat-heading-mob my-1"
            } ${isTimerVisible ? "" : "text-center"}`}
            onClick={scrollToBottomMessage}
          >
            Real-Time interview conversation
          </p>

          {isTimerVisible && (
            <>
              <div className="timer-container">
                <span className="timer-text">Time left: </span>
                <div className="fixed-digits">
                  {timeArray.slice(0, -1).map((char, index) => (
                    <div key={index} className="digit">
                      {char}
                    </div>
                  ))}
                </div>
                <div className="scrolling-digit">
                  {timeArray[timeArray.length - 1]}
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={`chat-bubbles-section ${
            isTabletSize ? "ct-bub-sec" : "ct-bub-sec-mob"
          }`}
          ref={chatSectionRef}
        >
          {conversation
            .filter((item) => item.role != "system")
            .map((message, index) => (
              <ChatBubble
                key={index}
                message={message.content}
                sender={message.role}
                timeStamp={timeStamps[index + 1]}
                scrollToBottomMessage={scrollToBottomMessage}
                user={user}
              />
            ))}
        </div>
        <div
          className={`tyn-chat-form ${
            isTabletSize ? "tn-ct-frm" : "tn-ct-frm-mob"
          } bg-light`}
        >
          <form style={{ width: "100%" }}>
            <div className="tyn-chat-form-enter">
              <SpeechToTextConverterInput
                ref={speechToTextConverterInputRef}
                handleInputSubmit={handleInputSubmit}
                loading={loading}
                disableSkipBtn={disableSkipBtn}
                setDisableSkipBtn={setDisableSkipBtn}
                disableAllExceptMic={disableAllExceptMic}
                setDisableAllExceptMic={setDisableAllExceptMic}
              />
              <div
                className={`skip_exit_btns ${isTabletSize ? "me-2" : "me-1"}`}
              >
                <button
                  disabled={
                    disableSkipBtn || !showSkipBtn || disableAllExceptMic
                  }
                  className={`bg-[#e6f9ff] btn btn-outline-primary btn-sm my-0 mb-1 d-flex justify-content-between align-items-center ${
                    isTabletSize ? "cht-sec-btn" : "cht-sec-btn-mob"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    makeNetworkCall({
                      funcWithInternet: async () => {
                        await muteMicAndResetStates();
                        skipToNextQuestion();
                      },
                    });
                  }}
                >
                  Skip Question
                  <FontAwesomeIcon icon={faForward} className="ps-2" />
                </button>
                <button
                  disabled={disableSkipBtn || disableAllExceptMic}
                  className={`bg-[#ffe4e1] btn btn-outline-danger btn-sm my-0 d-flex justify-content-between align-items-center ${
                    isTabletSize ? "cht-sec-btn" : "cht-sec-btn-mob"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    makeNetworkCall({
                      funcWithInternet: () => {
                        setShowEndInterviewAlertModal(true);
                      },
                    });
                  }}
                >
                  End Round
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                </button>
              </div>
            </div>
          </form>
          {showEndInterviewAlertModal && (
            <FullscreenExitModal
              show={showEndInterviewAlertModal}
              onReject={() => {
                setShowEndInterviewAlertModal(false);
              }}
              onConfirm={confirmAlertModal}
              text="Are you sure you want to end this round? If you wish to continue later, let the page reload."
              heading={"Are you sure?"}
              confirmBtnText={"End"}
              rejectBtntext={"Continue Round"}
              thirdBtnText={"Continue Later"}
              thirdBtnAction={() => window.location.reload()}
            />
          )}
        </div>
      </div>
    );
  }
);
export default MainChatSection;
MainChatSection.propTypes = {
  scrollToBottomMessage: PropTypes.func,
  chatSectionRef: PropTypes.object,
  conversation: PropTypes.array,
  timeStamps: PropTypes.array,
  user: PropTypes.object,
  handleInputSubmit: PropTypes.func,
  loading: PropTypes.bool,
  handleUserInputChange: PropTypes.func,
  timerTime: PropTypes.number,
  isTimerVisible: PropTypes.bool,
  setTimerTime: PropTypes.func,
  skipToNextQuestion: PropTypes.func,
  turnOffMicrophone: PropTypes.func,
  showSkipBtn: PropTypes.bool,
};
