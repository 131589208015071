import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
function getNetworkConnection() {
  return (
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection ||
    null
  );
}
function getNetworkConnectionInfo() {
  const connection = getNetworkConnection();
  if (!connection) {
    return {};
  }
  return {
    rtt: connection.rtt,
    type: connection.type,
    saveData: connection.saveData,
    downLink: connection.downLink,
    downLinkMax: connection.downLinkMax,
    effectiveType: connection.effectiveType,
  };
}
function useNetworkHook() {
  const [networkState, setNetworkState] = useState(() => {
    return {
      since: undefined,
      online: navigator.onLine,
      ...getNetworkConnectionInfo(),
    };
  });

  const networkStateRef = useRef(networkState);
  useEffect(() => {
    networkStateRef.current = networkState;
  }, [networkState]);

  useEffect(() => {
    const handleOnline = () => {
      setNetworkState((prevState) => ({
        ...prevState,
        online: true,
        since: new Date().toString(),
      }));
    };
    const handleOffline = () => {
      setNetworkState((prevState) => ({
        ...prevState,
        online: false,
        since: new Date().toString(),
      }));
    };
    const handleConnectionChange = () => {
      setNetworkState((prevState) => ({
        ...prevState,
        ...getNetworkConnectionInfo(),
      }));
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    const connection = getNetworkConnection();
    connection?.addEventListener("change", handleConnectionChange);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      connection?.removeEventListener("change", handleConnectionChange);
    };
  }, []);

  const makeNetworkCall = async ({
    funcWithInternet,
    funcWithoutInternet,
    shouldRetry = false,
    retryTimeout = 30000, // Default timeout set to 30 seconds (in milliseconds)
  }) => {
    try {
      if (networkState.online && funcWithInternet) {
        await funcWithInternet(...arguments);
      } else {
        if (shouldRetry) {
          let elapsedTime = 0;
          toast.error(
            `No Internet Connection. Please re-connect with in ${
              (retryTimeout - elapsedTime) / 1000
            } seconds`,
            {
              toastId: "no_internet_re-connect",
            }
          );
          const retryInterval = setInterval(async () => {
            elapsedTime += 1000; // Increment elapsed time by 1 second
            if (networkStateRef.current.online) {
              clearInterval(retryInterval);
              await funcWithInternet(...arguments);
            }
            if (
              !networkStateRef.current.online &&
              elapsedTime >= retryTimeout
            ) {
              clearInterval(retryInterval);
              if (funcWithoutInternet) {
                await funcWithoutInternet(...arguments);
              }
              console.log("Retry timeout reached.");
            }
          }, 1000);
        } else {
          toast.error("No Internet Connection", {
            toastId: "no_internet_connection",
          });
          if (funcWithoutInternet) {
            await funcWithoutInternet(...arguments);
          }
        }
      }
    } catch (error) {
      // Handle any errors that might occur during function execution
      console.error("Error occurred:", error);
      // Optionally, you can rethrow the error to propagate it further
      throw error;
    }
  };

  return { networkState, makeNetworkCall };
}

export default useNetworkHook;
