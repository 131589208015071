import {
  faMicrophoneLines,
  faMicrophoneSlash,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "@mui/material";
import PropTypes from "prop-types";
import {
  React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ThreeDots } from "react-loader-spinner";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";
import useIsTabletSize from "../../customHooks/isTabletSizeHook";
import useNetworkHook from "../../customHooks/useNetworkHook";
import MicCamPermissionModal from "../Modals/MicCamPermissionModal/MicCamPermissionModal";
import "./SpeechToTextConverterInput.css";

const SpeechToTextConverterInput = forwardRef(
  (
    {
      handleInputSubmit,
      loading,
      disableSkipBtn,
      setDisableSkipBtn,
      disableAllExceptMic,
      setDisableAllExceptMic,
    },
    ref
  ) => {
    SpeechToTextConverterInput.displayName = "SpeechToTextConverterInput";
    const [textToCopy, setTextToCopy] = useState("");
    const {
      transcript,
      interimTranscript,
      finalTranscript,
      listening,
      resetTranscript,
      browserSupportsSpeechRecognition,
    } = useSpeechRecognition();
    const { networkState, makeNetworkCall } = useNetworkHook();
    const [microphonePermissionGranted, setMicrophonePermissionGranted] =
      useState(true);
    const [shouldShowNoMicModal, setShouldShowNoMicModal] = useState(false);
    const [disableMic, setDisableMic] = useState(true);
    const textareaRef = useRef(null);
    const isTabletSize = useIsTabletSize();
    useEffect(() => {
      if (!browserSupportsSpeechRecognition) {
        toast.error("Microphone not found.");
      }
    }, []);

    useEffect(() => {
      //Extra useEffect to continously disable textArea and other buttons while the text is being processed
      if (!interimTranscript) {
        setDisableAllExceptMic(false);
      } else {
        setDisableAllExceptMic(true);
        var currentRows = calculateRows(textareaRef.current.scrollHeight);
        textareaRef.current.rows = currentRows;
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
      }
      if (finalTranscript !== "") {
        setDisableAllExceptMic(false);
        console.log("Got final result:", finalTranscript);
      }
    }, [interimTranscript, finalTranscript]);
    useEffect(() => {
      //Extra useEffect to continously reset textarea value while the bot is speaking
      if (disableSkipBtn) {
        resetTranscript();
        textareaRef.current.value = "";
      }
    }, [textareaRef?.current?.value]);

    useEffect(() => {
      if (listening) {
        makeNetworkCall({
          funcWithInternet: () => {
            //
          },
          funcWithoutInternet: () => {
            SpeechRecognition.stopListening();
          },
        });
      }
    }, [listening, networkState.online]);

    useEffect(() => {
      checkMicrophoneAndCameraPermission();
    }, [microphonePermissionGranted]);

    const checkMicrophoneAndCameraPermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        setMicrophonePermissionGranted(true);
      } catch (error) {
        setShouldShowNoMicModal(true);
        setMicrophonePermissionGranted(false);
      }
    };

    const muteAndReset = () => {
      resetTranscript();
      SpeechRecognition.stopListening();
      setTextToCopy("");
      setDisableMic(true);
      setDisableSkipBtn(true);
    };

    useImperativeHandle(ref, () => ({
      muteAndReset,
      startTheListening,
    }));
    const startTheListening = async () => {
      setDisableMic(false);
      await SpeechRecognition.startListening({
        continuous: true,
        language: "en-US",
      });
    };

    const handleTextAreaChange = async (event) => {
      const newText = event.target.value;
      SpeechRecognition.stopListening();
      setTextToCopy(newText);
      resetTranscript();
    };

    const handleKeyDown = (e) => {
      if (e && e.key === "Enter" && e && !e.shiftKey) {
        e.preventDefault(); // Prevent new line
        const newText = e.target.value;
        if (newText.trim() === "") {
          return;
        }
        SpeechRecognition.stopListening();
        makeNetworkCall({
          funcWithInternet: () => {
            textareaRef.current.rows = 2;
            muteAndReset();
            handleInputSubmit({
              e: e,
              isCode: false,
              userMsg: newText,
            }); // Call the form submission function
          },
        });
      }
    };

    const handleTextareaResize = ({ event, rows }) => {
      const textarea = event.target;
      var currentRows = calculateRows(textarea.scrollHeight);
      if (rows) {
        currentRows = 2;
      }
      textarea.rows = currentRows;
    };

    const calculateRows = (content) => {
      // Calculate the number of rows based on the content's height
      const lineHeight = 24; // Adjust this value based on your font and styling
      const minRows = 2;
      const maxRows = 5;

      const contentRows = Math.floor(content / lineHeight);

      return Math.min(maxRows, Math.max(minRows, contentRows));
    };

    return (
      <div className="mic-and-input-container" style={{ flexGrow: "1" }}>
        <button
          disabled={disableMic}
          className={`microphone-icon ${
            isTabletSize ? "chat-sec-icn" : "chat-sec-icn-mob"
          } ${disableMic ? "input-bar-icon-disabled" : "input-bar-icon"} me-2 ${
            loading && "disabledIcon"
          } ${
            !browserSupportsSpeechRecognition || !microphonePermissionGranted
              ? "no-mic"
              : ""
          }`}
          style={{
            backgroundColor: `${listening ? "rgb(191, 219, 254)" : "#fff"}`,
          }}
          onClick={async (e) => {
            e.preventDefault();
            if (
              browserSupportsSpeechRecognition &&
              microphonePermissionGranted &&
              !loading
            ) {
              if (!listening) {
                startTheListening();
              } else {
                SpeechRecognition.stopListening();
              }
            }
          }}
        >
          {!browserSupportsSpeechRecognition || !microphonePermissionGranted ? (
            <Badge
              badgeContent="!"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "red",
                },
              }}
              onClick={() => {
                setShouldShowNoMicModal(true);
              }}
            >
              <FontAwesomeIcon
                className={`${loading && "disabledIcon"} ${
                  !microphonePermissionGranted ? "text-danger" : ""
                } no-mic `}
                icon={faMicrophoneSlash}
                onClick={() => {
                  setShouldShowNoMicModal(true);
                }}
              />
            </Badge>
          ) : listening ? (
            <FontAwesomeIcon
              icon={faMicrophoneLines}
              flip
              className={`${loading ? "disabledIcon" : ""} `}
              style={{
                color: "#2563eb",
                transform: "scale(1.5)",
              }}
            />
          ) : (
            <FontAwesomeIcon
              className={`${loading && "disabledIcon"} ${
                !microphonePermissionGranted ? "text-danger" : ""
              }`}
              icon={faMicrophoneSlash}
            />
          )}
        </button>
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          {disableAllExceptMic && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.4)",
                zIndex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ position: "absolute", bottom: "5px", right: "10px" }}
              >
                <ThreeDots
                  visible={true}
                  height="40"
                  width="40"
                  color="#12009d"
                  radius="3"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            </div>
          )}

          <textarea
            style={{ width: "100%", height: "100%", zIndex: 0 }}
            ref={textareaRef}
            onPaste={(e) => e.preventDefault()}
            type="text"
            placeholder="Your response goes here..."
            className={`tyn-chat-form-input ${
              isTabletSize ? "tyn-ct-frm-inp" : "tyn-ct-frm-inp-mob"
            }`}
            autoFocus
            rows={2}
            disabled={loading || disableSkipBtn || disableAllExceptMic}
            value={textToCopy + transcript}
            onChange={handleTextAreaChange}
            onKeyDown={handleKeyDown}
            onInput={(event) => {
              handleTextareaResize({ event: event });
            }}
          />
        </div>
        <button
          className={`${isTabletSize ? "chat-sec-icn" : "chat-sec-icn-mob"} ${
            disableSkipBtn || disableAllExceptMic
              ? "input-bar-icon-disabled"
              : "input-bar-icon"
          } ${loading && "disabledIcon"} mx-1 border-0 ${
            loading && "disabledIcon"
          }`}
          disabled={disableSkipBtn || disableAllExceptMic}
          onClick={(e) => {
            e.preventDefault();
            const newText = textToCopy + transcript;
            if (newText.trim() === "") {
              return;
            }
            SpeechRecognition.stopListening();
            makeNetworkCall({
              funcWithInternet: async () => {
                resetTranscript();
                muteAndReset();
                textareaRef.current.rows = 2;
                handleInputSubmit({
                  e: e,
                  isCode: false,
                  userMsg: newText,
                });
              },
            });
          }}
        >
          <FontAwesomeIcon
            className={`${loading && "disabledIcon"}`}
            icon={faPaperPlane}
          />
        </button>
        <MicCamPermissionModal
          shouldShowNoMicModal={shouldShowNoMicModal}
          source="microphone"
        />
      </div>
    );
  }
);
export default SpeechToTextConverterInput;
SpeechToTextConverterInput.propTypes = {
  handleInputSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disableSkipBtn: PropTypes.bool.isRequired,
  setDisableSkipBtn: PropTypes.func.isRequired,
  disableAllExceptMic: PropTypes.bool.isRequired,
  setDisableAllExceptMic: PropTypes.func.isRequired,
};
