import {
  fetchDocumentsDataArrayFromFireStore,
  getNRandomDocumentIdsArray,
} from "../../../Consumer/supportFunctions/GeneralHelperFunctions";

export async function fetchAllDataForAllRounds({
  companyName,
  isASingleTest,
  configArray,
}) {
  const roundsDataArray = await fetchAllRoundsQuestionsData({
    configArray: configArray,
  });
  // console.log("Dwaraka roundsDataArray: ", roundsDataArray);

  const onlineTestStartingData = {
    testForCompany: companyName,
    isASingleTest: isASingleTest,
    roundsDataArray: roundsDataArray,
  };
  // console.log("Dwaraka onlineTestStartingData: ", onlineTestStartingData);
  return onlineTestStartingData;
}

export async function fetchAllRoundsQuestionsData({ configArray }) {
  // console.log("DWaraka configArray: ", configArray);
  try {
    const fetchPromises = configArray.map(async (config) => {
      const { category, numberOfDocuments, timeForThisRound } = config;

      const idsPromise = getNRandomDocumentIdsArray({
        realTimeDBKey: `${category}QuestionsList`,
        numberOfDocuments: numberOfDocuments,
      });

      const datasPromiseArray = await fetchDocumentsDataArrayFromFireStore({
        collectionName: `${category}_question_bank`,
        documentIds: await idsPromise,
      });

      const dataPromiseObject = Object.assign(
        {},
        ...datasPromiseArray.filter((data) => data !== null)
      );

      const [ids, datas] = await Promise.all([idsPromise, dataPromiseObject]);

      console.log(`${category} ids: `, ids);
      // console.log(`${category} datas: `, datas);

      const questionsData = {
        [category]: datas,
      };

      return {
        timeForThisRound: timeForThisRound,
        roundTitle: category,
        questionsData: questionsData[category],
      };
    });

    const allDataArray = await Promise.all(fetchPromises);

    const finalResult = {
      roundsDataArray: allDataArray,
    };

    console.log("Final Result:", finalResult);
    return allDataArray;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
