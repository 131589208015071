import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { extractTextFromPdf } from "../../utils/helperFunctions";
import "./PdfDropZoneComponent.css";
import PdfInputField from "./PdfInputField";

export default function PdfDropZone({
  setIsPdfProcessing,
  setResumeText,
  setResumeFile,
  showLottie = true,
}) {
  PdfDropZone.propTypes = {
    setIsPdfProcessing: PropTypes.func,
    setResumeText: PropTypes.func,
    setResumeFile: PropTypes.func,
    showLottie: PropTypes.bool,
  };
  const { enqueueSnackbar } = useSnackbar();
  const pdfInputFieldRef = useRef(null);
  const handlePdfUpload = async (resumeFile) => {
    if (setResumeFile) {
      setResumeFile(resumeFile);
    }
    setIsPdfProcessing(true);
    const result = await extractTextFromPdf(resumeFile);
    console.log("Uploaded resume: ", result.text);
    if (result.numPages > 2) {
      enqueueSnackbar(
        "Maximum number of pages in resume should be less than 2.",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      setResumeText("");
      setIsPdfProcessing(false);
      pdfInputFieldRef.current.removeResumeFile();
      return;
    } else {
      if (result.text.trim() == "") {
        enqueueSnackbar(
          "Resume is empty or corrupted. Please try with a different one.",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
        setResumeText("");
        setIsPdfProcessing(false);
        pdfInputFieldRef.current.removeResumeFile();
        return;
      }
      if (result.numPages == 2) {
        enqueueSnackbar("Optimal resume should be in a single page.", {
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
      setResumeText(result.text);
    }
    setIsPdfProcessing(false);
  };

  return (
    <div className="resume-uploader">
      <PdfInputField
        ref={pdfInputFieldRef}
        handlePdfUpload={handlePdfUpload}
        showLottie={showLottie}
      />
    </div>
  );
}
