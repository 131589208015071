import { increasePotdSubmissionCount } from "../../../Consumer/pages/ProblemOfTheDayPage/PotdSupportFunctions";
import {
  fetchDocumentFromFireStore,
  getDataFromRealtimeDatabase,
  incrementUserCredits,
  saveThisInterviewScoreToLastThreeInterviewsList,
} from "../../../Consumer/supportFunctions/FirebaseFunctions";
import {
  fetchDocumentsDataArrayFromFireStore,
  getNRandomDocumentIdsArray,
} from "../../../Consumer/supportFunctions/GeneralHelperFunctions";
import { getAlgoRoundEvaluation } from "../../../Consumer/supportFunctions/InterviewBotFunctions";
import { getExtractedResumeFromResume } from "../../../Consumer/supportFunctions/ResumeRoundBotFunctions/ResumeRoundBotFunctions";
import {
  addDataAtL2CollectionFStore,
  overrideVariableATL1orL2CollectionFStore,
  updateVariableATL1CollectionFStore,
} from "../../../Global/utils/firebaseFunctions/firestoreFunctions";
import HRQuestionsData from "../../data/HRQuestions.json";
import theoryQuestionsData from "../../data/TheoryQuestions.json";
import { handleSynthesizeSpeech } from "../../utils/speechSynthesizer";
import { capitalizeFirstLetter } from "../../utils/stringExtensionFunctions";
import {
  interviewStates,
  roundStatus,
} from "./InterviewBotConstants/InterviewBotConstants";
import { increaseSubscriptionInterviews } from "./InterviewBotFirebaseFunctions";

export async function fetchAllDSAQuestions({ totalNumberOfDSAQuestions }) {
  let dsaQuestionsIdArray = [];
  let dsaQuestionsArray = [];
  if (totalNumberOfDSAQuestions) {
    dsaQuestionsIdArray = await getNRandomDocumentIdsArray({
      realTimeDBKey: "/DSAQuestionsList",
      numberOfDocuments: totalNumberOfDSAQuestions,
    });
    const dsaQuestionsDataArray = await fetchDocumentsDataArrayFromFireStore({
      collectionName: "dSA_question_bank",
      documentIds: await dsaQuestionsIdArray,
    });
    dsaQuestionsArray = dsaQuestionsDataArray?.map((item) => ({
      question: item[Object.keys(item)[0]].question,
    }));
  }
  return { dsaQuestionsIdArray, dsaQuestionsArray };
}

export function getRandomHRRoundQuestions({ numberOfQuestions }) {
  const shuffledQuestions = HRQuestionsData.sort(() => 0.5 - Math.random());
  const pickedQuestions = shuffledQuestions.slice(0, numberOfQuestions);

  return pickedQuestions;
}
export function getRandomTheoryRoundQuestions({
  selectedCompanyCard,
  numberOfQuestions,
  difficultyLevel,
}) {
  const company = selectedCompanyCard.split("_")[0];
  const questionsData = theoryQuestionsData[company]?.[difficultyLevel];
  if (!questionsData) {
    console.error(
      `Invalid company card or difficulty level: ${
        selectedCompanyCard.split("_")[0]
      }, ${difficultyLevel}`
    );
    return [];
  }

  // Split questions into easy, medium, and hard difficulty level arrays
  const easyQuestions = questionsData.easy || [];
  const mediumQuestions = questionsData.medium || [];
  const hardQuestions = questionsData.hard || [];

  // Calculate number of questions to pick from each difficulty level
  const easyCount = Math.ceil(numberOfQuestions * 0.5);
  const mediumCount = Math.ceil(numberOfQuestions * 0.25);
  const hardCount = numberOfQuestions - easyCount - mediumCount;

  // Shuffle and pick questions from each difficulty level
  const pickedQuestions = [
    ...getRandomQuestions(easyQuestions, easyCount),
    ...getRandomQuestions(mediumQuestions, mediumCount),
    ...getRandomQuestions(hardQuestions, hardCount),
  ];
  return pickedQuestions;
}

// Helper function to shuffle and pick random questions from an array
function getRandomQuestions(questions, count) {
  const shuffledQuestions = questions.sort(() => 0.5 - Math.random());
  return shuffledQuestions.slice(0, count);
}

export async function handleReferralCreditsEarned(userData) {
  if (userData.totalNumberOfInterviews === 0 && userData.referredById) {
    const referredByUserId = await getDataFromRealtimeDatabase(
      `/AllUserReferralList/${userData.referredById}`
    );
    if (!referredByUserId) {
      return;
    }
    const referredByUserData = await getDataFromRealtimeDatabase(
      `/AllUsersData/${referredByUserId}`
    );

    const earnedThroughReferralNotification = {
      title: "Congratulations - Earned credits",
      description: `You've earned 100 FREE credits. The user ${userData.profileId} referred by you completed their first interview. Keep referring to earn even more!!!`,
      notificationImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/evaluaitor.appspot.com/o/ImagesForWebsite%2Flogo_s_bg_blue.png?alt=media&token=173283c0-1799-468e-8a17-f7981535745b",
      redirectUrl: `/user-dashboard/${referredByUserData.profileId}`,
      timeStamp: new Date(),
    };

    await incrementUserCredits("Interview", "credits", 100, referredByUserId);
    await incrementUserCredits(
      "onboarding",
      "showEarnedCreditsOnboardingModel",
      true,
      referredByUserId
    );
    await incrementUserCredits(
      "notifications",
      "unSeenNotificationsCount",
      1,
      referredByUserId
    );

    await updateVariableATL1CollectionFStore({
      data: earnedThroughReferralNotification,
      collectionName: "all-user-notifications-data",
      documentName: referredByUserId,
      variableName: "Notifications",
    });
  }
}

export async function handleInitialUserTypeCreditsAndInterviewCountUpdation(
  user
) {
  await incrementUserCredits(
    "Interview",
    "totalNumberOfInterviews",
    1,
    user.uid
  );
  if (!user.currentUserType) {
    await incrementUserCredits("Interview", "credits", -100, user.uid);
  } else if (user.currentUserType === "trialSubscription") {
    await increaseSubscriptionInterviews(
      `TrialSubscriptionDomains/${user.subscribedUnderInstitute}`,
      "freeInterviewsLeft",
      -1,
      user.uid
    );
  } else if (user.currentUserType === "unlimitedSubscription") {
    await increaseSubscriptionInterviews(
      `UnlimitedSubscriptionDomains/${user.subscribedUnderInstituteKey}`,
      "totalInterviewsTaken",
      1,
      user.uid
    );
  }
}

export async function updateUserAverageInterviewScore(
  pastOverallInterviewScore,
  latestOverallInterviewScore,
  interviewFirestoreID,
  userData
) {
  var currentAverageInterviewScore = parseFloat(userData.averageInterviewScore);
  var currentTotalNumberOfInterviews = parseFloat(
    userData.totalNumberOfInterviews
  );

  var currentTotalScore =
    currentAverageInterviewScore * currentTotalNumberOfInterviews;

  var accumilatedScore =
    parseFloat(currentTotalScore) -
    parseFloat(pastOverallInterviewScore) +
    parseFloat(latestOverallInterviewScore);

  var newAverageInterviewScore = parseFloat(
    (
      parseFloat(accumilatedScore) /
      parseFloat(
        currentTotalNumberOfInterviews +
          (pastOverallInterviewScore == 0 &&
            latestOverallInterviewScore == 0) || 1
      )
    ).toFixed(2)
  );
  console.log("Dwaraka newAverageInterviewScore: ", newAverageInterviewScore);
  await incrementUserCredits(
    "Interviews",
    "averageInterviewScore",
    parseFloat(
      (
        parseFloat(newAverageInterviewScore) -
        parseFloat(currentAverageInterviewScore)
      ).toFixed(2)
    ),
    userData.uid
  );
  await saveThisInterviewScoreToLastThreeInterviewsList(
    userData.uid,
    interviewFirestoreID,
    latestOverallInterviewScore,
    userData.profileId
  );
  await updateBestInterviewScore(latestOverallInterviewScore, userData);
}

const updateBestInterviewScore = async (
  latestOverallInterviewScore,
  userData
) => {
  const bestInterviewScore = userData.bestInterviewScore;
  const updatedBestInterviewScore = Math.max(
    bestInterviewScore,
    latestOverallInterviewScore
  );
  await incrementUserCredits(
    "Interviews",
    "bestInterviewScore",
    parseFloat(
      (
        parseFloat(updatedBestInterviewScore) - parseFloat(bestInterviewScore)
      ).toFixed(2)
    ),
    userData.uid
  );
};

export const checkIsInterviewSlugValid = async ({
  firestoreCollectionId,
  firestoreChildCollectionId,
  firestoreDocumentId,
  interviewIDSlug,
  retakeInitialSetupDone,
  navigate,
  setInterviewFirestoreID,
  setOverallInterviewScore,
  setInterviewRounds,
  setUserChoicesForInterview,
  setResumeText,
  setCurrentDSAQuestionNumber,
  currentInterviewRoundIndexRef,
  initialCoreSetupForDSARound,
  setConversationAndTimeStamps,
  setDsaQuestionsScores,
  setdSAQuestionsReportDataArray,
  setRetakeInitialSetupDone,
  commonParamsForSetConversationAndTimeStamps,
  setInternalDifficultyLevel,
  setInternalTheoryRoundQuestions,
  setInternalHRRoundQuestions,
  setInternalDSARoundQuestions,
  setInternalDSARoundQuestionIDs,
  setInternalDSARoundQuestionsCount,
}) => {
  var interviewData = await fetchDocumentFromFireStore({
    parent_collection: firestoreCollectionId,
    parent_document: firestoreDocumentId,
    child_collection: firestoreChildCollectionId,
    child_document: interviewIDSlug,
  });

  if (
    !interviewData ||
    retakeInitialSetupDone ||
    interviewData.isThisInterviewRetaken
  ) {
    navigate("/hiring");
    return;
  }

  if (interviewData.OverAllInterviewStatus === roundStatus.Completed) {
    navigate("/hiring");
    return;
  }
  setInterviewRounds(interviewData.interviewRounds);
  setInterviewFirestoreID(interviewIDSlug);
  setDsaQuestionsScores(interviewData.DSAQuestionsScoresArray);
  setdSAQuestionsReportDataArray(interviewData.DSAQuestionsDataArray);
  setOverallInterviewScore(interviewData.overallInterviewScore || 0);
  setInternalDifficultyLevel(interviewData.difficultyLevel);
  setInternalTheoryRoundQuestions(
    getRandomTheoryRoundQuestions({
      selectedCompanyCard: interviewData.selectedCompanyCard,
      numberOfQuestions: interviewData.numberOfTheoryRoundQuestions,
      difficultyLevel: interviewData.difficultyLevel,
    })
  ),
    setInternalHRRoundQuestions(
      getRandomHRRoundQuestions({
        numberOfQuestions: interviewData.numberOfHrRoundQuestions,
      })
    );
  let selectedDSAQuestions = await fetchAllDSAQuestions({
    totalNumberOfDSAQuestions: interviewData.numberOfDSAQuestions,
  });

  setInternalDSARoundQuestions(selectedDSAQuestions.dsaQuestionsArray);
  setInternalDSARoundQuestionIDs(selectedDSAQuestions.dsaQuestionsIdArray);
  setInternalDSARoundQuestionsCount(interviewData.numberOfDSAQuestions);
  var userChoicesForInterview = {
    interviewRounds: interviewData.interviewRounds,
    interviewType: interviewData.interviewType || "MOCK",
    selectedCompanyCard: interviewData.selectedCompanyCard || null,
  };
  setUserChoicesForInterview(userChoicesForInterview);
  setResumeText(interviewData.Resume || "");
  // Find the first element with "roundStatus" not equal to "Completed" and its index
  const { element: firstIncompleteRound, index } =
    interviewData.interviewRounds.reduce(
      (acc, round, currentIndex) => {
        if (round.roundStatus !== roundStatus.Completed && acc.index === -1) {
          acc.element = round;
          acc.index = currentIndex;
        }
        return acc;
      },
      { element: undefined, index: -1 }
    );
  console.log(
    "Dwaraka firstIncompleteRound: ",
    firstIncompleteRound,
    " , index: ",
    index
  );
  if (index === -1) {
    await overrideVariableATL1orL2CollectionFStore({
      parent_collection: firestoreCollectionId,
      parent_document: firestoreDocumentId,
      child_collection: firestoreChildCollectionId,
      child_document: interviewIDSlug,
      variableToUpdate: "OverAllInterviewStatus",
      updatedValue: roundStatus.Completed,
    });
    navigate("/hiring");
    return;
  }
  let minIncompleteQuestion = Infinity;
  let minIncompleteQuestionNumber = -1;
  if (firstIncompleteRound.roundType == "coding") {
    // Iterate through the keys to find the first minimum question with status not equal to "Completed"
    for (const key in interviewData) {
      if (
        key.startsWith("DSAQ") &&
        key.endsWith("Status") &&
        interviewData[key] !== roundStatus.Completed
      ) {
        const questionNumber = parseInt(key.slice(4), 10);
        if (questionNumber < minIncompleteQuestion) {
          minIncompleteQuestion = questionNumber;
          minIncompleteQuestionNumber = questionNumber;
        }
      }
    }
    console.log(
      "Dwaraka DSA minIncompleteQuestionNumber: ",
      minIncompleteQuestionNumber
    );
    if (minIncompleteQuestionNumber === -1) {
      navigate("/hiring");
      return;
    }
    setCurrentDSAQuestionNumber(minIncompleteQuestionNumber);
  }
  currentInterviewRoundIndexRef.current = index + 1;
  const loadingTimeout = setTimeout(async () => {
    firstIncompleteRound.roundType == "coding"
      ? await initialCoreSetupForDSARound({
          thisInterviewFirestoreID: interviewIDSlug,
          roundTitle: firstIncompleteRound.roundTitle,
          interviewRounds: interviewData.interviewRounds,
          totalNumberOfDSAQuestions: interviewData.totalNumberOfDSAQuestions,
          currentDSAQuestionNumber: minIncompleteQuestionNumber,
        })
      : setConversationAndTimeStamps({
          ...commonParamsForSetConversationAndTimeStamps,
          ...userChoicesForInterview,
          interviewRounds: interviewData.interviewRounds,
          currentInterviewRoundIndex: index + 1,
          resumeText: interviewData.Resume || "",
          interviewFirestoreID: interviewIDSlug,
        });

    setRetakeInitialSetupDone(true);

    await overrideVariableATL1orL2CollectionFStore({
      parent_collection: firestoreCollectionId,
      parent_document: firestoreDocumentId,
      child_collection: firestoreChildCollectionId,
      child_document: interviewIDSlug,
      variableToUpdate: "isThisInterviewRetaken",
      updatedValue: true,
    });
  }, 500);

  return () => {
    clearTimeout(loadingTimeout);
  };
};

export const updateSeveralFirestoreVariables = async ({
  firestoreCollectionId,
  firestoreChildCollectionId,
  firestoreDocumentId,
  interviewFirestoreID,
  variablesToUpdate,
}) => {
  // Create an array of promises for each update operation
  const updatePromises = variablesToUpdate.map(
    ({ variableToUpdate, updatedValue }) => {
      return overrideVariableATL1orL2CollectionFStore({
        parent_collection: firestoreCollectionId,
        parent_document: firestoreDocumentId,
        child_collection: firestoreChildCollectionId,
        child_document: interviewFirestoreID,
        variableToUpdate,
        updatedValue,
      });
    }
  );

  // Await all update promises concurrently
  await Promise.all(updatePromises);
};

export function handleCodeSubmission(
  roundTitle,
  code,
  setCode,
  allDSAQuestionsIdList,
  internalDSARoundQuestions,
  currentDSAQuestionNumber,
  setCurrentInterviewState,
  userUid,
  storeThisDataInFirestore,
  dsaSolutionSubmittedAtTime,
  roundStatus,
  initialCoreSetupForDSARound,
  interviewFirestoreID,
  firestoreCollectionId,
  firestoreChildCollectionId,
  firestoreDocumentId,
  interviewRounds,
  conversation
) {
  return async () => {
    var userCode = code;
    console.log("Dwaraka allDSAQuestionsIdList: ", allDSAQuestionsIdList);
    console.log("Dwaraka currentDSAQuestionNumber: ", currentDSAQuestionNumber);
    var botQuestionPotdId = allDSAQuestionsIdList[currentDSAQuestionNumber - 2];

    setCurrentInterviewState(interviewStates.interviewPaused);
    const result = await getAlgoRoundEvaluation({
      userCode: userCode,
      question:
        internalDSARoundQuestions[currentDSAQuestionNumber - 2]?.question,
      conversation: conversation,
      userUid: userUid,
      interviewId: interviewFirestoreID,
    });
    console.log("DWaraka Siri algo eval result: " + result);
    const efficiencyRating = parseFloat(
      JSON.parse(result).Ratings.AlgorithmEfficiency.rating
    );
    const handlingRating = parseFloat(
      JSON.parse(result).Ratings.HandlingCases.rating
    );
    const readabilityRating = parseFloat(
      JSON.parse(result).Ratings.CodeStructureAndReadability.rating
    );
    console.log(
      "DWaraka Siri algo eval efficiencyRating, handlingRating, readabilityRating: " +
        efficiencyRating,
      handlingRating,
      readabilityRating
    );

    const thisQuestionScore = parseFloat(
      (
        0.55 * efficiencyRating +
        0.35 * handlingRating +
        0.1 * readabilityRating
      ).toFixed(2)
    );
    console.log(
      "DWaraka Siri algo eval thisQuestionScore: " + thisQuestionScore
    );

    await storeThisDataInFirestore(
      JSON.parse(result),
      userCode,
      botQuestionPotdId,
      thisQuestionScore
    );
    var variablesToUpdate = [];
    if (roundTitle === "DIP") {
      await increasePotdSubmissionCount(
        `DIPQuestionsListTillToday/${botQuestionPotdId}`,
        "totalNumberOfSubmissions",
        1,
        userUid
      );
      await incrementUserCredits(
        "AllUsersData",
        "numberOfDIPSubmissions",
        1,
        userUid
      );
      await incrementUserCredits(
        "userSpecifsubmittedPotdsListUpdate",
        "submittedDIPsList",
        botQuestionPotdId,
        userUid
      );
      variablesToUpdate = [
        {
          variableToUpdate: `DIPSubmittedAt`,
          updatedValue: dsaSolutionSubmittedAtTime,
        },
      ];
    } else {
      await increasePotdSubmissionCount(
        `DSAQuestionsList/${botQuestionPotdId}`,
        "totalNumberOfSubmissions",
        1,
        userUid
      );
      variablesToUpdate = [
        {
          variableToUpdate: `DSAQ${currentDSAQuestionNumber - 1}SubmittedAt`,
          updatedValue: dsaSolutionSubmittedAtTime,
        },
        {
          variableToUpdate: `DSAQ${currentDSAQuestionNumber - 1}Status`,
          updatedValue: roundStatus.Completed,
        },
      ];
    }
    await updateSeveralFirestoreVariables({
      firestoreCollectionId: firestoreCollectionId,
      firestoreChildCollectionId: firestoreChildCollectionId,
      firestoreDocumentId: firestoreDocumentId,
      interviewFirestoreID: interviewFirestoreID,
      variablesToUpdate: variablesToUpdate,
    });
    setCode("");
    if (roundTitle != "DIP") {
      await initialCoreSetupForDSARound({
        interviewRounds: interviewRounds,
        currentDSAQuestionNumber: currentDSAQuestionNumber,
      });
    }
  };
}

export function handlePostDSAQuestionSubmissionTasks({
  firestoreCollectionId,
  firestoreChildCollectionId,
  firestoreDocumentId,
  setLoading,
  dSAQuestionsReportDataArray,
  dsaQuestionsScores,
  setdSAQuestionsReportDataArray,
  setDsaQuestionsScores,
  overallInterviewScore,
  interviewFirestoreID,
  userData,
  setOverallInterviewScore,
  interviewType,
  currentDSAQuestionNumber,
  weightage,
}) {
  return async (
    evaluationData,
    userCode,
    botQuestionPotdId,
    thisQuestionScore
  ) => {
    setLoading(true);

    const questionData = {
      userCode_data: userCode,
      evaluation_data: evaluationData,
      dsa_question_potdId: botQuestionPotdId,
      timeStampAtSubmission: new Date(),
    };

    var latestDSAQuestionsDataArray = [...(dSAQuestionsReportDataArray || [])];
    latestDSAQuestionsDataArray[currentDSAQuestionNumber - 2] = questionData;
    var latestScoresArray = [...(dsaQuestionsScores || [])];
    latestScoresArray[currentDSAQuestionNumber - 2] =
      parseFloat(thisQuestionScore);
    setdSAQuestionsReportDataArray(latestDSAQuestionsDataArray);
    setDsaQuestionsScores(latestScoresArray);
    var variablesToUpdate = [];
    if (interviewType === "DIP") {
      variablesToUpdate = [
        {
          variableToUpdate: "DSAQuestionsDataArray",
          updatedValue: latestDSAQuestionsDataArray,
        },
      ];
    } else {
      const latestOverallInterviewScore = parseFloat(
        parseFloat(overallInterviewScore) +
          parseFloat(thisQuestionScore) * weightage
      );
      await updateUserAverageInterviewScore(
        overallInterviewScore,
        latestOverallInterviewScore,
        interviewFirestoreID,
        userData
      );
      // await incrementUserCredits(
      //   "Interviews",
      //   "accumulatedDSAScore",
      //   parseFloat((thisQuestionScore * 1).toFixed(2)),
      //   user.uid
      // );
      setOverallInterviewScore(latestOverallInterviewScore);
      var TotalDSAScore = latestScoresArray.length
        ? parseFloat(
            latestScoresArray
              .reduce((acc, current) => acc + current, 0)
              .toFixed(2)
          )
        : 0;
      variablesToUpdate = [
        {
          variableToUpdate: "overallInterviewScore",
          updatedValue: latestOverallInterviewScore,
        },
        {
          variableToUpdate: "DSAQuestionsDataArray",
          updatedValue: latestDSAQuestionsDataArray,
        },
        {
          variableToUpdate: "DSAQuestionsScoresArray",
          updatedValue: latestScoresArray,
        },
        {
          variableToUpdate: "TotalDSAScore",
          updatedValue: TotalDSAScore,
        },
      ];
    }
    await updateSeveralFirestoreVariables({
      firestoreCollectionId: firestoreCollectionId,
      firestoreChildCollectionId: firestoreChildCollectionId,
      firestoreDocumentId: firestoreDocumentId,
      interviewFirestoreID: interviewFirestoreID,
      variablesToUpdate: variablesToUpdate,
    });
    setLoading(false);
  };
}

export async function setConversationAndTimeStamps({
  firestoreCollectionId,
  firestoreChildCollectionId,
  firestoreDocumentId,
  user,
  avatarName,
  interviewRounds,
  setInterviewRounds,
  internalTheoryRoundQuestions,
  internalHRRoundQuestions,
  interviewFirestoreID,
  currentInterviewRoundIndex,
  internalDSARoundQuestions,
  currentDSAQuestionNumber,
  setCurrentDSAQuestionNumber,
  resumeText,
  setConversation,
  setFirstBotMsgAudioUrls,
  setTimeStamps,
  setCurrentInterviewState,
  setShowMiniTimer,
  setTimerTime,
  timerTimeToValue,
  enterFullscreen,
  setInputDataForSystemPrompt,
}) {
  console.log("DWaraka currentDSAQuestionNumber..: ", currentDSAQuestionNumber);
  console.log("DWaraka interviewRounds..: ", interviewRounds);
  console.log(
    "DWaraka incoming..: ",
    interviewRounds[currentInterviewRoundIndex - 1].roundType,
    interviewRounds[currentInterviewRoundIndex - 1].roundTitle,
    internalDSARoundQuestions,
    currentDSAQuestionNumber,
    resumeText,
    timerTimeToValue
  );
  if (timerTimeToValue) {
    setTimerTime(timerTimeToValue);
  } else {
    setTimerTime(interviewRounds[currentInterviewRoundIndex - 1].roundTime);
  }
  let newAssistantMessageContent;
  setCurrentInterviewState(interviewStates.interviewPaused);
  const updatedRounds1 = updateRoundStatus(
    currentInterviewRoundIndex - 1,
    interviewRounds,
    roundStatus.Incomplete
  );
  setInterviewRounds(updatedRounds1);
  var updatedRounds2 = [];
  if (currentInterviewRoundIndex >= 2) {
    updatedRounds2 = updateRoundStatus(
      currentInterviewRoundIndex - 2,
      updatedRounds1,
      roundStatus.Completed
    );
    setInterviewRounds(updatedRounds2);
  }
  console.log("DWaraka updatedRounds1: ", updatedRounds1);
  console.log("DWaraka updatedRounds2: ", updatedRounds2);
  const variablesToUpdate = [
    {
      variableToUpdate: "interviewRounds",
      updatedValue: updatedRounds1,
    },
    ...(currentInterviewRoundIndex >= 2
      ? [
          {
            variableToUpdate: "interviewRounds",
            updatedValue: updatedRounds2,
          },
        ]
      : []),
  ];
  let userName =
    user.displayName.split(" ")[0].charAt(0).toUpperCase() +
    user.displayName.split(" ")[0].slice(1);
  switch (interviewRounds[currentInterviewRoundIndex - 1].roundTitle) {
    case "Theory": {
      setInputDataForSystemPrompt(internalTheoryRoundQuestions);
      newAssistantMessageContent = `Hello ${capitalizeFirstLetter(
        userName
      )}! Thank you for joining me for this interview. Shall we start the theory round of interview?`;
      await updateSeveralFirestoreVariables({
        firestoreCollectionId: firestoreCollectionId,
        firestoreChildCollectionId: firestoreChildCollectionId,
        firestoreDocumentId: firestoreDocumentId,
        interviewFirestoreID: interviewFirestoreID,
        variablesToUpdate: variablesToUpdate,
      });
      setShowMiniTimer(true);
      break;
    }
    case "Resume": {
      const extractedResume = await getExtractedResumeFromResume({
        resumeText: resumeText,
      });
      setInputDataForSystemPrompt(extractedResume);
      newAssistantMessageContent = `Hello ${capitalizeFirstLetter(
        userName
      )}! Thank you for joining me for this interview. Shall we start the resume round of this interview?`;
      await updateSeveralFirestoreVariables({
        firestoreCollectionId: firestoreCollectionId,
        firestoreChildCollectionId: firestoreChildCollectionId,
        firestoreDocumentId: firestoreDocumentId,
        interviewFirestoreID: interviewFirestoreID,
        variablesToUpdate: variablesToUpdate,
      });
      setShowMiniTimer(true);
      break;
    }
    case "HR": {
      setInputDataForSystemPrompt(internalHRRoundQuestions);
      newAssistantMessageContent = `Hello ${capitalizeFirstLetter(
        userName
      )}! Thank you for joining me for this interview. Shall we start the HR round of this interview?`;
      await updateSeveralFirestoreVariables({
        firestoreCollectionId: firestoreCollectionId,
        firestoreChildCollectionId: firestoreChildCollectionId,
        firestoreDocumentId: firestoreDocumentId,
        interviewFirestoreID: interviewFirestoreID,
        variablesToUpdate: variablesToUpdate,
      });
      setShowMiniTimer(true);
      break;
    }
    case "DSA":
      setInputDataForSystemPrompt(
        internalDSARoundQuestions[currentDSAQuestionNumber - 1].question
      );
      newAssistantMessageContent =
        currentDSAQuestionNumber === 1
          ? `Hello ${capitalizeFirstLetter(
              userName
            )}! Thank you for joining me for this interview. Shall we start the DSA round of this interview?`
          : "Are you ready for the next question?";
      console.log(
        "DWaraka checking currentDSAQuestionNumber to internalDSARoundQuestions ",
        internalDSARoundQuestions
      );
      console.log(
        "DWaraka checking currentDSAQuestionNumber to internalDSARoundQuestions.length ",
        currentDSAQuestionNumber + " , ",
        internalDSARoundQuestions.length
      );
      if (currentDSAQuestionNumber <= internalDSARoundQuestions.length) {
        console.log(
          "DWaraka incrementing currentDSAQuestionNumber to ",
          currentDSAQuestionNumber + 1
        );
        setCurrentDSAQuestionNumber(currentDSAQuestionNumber + 1);
        setShowMiniTimer(true);
      }
      break;
    case "DIP":
      setInputDataForSystemPrompt(
        internalDSARoundQuestions[currentDSAQuestionNumber - 1].question
      );
      newAssistantMessageContent =
        currentDSAQuestionNumber === 1
          ? `Hello ${capitalizeFirstLetter(
              userName
            )}! Shall we start todays D.I.P practice?`
          : "Are you ready for the next question?";
      if (currentDSAQuestionNumber <= internalDSARoundQuestions.length) {
        setCurrentDSAQuestionNumber(currentDSAQuestionNumber + 1);
        setShowMiniTimer(true);
      }
      break;

    default:
      break;
  }
  let audioURLs = [];
  audioURLs = await handleSynthesizeSpeech({
    text: newAssistantMessageContent,
    avatarName: avatarName,
  });
  console.log("newAssistantMessageContent:", newAssistantMessageContent);

  setFirstBotMsgAudioUrls(audioURLs);
  console.log("Audio URLs:", audioURLs);
  setConversation([{ role: "assistant", content: newAssistantMessageContent }]);
  setTimeStamps([new Date(), new Date()]);
  enterFullscreen();
}

export const initialSetupForInterview = async ({
  firestoreCollectionId,
  firestoreChildCollectionId,
  resumeText,
  setResumeText,
  userData,
  userChoicesForInterview,
  setInterviewFirestoreID,
  difficultyLevel,
  numberOfDSAQuestions,
  numberOfTheoryRoundQuestions,
  numberOfHrRoundQuestions,
}) => {
  try {
    var interviewDataForFireStore = {
      ...userChoicesForInterview,
      isThisEntirelyDone: false,
      difficultyLevel,
      numberOfDSAQuestions,
      numberOfTheoryRoundQuestions,
      numberOfHrRoundQuestions,
    };
    if (resumeText) {
      setResumeText(resumeText);
      interviewDataForFireStore = {
        ...interviewDataForFireStore,
        Resume: resumeText,
      };
    }

    const thisFirestoreID = await addDataAtL2CollectionFStore({
      data: interviewDataForFireStore,
      parent_collection: firestoreCollectionId,
      parent_document: userData.uid,
      child_collection: firestoreChildCollectionId,
    });
    setInterviewFirestoreID(thisFirestoreID);

    await handleReferralCreditsEarned(userData);
    await updateUserAverageInterviewScore(0, 0, thisFirestoreID, userData);
    await handleInitialUserTypeCreditsAndInterviewCountUpdation(userData);
    return {
      thisFirestoreID,
    };
  } catch (error) {
    // Handle errors (e.g., log them or show user-friendly messages)
    console.error("Error during initial setup for interview:", error);
    return {
      thisFirestoreID: null,
    };
  }
};

export const updateRoundStatus = (index, originalRounds, newRoundStatus) => {
  return originalRounds.map((round, i) => {
    if (i === index) {
      return {
        ...round,
        roundStatus: newRoundStatus,
      };
    }
    return round;
  });
};

export const calculateInterviewScoreSummary = (jsonData) => {
  let totalNumberOfQuestions = 0;
  let aggregateScore = 0;
  let averageScore = 0;

  jsonData.question_rating.forEach((question) => {
    totalNumberOfQuestions += question.total_questions_asked || 1;
    aggregateScore += (question.total_questions_asked || 1) * question.rating;
  });
  averageScore = aggregateScore / totalNumberOfQuestions;
  if (totalNumberOfQuestions < 4) {
    averageScore -= (4 - totalNumberOfQuestions) * 1.25;
  }
  if (averageScore < 0) {
    averageScore = 0;
  } else {
    averageScore = parseFloat(averageScore.toFixed(2));
  }
  return { totalNumberOfQuestions, aggregateScore, averageScore };
};
