import {
  eventTypes,
  logAnalyticsErrorEvent,
} from "../../../Global/utils/Analytics/firebaseAnalytics";
import { getRandomOrdinalString } from "../../../Global/utils/stringExtensionFunctions";
import { performAPIRequest } from "../InterviewBotFunctions";

export async function getExtractedResumeFromResume({ resumeText }) {
  const extractedResumeFunction = getResumeRoundResumeExtraction({
    resumeText,
  });
  return await extractedResumeFunction();
}

export function getResumeRoundResumeExtraction({ resumeText }) {
  return async () => {
    try {
      let retryCount = 0;
      const maxRetries = 2;
      let response;
      let m = getRandomOrdinalString({ min: 1, max: 8 });
      let n = getRandomOrdinalString({ min: m.randomNumber + 1, max: 10 });
      while (retryCount < maxRetries) {
        response = await performAPIRequest({
          endPoint: "getresumeroundresumeextraction",
          body: JSON.stringify({
            resumeText,
            m: m.respectiveString,
            n: n.respectiveString,
            stream: false,
            maxTokens: 1000,
          }),
        });

        if (response.status === 200) {
          const data = await response.json();
          return data;
        } else {
          logAnalyticsErrorEvent({
            eventType: eventTypes.gpt_api_failure,
            eventData: {
              statusCode: response.status,
              endPoint: "getresumeroundresumeextraction",
              api_call_type: "resume_round_resume_extraction",
            },
          });
          retryCount++;
        }
      }

      throw new Error(
        `API request failed with status code: ${response.status}`
      );
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
}
