import {
  faCircleCheck,
  faCircleExclamation,
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import PropTypes from "prop-types";
import React from "react";
import "./CodingRoundProblemDetailsComponent.css";

const CustomTabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CodingRoundProblemDetailsComponent = ({
  activeProblemIndex,
  codingRoundQuestionsData,
  horizontalValue,
  onHorizontalChange,
  setCodingRoundQuestionsData,
  isCompilationInProgress,
  isSubmissionInProgress,
  setShouldShowReportModal,
}) => {
  CodingRoundProblemDetailsComponent.propTypes = {
    activeProblemIndex: PropTypes.number.isRequired,
    codingRoundQuestionsData: PropTypes.array.isRequired,
    horizontalValue: PropTypes.number.isRequired,
    onHorizontalChange: PropTypes.func.isRequired,
    setCodingRoundQuestionsData: PropTypes.func.isRequired,
    isCompilationInProgress: PropTypes.bool.isRequired,
    isSubmissionInProgress: PropTypes.bool.isRequired,
    setShouldShowReportModal: PropTypes.func.isRequired,
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={horizontalValue}
          onChange={onHorizontalChange}
          aria-label="basic tabs example"
        >
          <Tab label="Problem" />
          <Tab label="Input" />
          <Tab label="Submit" />
          <Tooltip
            title="Report this question"
            placement="top"
            arrow
            className="report-questio-btn"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: red[500],
                  "& .MuiTooltip-arrow": {
                    color: red[500],
                  },
                  color: "white",
                },
              },
            }}
            TransitionComponent={Zoom}
          >
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="px-2 pointer-cursor btn btn-outline-danger"
              onClick={() => {
                setShouldShowReportModal(true);
              }}
            />
          </Tooltip>
        </Tabs>
      </Box>
      <CustomTabPanel value={horizontalValue} index={0}>
        <strong>Problem Description</strong>
        <br />
        <div style={{ textAlign: "left" }}>
          {codingRoundQuestionsData[activeProblemIndex] && (
            <>
              <p className="h6">
                {codingRoundQuestionsData[activeProblemIndex].question}
              </p>
              <br />
              <p>
                <strong>Input format:</strong>
                <br />
                <mark>
                  {codingRoundQuestionsData[activeProblemIndex]?.inputFormat}
                </mark>
              </p>
              <p>
                <strong>Sample Input:</strong>
                <br />
                <code>
                  <p className="pre-line">
                    {codingRoundQuestionsData[activeProblemIndex].sampleInput}
                  </p>
                </code>
              </p>
              <p>
                <strong>Output format:</strong>
                <br />
                <mark>
                  {codingRoundQuestionsData[activeProblemIndex]?.outputFormat}
                </mark>
              </p>
              <p>
                <strong>Sample Output:</strong>
                <br />
                <code>
                  {codingRoundQuestionsData[activeProblemIndex].sampleOutput}
                </code>
              </p>
              <div>
                <p className="fw-bold mb-1">Constraints:</p>
                <ul className="p-0">
                  {codingRoundQuestionsData[activeProblemIndex].constraints.map(
                    (constraint, index) => (
                      <li
                        className="coding_round_question_constraints"
                        key={index}
                        dangerouslySetInnerHTML={{ __html: constraint }}
                      />
                    )
                  )}
                </ul>
              </div>
            </>
          )}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={horizontalValue} index={1}>
        {isCompilationInProgress ? (
          <div className="text-center h5">
            <FontAwesomeIcon icon={faSpinner} className="mx-3" spin />
            Compiling...
            <FontAwesomeIcon icon={faSpinner} className="mx-3" spin />
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-sm btn-success"
                onClick={() => {
                  const updatedQuestions = [...codingRoundQuestionsData];
                  updatedQuestions[activeProblemIndex].input =
                    codingRoundQuestionsData[activeProblemIndex].sampleInput;
                  setCodingRoundQuestionsData(updatedQuestions);
                }}
              >
                Use Sample Input
              </button>
            </div>
            <div>
              <label>Input:</label> <br />
              <small>
                <mark>
                  {codingRoundQuestionsData[activeProblemIndex]?.inputFormat}
                </mark>
              </small>
              <br />
              <textarea
                type="text"
                rows="4"
                placeholder="Give Custom Input"
                value={codingRoundQuestionsData[activeProblemIndex]?.input}
                onChange={(e) => {
                  const updatedQuestions = [...codingRoundQuestionsData];
                  updatedQuestions[activeProblemIndex].input = e.target.value;
                  setCodingRoundQuestionsData(updatedQuestions);
                }}
                className="mt-2"
                style={{ width: "400px" }}
              />
            </div>
            <br />
            <div>
              <label>Output:</label>
              <br />
              <small>
                <mark>
                  {codingRoundQuestionsData[activeProblemIndex]?.outputFormat}
                </mark>
              </small>
              <br />
              <textarea
                rows="4"
                value={
                  codingRoundQuestionsData[activeProblemIndex]?.exception ||
                  codingRoundQuestionsData[activeProblemIndex]?.output
                }
                readOnly
                style={{ width: "400px" }}
                className={`mt-2 ${
                  codingRoundQuestionsData[activeProblemIndex]?.exception &&
                  "border-danger text-danger"
                } `}
              />
            </div>
          </div>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={horizontalValue} index={2}>
        {isSubmissionInProgress ? (
          <div className="text-center h5">
            <FontAwesomeIcon icon={faSpinner} className="mx-3" spin />
            Running test cases
            <FontAwesomeIcon icon={faSpinner} className="mx-3" spin />
          </div>
        ) : codingRoundQuestionsData[activeProblemIndex]
            ?.submitResultsPressed ? (
          <>
            <div>
              {codingRoundQuestionsData[activeProblemIndex]?.score ===
              codingRoundQuestionsData[activeProblemIndex]?.testCases
                ?.length ? (
                <Alert severity="success">
                  All the test cases have been passed
                </Alert>
              ) : (
                <Alert severity="warning" className="align-items-center ">
                  <p className="mb-0">
                    Some of the test cases failed. <br /> Please check the
                    corner cases and submit again.
                  </p>
                </Alert>
              )}
            </div>

            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Test case #</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Language</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {codingRoundQuestionsData[activeProblemIndex]?.testCases?.map(
                    (testCase, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {testCase.passed ? (
                            <p className="text-success mb-0">
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                className="me-1"
                              />
                              Success
                            </p>
                          ) : (
                            <p className="text-danger mb-0">
                              <FontAwesomeIcon
                                icon={faCircleExclamation}
                                className="me-1"
                              />
                              Failed{" "}
                            </p>
                          )}
                        </TableCell>
                        <TableCell>
                          {
                            codingRoundQuestionsData[activeProblemIndex]
                              ?.selectedLanguage
                          }
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <h5 style={{ textAlign: "left" }}>
              Total Test Cases passed -{" "}
              {codingRoundQuestionsData[activeProblemIndex]?.score}/
              {codingRoundQuestionsData[activeProblemIndex]?.testCases?.length}
            </h5>
            <h5>
              Best Score -{" "}
              {codingRoundQuestionsData[activeProblemIndex]?.bestScore} /
              {codingRoundQuestionsData[activeProblemIndex]?.testCases?.length}
            </h5>
          </>
        ) : (
          <div className="">
            <p>
              Click the <strong>Submit</strong> button located at the top of the
              editor to view the results here.
            </p>
            {/* <button
              className="btn btn-success btn-sm w-auto"
              disabled={isCompilationInProgress || isSubmissionInProgress}
              onClick={runAllTestCases}
            >
              Submit
            </button> */}
          </div>
        )}
      </CustomTabPanel>
    </Box>
  );
};

export default CodingRoundProblemDetailsComponent;
