import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const TypingEffect = ({ textToType, scrollOnType }) => {
  TypingEffect.propTypes = {
    textToType: PropTypes.string.isRequired,
    scrollOnType: PropTypes.func,
  };
  const [typedText, setTypedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < textToType.length) {
      const timer = setTimeout(() => {
        setTypedText(
          (prevTypedText) => prevTypedText + textToType[currentIndex]
        );
        setCurrentIndex((prevIndex) => prevIndex + 1);
        scrollOnType();
      }, 45); // Adjust typing speed here

      return () => clearTimeout(timer);
    }
  }, [currentIndex, textToType]);

  return (
    <div>
      <p className="m-0">{typedText}</p>
    </div>
  );
};

export default TypingEffect;
