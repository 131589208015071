export const fetchCodingResults = async ({ language, input, code }) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BACKEND_BASEURL}/coding`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          language: language,
          input,
          code,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error executing code:", error);
    throw error;
  }
};

export const fetchUserFromEmail = async ({ email }) => {
  console.log("Dwaraka Siri email: ", email);
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BACKEND_BASEURL}/get-user-from-email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data?.email;
  } catch (error) {
    console.error("No user flund with given email:", error);
    return null;
  }
};
