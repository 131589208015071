import { Dialog, DialogContent } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SettingLoader from "../Loaders/SettingLoader/SettingLoader";
import MicCamPermissionModal from "../Modals/MicCamPermissionModal/MicCamPermissionModal";
import "./CheckMicCamPermission.css";
export default function CheckMicCamPermission({
  checkFor,
  triggerMediaCheck,
  permissionsCheckDone,
}) {
  CheckMicCamPermission.propTypes = {
    checkFor: PropTypes.string.isRequired,
    triggerMediaCheck: PropTypes.bool,
    permissionsCheckDone: PropTypes.func.isRequired,
  };

  const [shouldShowNoMicModal, setShouldShowNoMicModal] = useState(false);
  const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false);
  const [microphonePermissionGranted, setMicrophonePermissionGranted] =
    useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkCameraPermission = async () => {
      var isCameraPermitted = false;
      var isMicPermitted = false;
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasVideoDevice = devices.some(
          (device) => device.kind === "videoinput"
        );

        if (checkFor.includes("camera")) {
          if (!hasVideoDevice) {
            window.alert("No camera found on your device.");
            permissionsCheckDone({ isSuccessful: false });
            setShouldShowNoMicModal(true);
            return;
          }

          const videoStream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          videoStream.getTracks().forEach((track) => track.stop());
          setCameraPermissionGranted(true);
          isCameraPermitted = true;
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
        setCameraPermissionGranted(false);
        setShouldShowNoMicModal(true);
        permissionsCheckDone({ isSuccessful: false });
      }

      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasAudioDevice = devices.some(
          (device) => device.kind === "audioinput"
        );

        if (checkFor.includes("microphone")) {
          if (!hasAudioDevice) {
            window.alert("No microphone found on your device.");
            setShouldShowNoMicModal(true);
            permissionsCheckDone({ isSuccessful: false });
            return;
          }

          const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          audioStream.getTracks().forEach((track) => track.stop());
          setMicrophonePermissionGranted(true);
          isMicPermitted = true;
        }
      } catch (error) {
        console.error("Error accessing microphone:", error);
        setMicrophonePermissionGranted(false);
        setShouldShowNoMicModal(true);
      }
      permissionsCheckDone({
        isSuccessful: isCameraPermitted && isMicPermitted,
      });
    };

    const checkBothPermissions = async () => {
      setLoading(true); // Set loading to true while checking permissions

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        // Check if both video and audio tracks are present
        const hasVideoTrack = stream.getVideoTracks().length > 0;
        const hasAudioTrack = stream.getAudioTracks().length > 0;

        if (!hasVideoTrack) {
          window.alert("No camera found on your device.");
          setCameraPermissionGranted(false);
          setShouldShowNoMicModal(true);
          permissionsCheckDone({ isSuccessful: false });
          setLoading(false);
          return;
        }

        if (!hasAudioTrack) {
          window.alert("No microphone found on your device.");
          setMicrophonePermissionGranted(false);
          setShouldShowNoMicModal(true);
          permissionsCheckDone({ isSuccessful: false });
          setLoading(false);
          return;
        }

        // Both camera and microphone permissions granted
        setCameraPermissionGranted(true);
        setMicrophonePermissionGranted(true);
        permissionsCheckDone({ isSuccessful: true });
        setLoading(false);
      } catch (error) {
        console.error("Error accessing media devices:", error);
        setCameraPermissionGranted(false);
        setMicrophonePermissionGranted(false);
        setShouldShowNoMicModal(true);
        permissionsCheckDone({ isSuccessful: false });
        setLoading(false);
      }
    };

    const checkPermissions = async () => {
      setLoading(true); // Set loading to true while checking permissions
      await Promise.all([checkCameraPermission()]);
      setLoading(false); // Set loading to false once permissions are checked
    };
    if (triggerMediaCheck) {
      if (checkFor === "camera & microphone") {
        checkBothPermissions();
      } else {
        checkPermissions();
      }
    }
  }, [checkFor, triggerMediaCheck]);

  return (
    <div className="mic-cam-checker">
      {loading ? (
        <Dialog
          open={loading}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            ".MuiPaper-root": {
              padding: "20px",
            },
          }}
        >
          <DialogContent>
            <SettingLoader />
            <h5 className="fw-bolder theme-12009d-color text-sm md:text-xl">
              Checking media permissions
            </h5>
          </DialogContent>
        </Dialog>
      ) : (
        <MicCamPermissionModal
          shouldShowNoMicModal={shouldShowNoMicModal}
          source={
            !(microphonePermissionGranted || cameraPermissionGranted)
              ? "camera & microphone"
              : !cameraPermissionGranted
              ? "camera"
              : "microphone"
          }
        />
      )}
    </div>
  );
}
