import PropTypes from "prop-types";
import React from "react";
import useIsTabletSize from "../../customHooks/isTabletSizeHook";
import { convertToTitleCase } from "../../utils/stringExtensionFunctions";
import AvatarVideoPlayer from "../AvatarVideoPlayer/AvatarVideoPlayer";
import FullscreenControlButtons from "../Buttons/FullscreenControlButtons/FullscreenControlButtons";
import CameraComponent from "../CameraComponent/CameraComponent";
import "./CameraAvatarVideoComponent.css";

const CameraAvatarVideoComponent = ({
  userAvatar,
  userDisplayName,
  userUid,
  firestoreCollectionId,
  interviewFirestoreID,
  avatarVideoPlayerRef,
  avatarName,
  isFullscreen,
  enterFullscreen,
  exitFullscreen,
  proctoringData,
  setProctoringData,
  shouldVideoProctor = false,
  proctoringScreenshotGap,
}) => {
  const isTabletSize = useIsTabletSize();

  return (
    <>
      <div className="w-100">
        <div>
          {!isTabletSize && (
            <p
              className={`bg-light ${
                isTabletSize ? "user-name-text" : "user-name-text-mob"
              } text-center p-1 border m-0 border border-end-0 theme-12009d-color theme-12009d-border-color`}
            >
              <strong>Interview ID:</strong> {interviewFirestoreID}
            </p>
          )}
          <CameraComponent
            userAvatar={userAvatar}
            proctoringData={proctoringData}
            setProctoringData={setProctoringData}
            shouldVideoProctor={shouldVideoProctor}
            proctoringScreenshotGap={proctoringScreenshotGap}
            userUid={userUid}
            firestoreCollectionId={firestoreCollectionId}
            interviewFirestoreID={interviewFirestoreID}
          />
          <p
            className={`bg-light  ${
              isTabletSize ? "user-name-text" : "user-name-text-mob"
            } rounded-bottom text-center p-1 theme-12009d-color`}
          >
            {convertToTitleCase(userDisplayName)}
          </p>
        </div>
      </div>
      <div>
        {!isTabletSize && (
          <>
            {!shouldVideoProctor && (
              <FullscreenControlButtons
                isFullscreen={isFullscreen}
                enterFullscreen={enterFullscreen}
                exitFullscreen={exitFullscreen}
              />
            )}
          </>
        )}
        <AvatarVideoPlayer ref={avatarVideoPlayerRef} avatarName={avatarName} />
        <p
          className={`bg-light  ${
            isTabletSize ? "user-name-text" : "user-name-text-mob"
          } rounded-bottom text-center p-1 theme-12009d-color`}
        >
          AI Interviewer
        </p>
      </div>
      {isTabletSize && (
        <div className="w-100 p-2 mb-3">
          {!shouldVideoProctor && (
            <FullscreenControlButtons
              isFullscreen={isFullscreen}
              enterFullscreen={enterFullscreen}
              exitFullscreen={exitFullscreen}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CameraAvatarVideoComponent;
CameraAvatarVideoComponent.propTypes = {
  userAvatar: PropTypes.string.isRequired,
  userDisplayName: PropTypes.string.isRequired,
  userUid: PropTypes.string.isRequired,
  firestoreCollectionId: PropTypes.string.isRequired,
  interviewFirestoreID: PropTypes.string.isRequired,
  avatarVideoPlayerRef: PropTypes.object.isRequired,
  avatarName: PropTypes.string.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  enterFullscreen: PropTypes.func.isRequired,
  exitFullscreen: PropTypes.func.isRequired,
  proctoringData: PropTypes.array.isRequired,
  setProctoringData: PropTypes.func.isRequired,
  shouldVideoProctor: PropTypes.bool.isRequired,
  proctoringScreenshotGap: PropTypes.number,
};
