import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllBusinessCandidatesList } from "../../../BusinessAdmin/utils/FirebaseFunctions/realtimeDBFunctions";
import homePagePreloaderAnimation from "../../../Global/assets/LottieFiles/homePagePreloaderAnimation.json";
import { roundStatus } from "../../../Global/components/InterviewBot/InterviewBotConstants/InterviewBotConstants";
import PageHelmet from "../../../Global/components/PageHelmet/PageHelmet";
import PreloaderSection from "../../../Global/components/PreloaderSection/PreloaderSection";
import ProgressiveImage from "../../../Global/components/ProgressiveImage/ProgressiveImage";
import useIsTabletSize from "../../../Global/customHooks/isTabletSizeHook";
import useFullscreen from "../../../Global/customHooks/useFullscreen";
import {
  capitalizeFirstLetter,
  convertToTitleCase,
} from "../../../Global/utils/stringExtensionFunctions";
import RemainingTimeCounter from "../../components/RemainingTimeCounter/RemainingTimeCounter";
import BusinessFooter from "../../layouts/BusinessFooter/BusinessFooter";
import BusinessNavBar from "../../layouts/BusinessNavBar/BusinessNavBar";
import {
  fetchAllBusinessPageData,
  findIfValidBusinessUser,
} from "../../utils/FirebaseFunctions/realtimeDBFunctions";
import { logout } from "../../utils/authFunctions";
import {
  getSiteBusinessName,
  removePrivateAccessVaraible,
} from "../../utils/stringExtensionFunction";
import "./LandingPage.css";

const LandingPage = () => {
  const isTabletSize = useIsTabletSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const [eligibleTestsList, setEligibleTestsList] = useState([]);
  const { user } = useSelector((state) => ({ ...state }));
  const { isFullscreen } = useFullscreen();
  const businessName = capitalizeFirstLetter(getSiteBusinessName());
  useEffect(() => {
    const getPageData = async ({ pageName }) => {
      setIsLoading(true);
      const data = await fetchAllBusinessPageData({
        realTimeDBPath: `${capitalizeFirstLetter(
          getSiteBusinessName()
        )}_all_pages_data`,
        categoryName: pageName,
      });
      return data;
    };
    const getTestsList = async (data, email, isPrivate) => {
      const statuses = [];
      await Promise.all(
        Object.keys(data).map(async (key) => {
          if (key !== "valid_users_mails") {
            let candidatesArray = removePrivateAccessVaraible(data[key]);
            let foundUserInList = false;
            if (Array.isArray(candidatesArray)) {
              await Promise.all(
                candidatesArray.map(async (obj) => {
                  if (obj.email === email) {
                    foundUserInList = true;
                    const testPageData = await getPageData({ pageName: key });
                    statuses.push({
                      key,
                      status: obj.status,
                      accessTime: testPageData.accessTime,
                      title: testPageData.title,
                      description: testPageData.description,
                    });
                  }
                })
              );
              if (
                isPrivate == false &&
                data[key].isPrivateAccess == false &&
                !foundUserInList
              ) {
                const testPageData = await getPageData({ pageName: key });
                statuses.push({
                  key,
                  status: roundStatus.Unattempted,
                  accessTime: testPageData.accessTime,
                  title: testPageData.title,
                  description: testPageData.description,
                });
              }
            } else if (
              isPrivate == false &&
              data[key].isPrivateAccess == false
            ) {
              const testPageData = await getPageData({ pageName: key });
              statuses.push({
                key,
                status: roundStatus.Unattempted,
                accessTime: testPageData.accessTime,
                title: testPageData.title,
                description: testPageData.description,
              });
            }
          }
        })
      );
      return statuses;
    };

    const checkStatus = async (isPrivate) => {
      if (isPrivate) {
        const interviewStatus = await findIfValidBusinessUser({
          email: user.email,
          realTimeDBPath: `${businessName}_candidates_list`,
          categoryName: "valid_users_mails",
        });
        if (!interviewStatus) {
          logout({ dispatch, navigate, location });
          navigate("/login");
          return;
        }
      }
      const allCandidatesObj = await getAllBusinessCandidatesList(
        `${businessName.split("-")[0]}_candidates_list`
      );
      const list = await getTestsList(allCandidatesObj, user.email, isPrivate);
      // console.log("DWaraka Siri all tests list: ", list);

      setEligibleTestsList(list);
    };
    const loadData = async () => {
      setIsLoading(true);
      const pageDataResult = await getPageData({ pageName: "landing_page" });
      setPageData(pageDataResult);
      await checkStatus(pageDataResult.isPrivateAccess);
      setIsLoading(false);
    };
    if (user?.uid) {
      loadData();
    }
  }, [user]);

  return (
    <div className="landing-page">
      <main className="landingPage mb-5">
        <PageHelmet
          title={"Business: Landing Page"}
          description={
            "Elevate your interview skills with AI. Master software engineering interviews with AI-driven mock interviews, personalized feedback, and instant insights"
          }
        />
        {!isFullscreen && !isLoading && (
          <BusinessNavBar
            sticky={true}
            useAnchorTag={false}
            restrictRedirection={false}
            landingPageData={pageData}
          />
        )}
        {isLoading ? (
          <PreloaderSection
            animationData={homePagePreloaderAnimation}
            textData={"Awakening your AI interview partner..."}
          />
        ) : (
          <div className="jumbotron bg-light">
            <div className="select-page-main-container">
              <div className="container card select-page-justify-container">
                <div className="page_text_content mt-2 pt-2">
                  <h4 className="text-center mb-4 theme-12009d-color">
                    {pageData.title}
                  </h4>
                  <p className="text-center">{pageData.companyDescription}</p>
                </div>
                <div className="my-4 row justify-content-center m-auto w-100">
                  {eligibleTestsList.map((test, index) => (
                    <div
                      key={index}
                      className="col-xl-3 col-lg-4 col-sm-6 px-0 px-md-1 mt-3 hover-effect"
                    >
                      <div className="card border-0 rounded-3 h-100 tests-list-card">
                        <ProgressiveImage
                          src="https://firebasestorage.googleapis.com/v0/b/evaluaitor.appspot.com/o/ImagesForWebsite%2FinterviewTechnical.webp?alt=media&token=45d62ef5-3e53-4551-9a99-83ef9bb075aa"
                          placeholderSrc="https://firebasestorage.googleapis.com/v0/b/evaluaitor.appspot.com/o/ImagesForWebsite%2FinterviewTechnicalPlaceholder.webp?alt=media&token=7533977f-d921-4bcb-a596-e521102e5ef0"
                          alt={`${test.title.toLowerCase()}-logo`}
                          width="100%"
                          extraClass="rounded-top-3 border"
                        />
                        <div className="card-header text-center theme-12009d-color bg-transparent text-lg">
                          {convertToTitleCase(test.key)}
                        </div>
                        <div className="card-body p-0 tests-list-card-body">
                          <div>
                            {test.title && (
                              <p className="text-muted text-center card-title text-sm">
                                {test.title}
                              </p>
                            )}
                            {test.description && (
                              <p className="card-text ps-3">
                                {test.description}
                              </p>
                            )}
                          </div>
                          <div className="text-center">
                            <RemainingTimeCounter
                              startTime={test.accessTime.startTime}
                              endTime={test.accessTime.endTime}
                            />
                          </div>
                        </div>
                        <div className="text-center card-footer bg-light">
                          <button
                            disabled={
                              test.status != "Unattempted" ||
                              !(
                                new Date(test.accessTime.startTime) <=
                                  new Date() &&
                                new Date() < new Date(test.accessTime.endTime)
                              )
                            }
                            onClick={() => {
                              navigate(`/evaluaitions/${test.key}`);
                            }}
                            className="resume-evaluaitor-btn px-3 rounded"
                          >
                            {test.status === "Unattempted"
                              ? "Select"
                              : test.status}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
      <BusinessFooter isTabletSize={isTabletSize} />
    </div>
  );
};

export default LandingPage;
