import { faPlay, faSquareCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Editor from "@monaco-editor/react";
import PropTypes from "prop-types";
import React from "react";
import "./CodeInputSection.css";

export default function CodeInputSection({
  code,
  handleEditorChange,
  loading,
  onSubmitCodeHandler,
  handleInputSubmit,
}) {
  CodeInputSection.propTypes = {
    code: PropTypes.string,
    handleEditorChange: PropTypes.func,
    loading: PropTypes.bool,
    onSubmitCodeHandler: PropTypes.func,
    handleInputSubmit: PropTypes.func,
  };
  return (
    <div className="code-section">
      <div
        id="code-input-section-heading"
        className="code-input-section-heading text-center"
      >
        <p className="my-2 text-dark">Input your code here</p>
      </div>
      <div className="code-editor-container">
        <Editor
          value={code}
          defaultLanguage="cpp"
          theme="vs-dark"
          height="100%"
          defaultValue={code}
          onChange={handleEditorChange}
        />
      </div>
      <div
        id="code-input-section-submit-buttons"
        className="code-input-section-submit-buttons d-flex justify-content-evenly"
      >
        <div
          className={`send-code-button btn btn-sm ${
            (code.trim() == "" || loading) && "disabled"
          }`}
          onClick={handleInputSubmit}
        >
          Run code{" "}
          <FontAwesomeIcon
            icon={faPlay}
            style={{ marginLeft: "5px", height: "20px", width: "20px" }}
          />
        </div>
        <div
          className={`send-code-button btn btn-sm ${
            (code.trim() == "" || loading) && "disabled"
          }`}
          onClick={onSubmitCodeHandler}
        >
          Submit the code
          <FontAwesomeIcon
            icon={faSquareCaretRight}
            style={{ marginLeft: "5px", height: "20px", width: "20px" }}
          />
        </div>
      </div>
    </div>
  );
}
