import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Editor from "@monaco-editor/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import FullscreenExitModal from "../../Modals/ExitFullscreenModal/ExitFullscreenModal";
import { handleLanguageChange } from "../CodingRoundSuppportFunctions/CodingRoundSuppportFunctions";
import "./CodingRoundEditorComponent.css";

export default function CodingRoundEditorComponent({
  codingRoundQuestionsData,
  activeProblemIndex,
  setCodingRoundQuestionsData,
  handleCompileClick,
  runAllTestCases,
  isCompilationInProgress,
  isSubmissionInProgress,
}) {
  CodingRoundEditorComponent.propTypes = {
    codingRoundQuestionsData: PropTypes.array.isRequired,
    activeProblemIndex: PropTypes.number.isRequired,
    setCodingRoundQuestionsData: PropTypes.func.isRequired,
    handleCompileClick: PropTypes.func.isRequired,
    runAllTestCases: PropTypes.func.isRequired,
    isCompilationInProgress: PropTypes.bool.isRequired,
    isSubmissionInProgress: PropTypes.bool.isRequired,
  };
  const [showLanguageChangeAlertModal, setShowLanguageChangeAlertModal] =
    useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    codingRoundQuestionsData[activeProblemIndex]?.selectedLanguage || "cpp"
  );
  const handleConfirmLanguageChange = () => {
    handleLanguageChange({
      activeProblemIndex: activeProblemIndex,
      codingRoundQuestionsData: codingRoundQuestionsData,
      selectedLanguage: selectedLanguage,
      setCodingRoundQuestionsData: setCodingRoundQuestionsData,
    });
    setShowLanguageChangeAlertModal(false);
  };
  return (
    <div className="codingRound-editor-component">
      <div className="container">
        <div className="row align-items-center justify-content-between p-2">
          <div className="form-group w-auto">
            <div className="select-wrapper">
              <select
                className="form-control"
                id="languageSelect"
                value={
                  codingRoundQuestionsData[activeProblemIndex]?.selectedLanguage
                }
                onChange={(e) => {
                  setSelectedLanguage(e.target.value);
                  setShowLanguageChangeAlertModal(true);
                }}
              >
                <option value="cpp">C++</option>
                <option value="c">C</option>
                <option value="java">Java</option>
                <option value="python">Python</option>
              </select>
              <div className="select-arrow">
                <FontAwesomeIcon icon={faCaretDown} />
              </div>
            </div>
          </div>

          <button
            className="btn btn-primary btn-sm w-auto"
            disabled={isCompilationInProgress || isSubmissionInProgress}
            onClick={handleCompileClick}
          >
            Compile & Run
          </button>
          <button
            className="btn btn-success btn-sm w-auto"
            disabled={isCompilationInProgress || isSubmissionInProgress}
            onClick={runAllTestCases}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="codingRound-editor-container">
        <Editor
          key={activeProblemIndex}
          value={codingRoundQuestionsData[activeProblemIndex]?.code}
          defaultLanguage="cpp"
          height="100%"
          theme="vs-dark"
          defaultValue={codingRoundQuestionsData[activeProblemIndex]?.code}
          onChange={(newCode) => {
            const updatedQuestions = [...codingRoundQuestionsData];
            updatedQuestions[activeProblemIndex].code = newCode;
            setCodingRoundQuestionsData(updatedQuestions);
            console.log(updatedQuestions);
          }}
        />
      </div>
      <FullscreenExitModal
        show={showLanguageChangeAlertModal}
        onReject={() => setShowLanguageChangeAlertModal(false)}
        onConfirm={handleConfirmLanguageChange}
        text={"Switching language midway would reset your code!"}
        heading={"Are you sure?"}
        confirmBtnText={"Continue"}
        rejectBtntext={"Cancel"}
      />
    </div>
  );
}
