export const handleLanguageChange = ({
  activeProblemIndex,
  selectedLanguage,
  codingRoundQuestionsData,
  setCodingRoundQuestionsData,
}) => {
  let startingCode = "";
  switch (selectedLanguage) {
    case "cpp":
      startingCode = `//Ensure to handle inputs as per the problem requirements using cin. \n\n #include <iostream>\nusing namespace std;\nint main()\n{\n    cout << "Hello World";\n    return 0;\n}`;
      break;
    case "java":
      startingCode = `//Ensure to handle inputs as per the problem requirements using Scanner. \n\n import java.util.*;\n\npublic class Main {\n    public static void main(String[] args) {\n        System.out.println("Hello, World!");\n    }\n}`;
      break;
    case "c":
      startingCode = `//Ensure to handle inputs as per the problem requirements using scanf() or fgets(). \n\n #include <stdio.h>\nint main()\n{\n    printf("Hello, World!");\n    return 0;\n}`;
      break;
    case "python":
      startingCode = `//Ensure to handle inputs as per the problem requirements using input(). \n\n print("Hello, World!")`;
      break;
    default:
      startingCode = "Oops, somethings wrong!!";
  }
  const updatedQuestions = [...codingRoundQuestionsData];
  updatedQuestions[activeProblemIndex].code = startingCode;
  updatedQuestions[activeProblemIndex].selectedLanguage = selectedLanguage;
  setCodingRoundQuestionsData(updatedQuestions);
};
