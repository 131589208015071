import { useEffect } from "react";

const useBeforeComponentUnload = (enabled, callback) => {
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      if (callback && typeof callback === "function") {
        callback();
      }
    };

    if (enabled) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      if (enabled) {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      }
    };
  }, [enabled, callback]);
};

export default useBeforeComponentUnload;
