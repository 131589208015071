/* eslint-disable react/prop-types */
import { faVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { getProctoringForImage } from "../../../Consumer/supportFunctions/InterviewBotFunctions";
import useIsTabletSize from "../../customHooks/isTabletSizeHook";
import { uploadBase64ImageToStorage } from "../../utils/firebaseFunctions/firebaseStorageFunctions";
import { parseValidJSONFromString } from "../../utils/stringExtensionFunctions";
import MicCamPermissionModal from "../Modals/MicCamPermissionModal/MicCamPermissionModal";
import "./CameraComponent.css";

const CameraComponent = ({
  userAvatar,
  userUid,
  firestoreCollectionId,
  interviewFirestoreID,
  shouldVideoProctor = false,
  proctoringData = [],
  setProctoringData,
  proctoringScreenshotGap = 10000,
}) => {
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [cameraPermissionGranted, setCameraPermissionGranted] = useState(true);
  const [hasCamera, setHasCamera] = useState(true); // Initially assuming camera is present
  const videoRef = useRef(null);
  const isTabletSize = useIsTabletSize();
  const [shouldShowNoMicModal, setShouldShowNoMicModal] = useState(false);
  const [tempProcData, setTempProcData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [shouldProctor, setShouldProctor] = useState(shouldVideoProctor);
  // eslint-disable-next-line no-unused-vars
  const [showProctoringData, setShowProctoringData] = useState(false);

  // Inside a useEffect hook to listen for changes in the screenshots array
  useEffect(() => {
    const updateScreenshots = async () => {
      try {
        // Iterate through the screenshots array
        const updatedScreenshots = await Promise.all(
          tempProcData.map(async (screenshotData) => {
            try {
              // Wait for the API call to resolve
              const response = await screenshotData.apiCallPromise;
              const imgUrl = await uploadBase64ImageToStorage(
                `users/proctoringImages/${userUid}/${firestoreCollectionId}/${interviewFirestoreID}`,
                screenshotData.screenshot,
                `${Date.now().toString()}.png`
              );
              // Update the response data for the respective screenshot
              return {
                screenshot: imgUrl,
                response: parseValidJSONFromString(response),
              };
            } catch (error) {
              console.error("Error fetching API response:", error);
              return null; // Return null in case of error
            }
          })
        );
        console.log("Dwaraka Siri updatedScreenshots: ", updatedScreenshots);
        setProctoringData((prev) => [...prev, ...updatedScreenshots]); // Update proctoring data with the resolved screenshots

        // Remove the resolved screenshots from tempProcData
        const remainingScreenshots = tempProcData.filter(
          (_, index) => !updatedScreenshots[index].response
        );
        setTempProcData(remainingScreenshots);
      } catch (error) {
        console.error("Error fetching proctoring API response:", error);
      }
    };

    if (tempProcData.length > 0) {
      updateScreenshots();
    }
  }, [tempProcData, setProctoringData, setTempProcData]);

  useEffect(() => {
    const checkCamera = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasVideoDevice = devices.some(
          (device) => device.kind === "videoinput"
        );
        setHasCamera(hasVideoDevice);
        if (!hasVideoDevice) {
          window.alert("No camera found on your device.");
        }
      } catch (error) {
        console.error("Error checking camera:", error);
        setHasCamera(false);
      }
    };

    const startCamera = async () => {
      setIsLoading(true);
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { aspectRatio: { ideal: 354 / 206 } },
        });
        setIsLoading(false);
        setIsCameraOn(true);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
        setCameraPermissionGranted(true);
      } catch (error) {
        console.error("Error accessing the camera:", error);
        setIsLoading(false);
        setCameraPermissionGranted(false);
        setShouldShowNoMicModal(true);
      }
    };

    if (!isPaused) {
      checkCamera();
      startCamera();
    } else {
      stopCamera(); // Stop the camera if paused
    }
    console.log("Dwaraka Siri stopping Video ref changing: ", videoRef.current);
    return () => {
      stopCamera();
    };
  }, [isPaused]); // Empty dependency array ensures this runs only on unmount

  useEffect(() => {
    const screenshotInterval = setInterval(() => {
      if (isCameraOn && !isPaused && shouldProctor) {
        captureScreenshot();
      }
    }, proctoringScreenshotGap);

    return () => clearInterval(screenshotInterval);
  }, [isCameraOn, isPaused, shouldProctor]);

  const captureScreenshot = () => {
    const video = videoRef.current;
    if (video) {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Compress the image
      canvas.toBlob(
        async (blob) => {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const compressedImageDataUrl = reader.result;
            try {
              // Make the API call but don't wait for the response immediately
              const apiCallPromise = getProctoringForImage({
                image: compressedImageDataUrl,
              });

              // Store the pending API call along with the screenshot
              setTempProcData((prevScreenshots) => [
                ...prevScreenshots,
                {
                  screenshot: compressedImageDataUrl,
                  apiCallPromise: apiCallPromise,
                },
              ]);
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          };
          reader.readAsDataURL(blob);
        },
        "image/jpeg",
        0.5
      );
    }
  };

  const stopCamera = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setIsCameraOn(false);
    }
  };

  const handlePauseToggle = () => {
    setIsPaused((prevPaused) => !prevPaused);
  };

  return (
    <div
      className={`camera-container ${
        isTabletSize ? "cam-cont" : "cam-cont-mob"
      } rounded-top`}
    >
      {showProctoringData && (
        <ProctoringDataTable proctoringData={proctoringData} />
      )}
      {hasCamera ? (
        <div className="video-container">
          <div
            className={`text-center ${
              isPaused || isLoading || !isCameraOn ? "d-block" : "d-none"
            }`}
          >
            {isLoading && !isCameraOn && (
              <div className="camera-loading-container">
                <div
                  className={`${
                    isTabletSize
                      ? "camera-loading-text"
                      : "camera-loading-text-mob"
                  }`}
                >
                  Camera is starting...
                </div>
              </div>
            )}
            {!isLoading && (isPaused || !isCameraOn) && (
              <img
                loading="lazy"
                src={userAvatar}
                alt="Static Image"
                className="camera-user-image"
              />
            )}
          </div>
          <div
            className={`text-center ${
              isPaused || isLoading || !isCameraOn ? "d-none" : "d-block"
            }`}
          >
            <video ref={videoRef} autoPlay playsInline className="w-100" />
          </div>
        </div>
      ) : (
        <div className="text-center">
          <p>{isLoading ? "Checking camera..." : "Camera not available."}</p>
        </div>
      )}
      {!shouldVideoProctor && (
        <div
          className={`text-center camera-btn-container ${
            isTabletSize ? "cam-btn-cont" : "cam-btn-cont-mob"
          }`}
        >
          <button
            className={`camera-btn ${
              isPaused || !cameraPermissionGranted || !hasCamera
                ? "video-paused"
                : ""
            } ${isTabletSize ? "cam-btn" : "cam-btn-mob"}`}
            onClick={() => {
              if (!hasCamera || !cameraPermissionGranted) {
                setShouldShowNoMicModal(true);
              } else {
                handlePauseToggle();
              }
            }}
          >
            {!hasCamera || !cameraPermissionGranted ? (
              <Badge
                badgeContent="!"
                sx={{
                  "& .MuiBadge-badge": {
                    color: "red",
                    backgroundColor: "white",
                  },
                }}
                onClick={() => {
                  setShouldShowNoMicModal(true);
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faVideoSlash}
                  onClick={() => {
                    setShouldShowNoMicModal(true);
                  }}
                />
              </Badge>
            ) : (
              <FontAwesomeIcon
                icon={isPaused ? faVideoSlash : faVideo}
                style={{ color: "#fff" }}
              />
            )}
          </button>
        </div>
      )}
      <MicCamPermissionModal
        shouldShowNoMicModal={shouldShowNoMicModal}
        source="camera"
      />
    </div>
  );
};

CameraComponent.propTypes = {
  userAvatar: PropTypes.string.isRequired,
  userUid: PropTypes.string.isRequired,
  firestoreCollectionId: PropTypes.string.isRequired,
  interviewFirestoreID: PropTypes.string.isRequired,
  shouldVideoProctor: PropTypes.bool,
  proctoringData: PropTypes.array.isRequired,
  setProctoringData: PropTypes.func.isRequired,
  proctoringScreenshotGap: PropTypes.number,
};

export default CameraComponent;
const ProctoringDataTable = ({ proctoringData }) => {
  return (
    <div
      className="position-absolute border rounded border-danger bg-light p-2"
      style={{
        zIndex: "10",
        left: "50vw",
        top: "10px",
        width: "600px",
        maxHeight: "500px",
        overflowY: "auto",
      }}
    >
      <table className="w-100">
        <thead>
          <tr className="border">
            <th>Image</th>
            <th>Response</th>
          </tr>
        </thead>
        <tbody>
          {proctoringData.map((screenshot, index) => {
            return (
              <tr key={index} className="border">
                <td>
                  <img
                    src={screenshot.screenshot}
                    style={{ width: "150px", marginRight: "10px" }}
                    alt={`Screenshot ${index}`}
                  />
                </td>
                <td>{JSON.stringify(screenshot.response)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
