import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../../firebase";
export const uploadBlobToStorage = async ({ storagePath, file, fileName }) => {
  const firebaseStorageRef = storageRef(storage, storagePath);
  const imageRef = storageRef(
    firebaseStorageRef,
    fileName || Date.now().toString()
  );

  try {
    // Upload image to Firebase Storage
    await uploadBytes(imageRef, file);

    // Get download URL
    const imageUrl = await getDownloadURL(imageRef);

    return imageUrl;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

export const uploadBase64ImageToStorage = async (
  storagePath,
  base64ImageData,
  fileName
) => {
  const firebaseStorageRef = storageRef(storage, storagePath);
  const imageRef = storageRef(
    firebaseStorageRef,
    fileName || Date.now().toString()
  );

  try {
    // Extract the Base64 data excluding the prefix
    const base64Data = base64ImageData.replace(/^data:image\/\w+;base64,/, "");

    // Decode Base64 string into binary data
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Convert binary data into Blob
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Upload Blob to Firebase Storage
    await uploadBytes(imageRef, blob);

    // Get download URL
    const imageUrl = await getDownloadURL(imageRef);

    return imageUrl;
  } catch (error) {
    console.error("Error uploading base64 image:", error);
    throw error;
  }
};
