import {
  faFileCircleCheck,
  faFileCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";
import Lottie from "react-lottie";
import uploadYourResumeAnimation from "../../../Consumer/Assets/Images/LottieFiles/uploadYourResumeAnimation.json";
import "./PdfDropZoneComponent.css";

const PdfInputField = forwardRef(
  ({ handlePdfUpload, showLottie = true }, ref) => {
    PdfInputField.displayName = "PdfInputField";
    PdfInputField.propTypes = {
      setResumeText: PropTypes.func,
      handlePdfUpload: PropTypes.func,
      showLottie: PropTypes.bool,
    };
    const [resumeFile, setResumeFile] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const uploadResumeAnimationOptions = {
      loop: true,
      autoplay: true,
      animationData: uploadYourResumeAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    useImperativeHandle(ref, () => ({
      removeResumeFile,
    }));
    const removeResumeFile = () => {
      setResumeFile(null);
    };
    const handleAcceptedFiles = async (acceptedFiles) => {
      console.log("Accepted files:", acceptedFiles[0].type);
      if (acceptedFiles[0].type != "application/pdf") {
        enqueueSnackbar("Please upload pdf file.", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        setResumeFile(acceptedFiles[0]);
        handlePdfUpload(acceptedFiles[0]);
      }
    };

    const handleRejectedFiles = (rejectedFiles) => {
      console.log("Rejected files:", rejectedFiles);
      // Handle the rejected files here, e.g., show an error message.
    };

    const { getRootProps, getInputProps } = useDropzone({
      accept: "application/pdf", // Accept only PDF files
      onDrop: (accepted, rejected) => {
        handleAcceptedFiles(accepted);
        handleRejectedFiles(rejected);
      },
    });

    return (
      <div className="resume-uploader">
        <section className="file-upload-dropzone">
          <div className="wfp--dropzone">
            <div {...getRootProps({ className: "wfp--dropzone__input" })}>
              <input {...getInputProps()} />
              <div>
                <div className="upload-container">
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={resumeFile ? faFileCircleCheck : faFileCirclePlus}
                      size="xl"
                    />
                    <div className="upload-info ps-2">
                      <h6 className="upload-text pe-1">
                        {resumeFile ? (
                          <>
                            <strong>File:</strong>{" "}
                            {resumeFile.name.length > 40
                              ? resumeFile.name.substring(0, 40) + "..."
                              : resumeFile.name}
                          </>
                        ) : (
                          "Drag and drop your resume here ( pdf )"
                        )}
                      </h6>
                      <small className="upload-limit">
                        Limit 5MB per file • PDF
                      </small>
                    </div>
                  </div>

                  <button
                    aria-label="browseFiles"
                    className="browse-button btn btn-sm"
                  >
                    <FontAwesomeIcon
                      icon={faFileCirclePlus}
                      style={{
                        marginLeft: "5px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </button>
                </div>
              </div>
              {showLottie && (
                <div className="bg-light mt-2 rounded">
                  <Lottie
                    // style={{
                    //   pointerEvents: "none",
                    //   maxWidth: "100%",
                    //   height: "auto",
                    // }}
                    options={uploadResumeAnimationOptions}
                    width={300}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
);
export default PdfInputField;
