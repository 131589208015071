import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import useIsTabletSize from "../../../customHooks/isTabletSizeHook";
import useNetworkHook from "../../../customHooks/useNetworkHook";
import keepCalmQuotes from "../../../data/keepCalmQuotes.json";
import tipsData from "../../../data/tips.json";
import { shuffleArray } from "../../../utils/helperFunctions";
import { interviewStates } from "../../InterviewBot/InterviewBotConstants/InterviewBotConstants";
import PdfDropZoneComponent from "../../PdfDropZoneComponent/PdfDropZoneComponent";
import InterviewModalLottieComponent from "./InterviewModalLottieComponent";
import "./InterviewRoundsModal.css";

export default function InterviewRoundsModal({
  userData,
  doesRequireResume,
  currentInterviewState,
  setShouldShowFeedbackModal,
  miniTimerDuration,
  showMiniTimer,
  miniTimerDone,
  makeInitialSetupForInterview,
  startInterview,
  interviewFirestoreID,
}) {
  const isTabletSize = useIsTabletSize();
  const { makeNetworkCall } = useNetworkHook();
  const [resumeText, setResumeText] = useState("");
  const [randomTips, setRandomTips] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPdfProcessing, setIsPdfProcessing] = useState(false);
  const [keepCalmQuote, setKeepCalmQuote] = useState("");
  const [isScrolling, setIsScrolling] = useState(false);
  const [isIssueRaised, setIsIssuedRaised] = useState(false);

  const getRandomTips = () => {
    const randomTipsArray = [];
    const numTipsToShow = 5;

    while (randomTipsArray.length < numTipsToShow) {
      const randomIndex = Math.floor(Math.random() * tipsData.length);
      const randomTip = tipsData[randomIndex];
      if (!randomTipsArray.includes(randomTip)) {
        randomTipsArray.push(randomTip);
      }
    }

    setRandomTips(randomTipsArray);
    setIsScrolling(true);
  };
  const getRandomQuote = () => {
    setKeepCalmQuote(shuffleArray(keepCalmQuotes)[0]);
  };
  useEffect(() => {
    // Function to update the quote every 3 seconds
    const intervalId = setInterval(getRandomQuote, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures that this effect runs only once, like componentDidMount

  useEffect(() => {
    console.log(isPdfProcessing);
    getRandomTips();
    getRandomQuote();
  }, []);
  useEffect(() => {
    if (userData) {
      setIsLoading(false);
      if (currentInterviewState == interviewStates.interviewNotStarted) {
        console.log(
          "DWaraka triggering makeInitialSetupForInterview: ",
          currentInterviewState
        );
        if (!doesRequireResume) {
          makeInitialSetupForInterview(resumeText);
        } else if (resumeText) {
          makeInitialSetupForInterview(resumeText);
        }
      }
    }
  }, [userData, resumeText]);

  const splitTip = (tip) => {
    const splitIndex = tip.indexOf(":");
    return {
      strongPart: tip.substring(0, splitIndex) + ": ",
      regularPart: tip.substring(splitIndex + 1),
    };
  };

  const handleStartResumeSection = async () => {
    makeNetworkCall({
      funcWithInternet: async () => {
        await startInterview({
          currentInterviewRoundIndex: 1,
          resumeText: resumeText,
        });
      },
    });
  };
  return (
    <div className="InterviewRoundsModal-main-container px-1">
      <div className="InterviewRoundsModal-frame extra-margin-bottom-coz-of-sticky-footer">
        {interviewFirestoreID && (
          <p className="bg-light user-name-text text-center p-1 border mt-1  theme-12009d-color text-sm md:text-base">
            <strong>Interview ID:</strong> {interviewFirestoreID}
          </p>
        )}
        <div className="InterviewRoundsModal-top p-1 p-md-2 p-lg-3 text-center">
          {showMiniTimer ? (
            <BottomLoadingTextComponent
              text1={
                <>
                  <strong>{splitTip(keepCalmQuote).strongPart}</strong>
                  {splitTip(keepCalmQuote).regularPart}
                </>
              }
            />
          ) : isLoading ||
            currentInterviewState == interviewStates.interviewSettingUp ? (
            <strong>Settings up the best AI interviewer for you</strong>
          ) : currentInterviewState == interviewStates.interviewNotStarted ? (
            doesRequireResume ? (
              <strong>Please upload your resume to get started</strong>
            ) : (
              <strong>Settings up the best AI interviewer for you</strong>
            )
          ) : currentInterviewState == interviewStates.interviewSettingDone ? (
            <strong>All set up. Lets get started</strong>
          ) : currentInterviewState == interviewStates.dipAlreadySolved ? (
            <strong>
              You have solved this DIP. Please come back tomorrow for a new
              challenge
            </strong>
          ) : currentInterviewState == interviewStates.dipTimerOut ? (
            <strong>
              Your effort is appreciated, but the time constraints can be
              challenging. Keep practicing, and you will definitely smash it
              next time.
            </strong>
          ) : currentInterviewState == interviewStates.interviewPaused ? (
            <BottomLoadingTextComponent
              text1="EvaluAIting your performance"
              text2="It will take few moments..."
            />
          ) : currentInterviewState == interviewStates.interviewEnded ? (
            <strong>
              You have completed the interview. <br />
              Thank you for your time. Have a nice day ahead.
            </strong>
          ) : currentInterviewState == interviewStates.interviewFailed ? (
            <>
              <strong>
                Interview Failed. Fill the form to raise an issue.{" "}
              </strong>
              <br />
              We will investigate and refund your credits if it&apos;s an issue
              from our end.
            </>
          ) : (
            <BottomLoadingTextComponent
              text1="Evaluating your performance and preparing a detailed report for you."
              text2="It will take few moments..."
            />
          )}
        </div>

        {currentInterviewState == interviewStates.interviewNotStarted &&
          doesRequireResume && (
            <div className="mt-5 mb-0 mx-5">
              {" "}
              <PdfDropZoneComponent
                setResumeText={setResumeText}
                setIsPdfProcessing={setIsPdfProcessing}
              />
            </div>
          )}
        <div
          className={`row justify-content-center align-middle
            ${
              showMiniTimer
                ? "my-5"
                : currentInterviewState != interviewStates.interviewNotStarted
                ? "mt-2"
                : ""
            }`}
        >
          {showMiniTimer && (
            <div className="d-flex align-items-center w-auto">
              <CountdownCircleTimer
                isPlaying={showMiniTimer}
                duration={miniTimerDuration}
                colors={["#12009d", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[
                  miniTimerDuration / 2,
                  miniTimerDuration / 4,
                  miniTimerDuration / 8,
                  0,
                ]}
                onComplete={() => {
                  miniTimerDone();
                }}
                strokeWidth={isTabletSize ? 10 : 5}
                size={isTabletSize ? 150 : 75}
              >
                {({ remainingTime }) => (
                  <div className="text-center">
                    <p className="mb-1 text-xs md:text-lg">Starts in...</p>
                    <h1 className="d-inline h1 text-base md:text-2xl">
                      {remainingTime}
                    </h1>{" "}
                    <small>sec</small>
                  </div>
                )}
              </CountdownCircleTimer>
            </div>
          )}
          {!(
            currentInterviewState === interviewStates.interviewNotStarted &&
            doesRequireResume
          ) && (
            <div className="d-inline-block w-auto p-events-none">
              <InterviewModalLottieComponent
                showMiniTimer={showMiniTimer}
                currentInterviewState={currentInterviewState}
                doesRequireResume={doesRequireResume}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
        {currentInterviewState == interviewStates.interviewSettingDone && (
          <div className="text-center">
            <button
              aria-label="startInterview"
              className={`btn btn-primary my-2 resume-evaluaitor-btn px-2 px-md-3 py-1 text-sm md:text-lg`}
              onClick={handleStartResumeSection}
            >
              Start{" "}
              <FontAwesomeIcon icon={faArrowRight} size="sm" className="ps-1" />
            </button>
          </div>
        )}
        {currentInterviewState == interviewStates.interviewFailed &&
          !isIssueRaised && (
            <div className="text-center">
              <button
                aria-label="startInterview"
                className={`btn btn-primary my-2 resume-evaluaitor-btn px-4 py-2`}
                onClick={() => {
                  setShouldShowFeedbackModal(true);
                  setIsIssuedRaised(true);
                }}
              >
                Raise an Issue <ArrowForwardOutlinedIcon />
              </button>
            </div>
          )}
        {isScrolling && (
          <marquee
            className="InterviewRoundsModal-bottom p-1 p-md-3"
            direction="left"
          >
            <div className="text-sm md:text-base">
              <TipsAndUpdatesIcon className="me-2" />
              <strong>{splitTip(randomTips[0]).strongPart}</strong>
              {splitTip(randomTips[0]).regularPart}
              <TipsAndUpdatesIcon className="margin-end-50 ms-2" />
              <TipsAndUpdatesIcon className="margin-start-50 me-2" />
              <strong>{splitTip(randomTips[1]).strongPart}</strong>
              {splitTip(randomTips[1]).regularPart}
              <TipsAndUpdatesIcon className="margin-end-50 ms-2" />
              <TipsAndUpdatesIcon className="margin-start-50 me-2" />
              <strong>{splitTip(randomTips[2]).strongPart}</strong>
              {splitTip(randomTips[2]).regularPart}
              <TipsAndUpdatesIcon className="margin-end-50 ms-2" />
              <TipsAndUpdatesIcon className="margin-start-50 me-2" />
              <strong>{splitTip(randomTips[3]).strongPart}</strong>
              {splitTip(randomTips[3]).regularPart}
              <TipsAndUpdatesIcon className="margin-end-50 ms-2" />
              <TipsAndUpdatesIcon className="margin-start-50 me-2" />
              <strong>{splitTip(randomTips[4]).strongPart}</strong>
              {splitTip(randomTips[4]).regularPart}
              <TipsAndUpdatesIcon className="margin-end-50 ms-2" />
            </div>
          </marquee>
        )}
      </div>
    </div>
  );
}

const BottomLoadingTextComponent = ({ text1, text2 }) => {
  BottomLoadingTextComponent.propTypes = {
    text1: PropTypes.node,
    text2: PropTypes.string,
  };
  return (
    <div className="text-xs md:text-sm">
      {text1}
      {text2 && (
        <>
          <br />
          <FontAwesomeIcon icon={faSpinner} className="mx-3" spin spinReverse />
          {text2}
          <FontAwesomeIcon icon={faSpinner} className="mx-3" spin spinReverse />
        </>
      )}
    </div>
  );
};
InterviewRoundsModal.propTypes = {
  userData: PropTypes.object,
  doesRequireResume: PropTypes.bool,
  setShouldShowFeedbackModal: PropTypes.func.isRequired,
  currentInterviewState: PropTypes.string,
  miniTimerDuration: PropTypes.number,
  showMiniTimer: PropTypes.bool,
  miniTimerDone: PropTypes.func,
  makeInitialSetupForInterview: PropTypes.func,
  startInterview: PropTypes.func,
  interviewFirestoreID: PropTypes.string,
};
