import Editor from "@monaco-editor/react";
import PropTypes from "prop-types";
import React from "react";
import chatBotLogo from "../../assets/Images/Logos/logo_s_bg_blue.png";
import useIsTabletSize from "../../customHooks/isTabletSizeHook";
import TypingEffect from "../TypingEffect/TypingEffect";
import "./ChatBubble.css";

const ChatBubble = ({
  message,
  sender,
  timeStamp,
  scrollToBottomMessage,
  user,
}) => {
  const isTabletSize = useIsTabletSize();
  const alignClass = sender === "user" ? "left" : "right";

  const editorOptions = {
    readOnly: true, // Set this to true to disable editing
  };

  const handelScrollToBottomMessage = () => {
    scrollToBottomMessage();
  };

  function getCurrentTime(date) {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  return (
    <div
      className={`${
        isTabletSize ? "chat-bubble-container" : "chat-bubble-container-mob"
      } ${alignClass} ${
        typeof message === "string" &&
        message.startsWith("CustomMessage::") &&
        "alert-message"
      }`}
    >
      <span className={`chat-bubble`}>
        <div className="tyn-qa-avatar">
          <div
            className={`${
              isTabletSize ? "cht-media" : "cht-media-mob"
            } tyn-size-md`}
          >
            <img
              loading="lazy"
              src={
                typeof message === "string" &&
                message.startsWith("CustomMessage::")
                  ? chatBotLogo
                  : sender === "user"
                  ? user.profilepic
                  : "https://firebasestorage.googleapis.com/v0/b/evaluaitor.appspot.com/o/ImagesForWebsite%2Flogo_s_bg_blue.png?alt=media&token=173283c0-1799-468e-8a17-f7981535745b"
              }
              alt="chat-bubble-image"
            />
          </div>
        </div>
        <span
          className={`${isTabletSize ? "message-text" : "message-text-mob"} `}
        >
          {(() => {
            if (sender != "user") {
              if (timeStamp) {
                return (
                  <TypingEffect
                    textToType={
                      typeof message === "string" &&
                      message.startsWith("CustomMessage::")
                        ? message.substring("CustomMessage::".length)
                        : message
                    }
                    scrollOnType={handelScrollToBottomMessage}
                  />
                );
              } else {
                return message;
              }
            } else {
              if (typeof message === "string" && message.startsWith("//code")) {
                return (
                  <>
                    <h6 className="tyn-code-block-title tyn-overline mb-0">
                      Your code
                    </h6>
                    <Editor
                      value={message.replace(/^\/\/code/, "")}
                      defaultLanguage="cpp"
                      width={"500px"}
                      height={"200px"}
                      theme="vs-dark"
                      options={editorOptions}
                      defaultValue={message.replace(/^\/\/code/, "")}
                      onChange={() => {}}
                    />
                  </>
                );
              } else if (timeStamp?.getTime() == 0) {
                return (
                  <strong>{user.displayName} skipped this question</strong>
                );
              } else {
                return (
                  <>
                    {typeof message === "string" &&
                    message.startsWith("CustomMessage::")
                      ? message.substring("CustomMessage::".length)
                      : message}
                  </>
                );
              }
            }
          })()}
        </span>
        {timeStamp && (
          <span className={`${isTabletSize ? "timestamp" : "timestamp-mob"}`}>
            {getCurrentTime(timeStamp)}
          </span>
        )}
      </span>
    </div>
  );
};

export default ChatBubble;
ChatBubble.propTypes = {
  message: PropTypes.any,
  sender: PropTypes.string,
  timeStamp: PropTypes.object,
  scrollToBottomMessage: PropTypes.func,
  user: PropTypes.object,
};
