import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import DIPHomeAnimation from "../../../Global/assets/LottieFiles/DIPHomeAnimation.json";
import codeEvaluatingAnimation from "../../../Global/assets/LottieFiles/codeEvaluatingAnimation.json";
import findingBotAnimation from "../../../Global/assets/LottieFiles/findingBotAnimation.json";
import resumeEvaluationSuccess from "../../../Global/assets/LottieFiles/resumeEvaluationSuccess.json";
import CheckMicCamPermission from "../../../Global/components/CheckMicCamPermission/CheckMicCamPermission";
import FullscreenExitModal from "../../../Global/components/Modals/ExitFullscreenModal/ExitFullscreenModal";
import FeedbackFormModal from "../../../Global/components/Modals/FeedbackFormModal/FeedbackFormModal";
import ReportAQuestionModal from "../../../Global/components/Modals/ReportAQuestionModal/ReportAQuestionModal";
import ScreenRecorder from "../../../Global/components/ScreenRecorder/ScreenRecorder";
import useIsTabletSize from "../../../Global/customHooks/isTabletSizeHook";
import useBeforeComponentUnload from "../../../Global/customHooks/useBeforeComponentUnload";
import useFullscreen from "../../../Global/customHooks/useFullscreen";
import { fetchCodingResults } from "../../../Global/utils/CallsTobackend";
import { addDataAtL2CollectionFStore } from "../../../Global/utils/firebaseFunctions/firestoreFunctions";
import challengeCompletedAnimation from "../../assets/LottieFiles/challengeCompletedAnimation.json";
import CodingRoundQuestionsList from "../../data/CodingRoundQuestionsData";
import { updateSeveralFirestoreVariables } from "../InterviewBot/InterviewBotCoreHelperFunctions";
import ReportForCodingRoundComponent from "../ReportComponents/CodingRoundReportComponent/CodingRoundReportComponent";
import "./CodingRoundComponent.css";
import CodingEditorComponent from "./CodingRoundEditorComponent/CodingRoundEditorComponent";
import CodingRoundProblemDetailsComponent from "./CodingRoundProblemDetailsComponent/CodingRoundProblemDetailsComponent";
import CodingRoundProblemListCoponent from "./CodingRoundProblemListCoponent/CodingRoundProblemListCoponent";

const PROBLEM_TAB_INDEX = 0;
const INPUT_TAB_INDEX = 1;
const SUBMIT_TAB_INDEX = 2;

const CodingRoundComponent = forwardRef(
  (
    {
      questions,
      firestoreCollectionId = "coding_round_submissions",
      firestoreChildCollectionId = "evaluAItor",
      codingRoundTime = 1200,
      setThisCodingRoundFirestoreID,
      setThisRoundReportForHiringProcess,
      codingRoundSlug,
      shouldShowReportAtEnd = false,
      shouldVideoProctor = false,
      shouldRecordAudio = false,
      shouldRecordScreen = false,
      proctoringScreenshotGap = 10000,
    },
    ref
  ) => {
    CodingRoundComponent.displayName = "CodingRoundComponent";
    const { user } = useSelector((state) => ({ ...state }));
    const isTabletSize = useIsTabletSize();
    const {
      isFullscreen,
      enterFullscreen,
      exitFullscreen,
      showExitFullscreenAlertModal,
      setShowExitFullscreenAlertModal,
      handleConfirmExit,
    } = useFullscreen();
    const timerTimeRef = useRef(codingRoundTime);
    const timerParagraphRef = useRef();
    const recorderRef = useRef();
    const [activeProblemIndex, setActiveProblemIndex] =
      useState(PROBLEM_TAB_INDEX);
    const [horizontalValue, setHorizontalValue] = useState(PROBLEM_TAB_INDEX);
    const [codingRoundQuestionsData, setCodingRoundQuestionsData] = useState(
      []
    );
    const [isCodingRoundStarted, setIsCodingRoundStarted] = useState(false);
    const [isCodingRoundDone, setIsCodingRoundDone] = useState(false);
    const [shouldShowFeedbackModal, setShouldShowFeedbackModal] =
      useState(false);
    const [isCompilationInProgress, setIsCompilationInProgress] =
      useState(false);
    const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false);
    const [codingRoundFirestoreID, setCodingRoundFirestoreID] = useState();
    const [shouldShowReportModal, setShouldShowReportModal] = useState(false);
    const [totalTimeTaken, setTotalTimeTaken] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isCodingSettingUpInProgress, setIsCodingSettingUpInProgress] =
      useState(false);
    const [triggerMediaCheck, setTriggerMediaCheck] = useState(true);
    const [proctoringData, setProctoringData] = useState([]);
    const [recordedDataUrl, setRecordedDataUrl] = useState();
    useBeforeComponentUnload(!isCodingRoundDone && isCodingRoundStarted, () => {
      // Additional logic to execute beforeunload if needed
    });
    const defaultDIPLottieOptions = {
      loop: true,
      autoplay: true,
      animationData: isCodingRoundDone
        ? challengeCompletedAnimation
        : isLoading
        ? codeEvaluatingAnimation
        : isCodingSettingUpInProgress
        ? findingBotAnimation
        : !isCodingRoundStarted
        ? DIPHomeAnimation
        : !shouldShowReportAtEnd
        ? codeEvaluatingAnimation
        : resumeEvaluationSuccess,
    };
    let timer;

    const permissionsCheckDone = ({ isSuccessful }) => {
      setTriggerMediaCheck(false);
      if (isSuccessful) {
        /* empty */
      }
    };

    useEffect(() => {
      const updateTimer = () => {
        if (
          isCodingRoundStarted &&
          !isCodingRoundDone &&
          timerTimeRef.current > 0
        ) {
          timerTimeRef.current = Math.max(timerTimeRef.current - 1, 0);
          setTotalTimeTaken((prevTime) => prevTime + 1);
        }

        if (
          isCodingRoundStarted &&
          !isCodingRoundDone &&
          timerTimeRef.current === 0
        ) {
          setIsCodingRoundDone(true);
        }
      };

      timer = setInterval(updateTimer, 1000);

      return () => {
        clearInterval(timer);
      };
    }, [isCodingRoundStarted]);

    useEffect(() => {
      const updateParagraph = () => {
        if (timerParagraphRef.current) {
          timerParagraphRef.current.textContent = `Time Left: ${Math.floor(
            timerTimeRef.current / 60
          )}:${
            timerTimeRef.current % 60 < 10
              ? `0${timerTimeRef.current % 60}`
              : timerTimeRef.current % 60
          }`;
          requestAnimationFrame(updateParagraph);
        }
      };
      updateParagraph();
      return () => {
        cancelAnimationFrame(updateParagraph);
      };
    }, [isCodingRoundStarted]);

    const handleCompileClick = async () => {
      setIsCompilationInProgress(true);
      setHorizontalValue(INPUT_TAB_INDEX);
      if (codingRoundQuestionsData[activeProblemIndex].input == "") {
        const updatedQuestions = [...codingRoundQuestionsData];
        updatedQuestions[activeProblemIndex].input =
          codingRoundQuestionsData[activeProblemIndex].sampleInput;
        setCodingRoundQuestionsData(updatedQuestions);
      }
      const responseData = await fetchCodingResults({
        language: codingRoundQuestionsData[activeProblemIndex].selectedLanguage,
        input: codingRoundQuestionsData[activeProblemIndex].input.replace(
          /\n/g,
          " "
        ),
        code: codingRoundQuestionsData[activeProblemIndex].code,
      });

      const updatedQuestions = [...codingRoundQuestionsData];
      updatedQuestions[activeProblemIndex].exception = responseData.exception;
      updatedQuestions[activeProblemIndex].output =
        responseData.stdout || responseData.stderr;
      setCodingRoundQuestionsData(updatedQuestions);
      setIsCompilationInProgress(false);
    };

    const runAllTestCases = async () => {
      setIsSubmissionInProgress(true);
      setHorizontalValue(SUBMIT_TAB_INDEX);
      var correct = 0;
      for (
        let i = 0;
        i < codingRoundQuestionsData[activeProblemIndex].testCases.length;
        i++
      ) {
        const testCase =
          codingRoundQuestionsData[activeProblemIndex].testCases[i];
        const responseData = await fetchCodingResults({
          language:
            codingRoundQuestionsData[activeProblemIndex].selectedLanguage,
          input: testCase.input,
          code: codingRoundQuestionsData[activeProblemIndex].code,
        });
        // console.log(JSON.stringify(responseData));
        if (responseData?.executionTime) {
          codingRoundQuestionsData[activeProblemIndex].testCases[
            i
          ].executionTime = responseData.executionTime;
        }
        if (
          responseData.stdout.trim() === testCase.output ||
          responseData.stdout.trim() === testCase.output + "\n"
        ) {
          codingRoundQuestionsData[activeProblemIndex].testCases[
            i
          ].passed = true;
          console.log("Added score");
          correct = correct + 1;
        } else {
          codingRoundQuestionsData[activeProblemIndex].testCases[
            i
          ].passed = false;
        }
      }

      const updatedQuestions = [...codingRoundQuestionsData];
      if (updatedQuestions[activeProblemIndex].bestScore <= correct) {
        updatedQuestions[activeProblemIndex].bestScore = correct;
        updatedQuestions[activeProblemIndex].bestScoreCodeInput =
          updatedQuestions[activeProblemIndex].code;
      }
      if (updatedQuestions[activeProblemIndex].bestScore == 0) {
        updatedQuestions[activeProblemIndex].bestScoreCodeInput =
          updatedQuestions[activeProblemIndex].code;
      }
      updatedQuestions[activeProblemIndex].score = correct;
      updatedQuestions[activeProblemIndex].submitResultsPressed = true;
      setIsSubmissionInProgress(false);
      updatedQuestions[activeProblemIndex].submittedThisQuestion = true;
      setCodingRoundQuestionsData(updatedQuestions);
    };

    useEffect(() => {
      if (user.uid) {
        const questionsWithProperties = questions.map((question) => ({
          ...question,
          input: "",
          output: "Compilation result will appear here...",
          score: 0,
          bestScore: 0,
          bestScoreCodeInput: "",
          code: `#include <iostream>\nusing namespace std;\nint main()\n{\n    cout << "Hello World";\n    return 0;\n}`,
          selectedLanguage: "cpp",
          submitResultsPressed: false,
          submittedThisQuestion: false,
        }));
        setCodingRoundQuestionsData(questionsWithProperties);
      }
    }, [questions, user]);

    const completeCodingRound = async () => {
      setIsLoading(true);
      let recordedUrl = await recorderRef?.current?.stopRecording();
      console.log("DWaraka Siri recordedUrl: ", recordedUrl);
      setRecordedDataUrl(recordedUrl);
      const variablesToUpdate = [
        {
          variableToUpdate: "allRoundsReportData",
          updatedValue: codingRoundQuestionsData,
        },
        {
          variableToUpdate: "proctoringData",
          updatedValue: proctoringData,
        },
        {
          variableToUpdate: "recordedUrl",
          updatedValue: recordedUrl || "",
        },
        {
          variableToUpdate: "isThisEntirelyDone",
          updatedValue: true,
        },
        {
          variableToUpdate: "totalTimeTaken",
          updatedValue: totalTimeTaken,
        },
        {
          variableToUpdate: "overAllScorePercentage",
          updatedValue:
            (codingRoundQuestionsData.reduce((total, roundData) => {
              return total + roundData.bestScore;
            }, 0) /
              codingRoundQuestionsData.length) *
            10,
        },
      ];
      await updateSeveralFirestoreVariables({
        firestoreCollectionId: firestoreCollectionId,
        firestoreChildCollectionId: firestoreChildCollectionId,
        firestoreDocumentId: user.uid,
        interviewFirestoreID: codingRoundFirestoreID,
        variablesToUpdate: variablesToUpdate,
      });

      if (setThisRoundReportForHiringProcess) {
        setThisRoundReportForHiringProcess({
          firestoreId: codingRoundFirestoreID,
          reportData: {
            allRoundsReportData: codingRoundQuestionsData,
            timestamp: new Date(),
            isThisEntirelyDone: true,
          },
        });
      }
      setIsCodingRoundDone(true);
      setIsLoading(false);
      if (shouldShowReportAtEnd) {
        setShouldShowFeedbackModal(true);
      }
    };

    const handleProblemChange = (event, newValue) => {
      setActiveProblemIndex(newValue);
      setHorizontalValue(PROBLEM_TAB_INDEX);
    };

    const handleHorizontalChange = (event, newValue) => {
      setHorizontalValue(newValue);
    };

    const startCodingRound = async () => {
      setIsCodingSettingUpInProgress(true);
      if (codingRoundSlug) {
        setCodingRoundFirestoreID(codingRoundSlug);
      } else {
        const thisFirestoreID = await addDataAtL2CollectionFStore({
          data: {
            allRoundsReportData: codingRoundQuestionsData,
            overAllScorePercentage: 0,
            isThisEntirelyDone: false,
          },
          parent_collection: firestoreCollectionId,
          parent_document: user.uid,
          child_collection: firestoreChildCollectionId,
        });
        setCodingRoundFirestoreID(thisFirestoreID);
        if (setThisCodingRoundFirestoreID) {
          setThisCodingRoundFirestoreID({
            [firestoreCollectionId]: thisFirestoreID,
          });
        }
      }
      setIsCodingSettingUpInProgress(false);
      setIsCodingRoundStarted(true);
      enterFullscreen();
    };

    useImperativeHandle(ref, () => ({
      startCodingRound,
    }));

    return (
      <div className="coding-round-container">
        {(shouldRecordAudio || shouldRecordScreen) && (
          <ScreenRecorder
            ref={recorderRef}
            userUid={user.uid}
            firestoreCollectionId={firestoreCollectionId}
            roundFirestoreID={codingRoundFirestoreID}
            recordAudio={shouldRecordAudio}
            recordScreen={shouldRecordScreen}
          />
        )}
        {(!isCodingRoundStarted ||
          isLoading ||
          (isCodingRoundDone && !shouldShowReportAtEnd)) && (
          <div className="text-center coding-round-lottie-container m-auto">
            <div className="row justify-content-center align-items-center w-auto p-events-none my-3">
              {codingRoundFirestoreID && (
                <p className="bg-light user-name-text text-center p-1 border mt-1  theme-12009d-color">
                  <strong>Round ID:</strong> {codingRoundFirestoreID}
                </p>
              )}
              <div className="text-center loading-indication-text">
                {isCodingRoundDone ? (
                  <strong>
                    You have completed the test. <br />
                    Thank you for your time. Have a nice day ahead.
                  </strong>
                ) : (
                  isLoading && (
                    <>
                      <h3 className="mb-1">EvaluAIting your performance</h3>
                      <h5 className="d-inline">Please wait....</h5>
                    </>
                  )
                )}
              </div>

              <Lottie
                options={defaultDIPLottieOptions}
                height="auto"
                margin="0"
                width={
                  isCodingSettingUpInProgress || isLoading
                    ? "40vw"
                    : isTabletSize
                    ? "50vw"
                    : "80vw"
                }
              />
            </div>
            {!isLoading && !isCodingRoundDone && (
              <button
                className="resume-evaluaitor-btn px-4 py-2"
                onClick={async () => {
                  if (shouldRecordAudio || shouldRecordScreen) {
                    recorderRef.current
                      .startRecording()
                      .then((screenShared) => {
                        if (screenShared) {
                          // Screen sharing started
                          console.log("User has selected a screen to share.");
                          startCodingRound();
                        } else {
                          // Screen sharing cancelled
                          console.log("User has cancelled screen sharing.");
                        }
                      })
                      .catch((error) => {
                        console.error("Error:", error);
                      });
                  } else {
                    startCodingRound();
                  }
                }}
              >
                Start Coding Round
              </button>
            )}
          </div>
        )}
        {!isCodingRoundDone && isCodingRoundStarted && !isLoading && (
          <div className="row coding-round-content bg-light d-flex">
            <div style={{ width: "310px" }} className="p-0 d-flex border">
              <CodingRoundProblemListCoponent
                numberOfQuestions={questions.length}
                activeProblemIndex={activeProblemIndex}
                onProblemChange={handleProblemChange}
                codingRoundQuestionsData={codingRoundQuestionsData}
                timerParagraphRef={timerParagraphRef}
                completeCodingRound={completeCodingRound}
                isCompilationInProgress={isCompilationInProgress}
                isSubmissionInProgress={isSubmissionInProgress}
                isFullscreen={isFullscreen}
                enterFullscreen={enterFullscreen}
                exitFullscreen={exitFullscreen}
                firestoreCollectionId={firestoreCollectionId}
                codingRoundFirestoreID={codingRoundFirestoreID}
                proctoringData={proctoringData}
                setProctoringData={setProctoringData}
                shouldVideoProctor={shouldVideoProctor}
                proctoringScreenshotGap={proctoringScreenshotGap}
                userProfilePic={user.profilepic}
                userDisplayName={user.displayName}
                userUid={user.uid}
              />
            </div>
            <div style={{ width: "450px" }} className="p-0 d-flex border">
              <CodingRoundProblemDetailsComponent
                activeProblemIndex={activeProblemIndex}
                codingRoundQuestionsData={codingRoundQuestionsData}
                horizontalValue={horizontalValue}
                runAllTestCases={runAllTestCases}
                onHorizontalChange={handleHorizontalChange}
                setCodingRoundQuestionsData={setCodingRoundQuestionsData}
                isCompilationInProgress={isCompilationInProgress}
                isSubmissionInProgress={isSubmissionInProgress}
                setShouldShowReportModal={setShouldShowReportModal}
              />
            </div>
            <div style={{ flex: "1" }} className="p-0 border d-flex">
              <CodingEditorComponent
                codingRoundQuestionsData={codingRoundQuestionsData}
                activeProblemIndex={activeProblemIndex}
                setCodingRoundQuestionsData={setCodingRoundQuestionsData}
                handleCompileClick={handleCompileClick}
                runAllTestCases={runAllTestCases}
                isCompilationInProgress={isCompilationInProgress}
                isSubmissionInProgress={isSubmissionInProgress}
              />
            </div>
          </div>
        )}
        {isCodingRoundDone && isCodingRoundStarted && !isLoading && (
          <div className="w-100">
            {shouldShowReportAtEnd ? (
              <div className="bg-light container mt-3">
                <ReportForCodingRoundComponent
                  codingReportData={{
                    allRoundsReportData: codingRoundQuestionsData,
                    proctoringData: proctoringData,
                    recordedUrl: recordedDataUrl,
                    timestamp: new Date(),
                    isThisEntirelyDone: true,
                  }}
                  codingRoundId={codingRoundFirestoreID}
                  showShare={false}
                  showBackBtn={true}
                />
              </div>
            ) : (
              <div className="m-auto h-100 d-flex align-items-center">
                <div
                  className={`interview-potential-lottie p-events-none m-auto ${
                    isTabletSize ? "tablet" : ""
                  }`}
                >
                  <Lottie options={defaultDIPLottieOptions} height="50vh" />
                </div>
              </div>
            )}
          </div>
        )}
        <CheckMicCamPermission
          checkFor="camera & microphone"
          triggerMediaCheck={triggerMediaCheck}
          permissionsCheckDone={permissionsCheckDone}
        />
        <FeedbackFormModal
          shouldShowFeedbackModal={shouldShowFeedbackModal}
          setShouldShowFeedbackModal={setShouldShowFeedbackModal}
          roundType="Coding_Round"
          userUid={user.uid}
        />
        <FullscreenExitModal
          show={showExitFullscreenAlertModal}
          onReject={() => setShowExitFullscreenAlertModal(false)}
          onConfirm={handleConfirmExit}
          text={"It is recommended to not exit fullscreen during the test."}
          heading={"Exit Fullscreen?"}
          confirmBtnText={"Exit"}
          rejectBtntext={"Cancel"}
        />
        {shouldShowReportModal && (
          <ReportAQuestionModal
            shouldShowReportModal={shouldShowReportModal}
            setShouldShowReportModal={setShouldShowReportModal}
            questionId={codingRoundQuestionsData[activeProblemIndex].questionId}
            userUid={user.uid}
            dBPath="AllReportedCodingQuestionsList"
          />
        )}
      </div>
    );
  }
);

export default CodingRoundComponent;
CodingRoundComponent.propTypes = {
  questions: PropTypes.array.isRequired,
  firestoreCollectionId: PropTypes.string,
  firestoreChildCollectionId: PropTypes.string,
  codingRoundTime: PropTypes.number,
  setThisCodingRoundFirestoreID: PropTypes.func,
  setThisRoundReportForHiringProcess: PropTypes.func,
  codingRoundSlug: PropTypes.string,
  shouldShowReportAtEnd: PropTypes.bool,
  shouldVideoProctor: PropTypes.bool,
  shouldRecordAudio: PropTypes.bool,
  shouldRecordScreen: PropTypes.bool,
  proctoringScreenshotGap: PropTypes.number,
};

export const getNCodingQuestions = ({ companyName, numberOfQuestions }) => {
  console.log(
    "DWaraka in useEffect CodingRoundQuestionsList: ",
    CodingRoundQuestionsList
  );
  var shuffledDataset;
  if (companyName.toLocaleLowerCase().startsWith("generic")) {
    const allQuestions = Object.values(CodingRoundQuestionsList).flat();
    shuffledDataset = [...allQuestions].sort(() => Math.random() - 0.5);
  } else {
    shuffledDataset = [...CodingRoundQuestionsList[companyName]].sort(
      () => Math.random() - 0.5
    );
  }

  return shuffledDataset.slice(0, numberOfQuestions);
};
