import { lazy } from "react";
import LandingPage from "./pages/LandingPage/LandingPage";
import TestPage from "./pages/TestPage/TestPage";
const LoginPage = lazy(() => import("./pages/Auth/LoginPage"));
const ForgotPasswordPage = lazy(() =>
  import("./pages/Auth/ForgotPasswordPage")
);
const RegisterPage = lazy(() => import("./pages/Auth/RegisterPage"));

const businessRoutes = [
  {
    path: "/",
    component: LandingPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/evaluaitions/:testslug",
    component: TestPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/login",
    component: LoginPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/register",
    component: RegisterPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/forgot/password",
    component: ForgotPasswordPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: true,
  },
];
export default businessRoutes;
