import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import FullscreenControlButtons from "../../../../Global/components/Buttons/FullscreenControlButtons/FullscreenControlButtons";
import CameraComponent from "../../../../Global/components/CameraComponent/CameraComponent";
import FullscreenExitModal from "../../../../Global/components/Modals/ExitFullscreenModal/ExitFullscreenModal";
import { convertToTitleCase } from "../../../../Global/utils/stringExtensionFunctions";
import "./CodingRoundProblemListCoponent.css";

const CodingRoundProblemListCoponent = ({
  numberOfQuestions,
  activeProblemIndex,
  onProblemChange,
  codingRoundQuestionsData,
  timerParagraphRef,
  completeCodingRound,
  isCompilationInProgress,
  isSubmissionInProgress,
  isFullscreen,
  enterFullscreen,
  exitFullscreen,
  firestoreCollectionId,
  codingRoundFirestoreID,
  proctoringData,
  setProctoringData,
  shouldVideoProctor = false,
  proctoringScreenshotGap = 10000,
  userProfilePic,
  userDisplayName,
  userUid,
}) => {
  const [showFinishAlertModal, setShowFinishAlertModal] = useState(false);
  const confirmAlertModal = () => {
    completeCodingRound();
    setShowFinishAlertModal(false);
  };
  return (
    <div className="codingRoundProblemListCoponent_main">
      <div>
        <p className="text-center h4 p-2 theme-gradient text-light">Problems</p>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={activeProblemIndex}
          onChange={onProblemChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {Array.from({ length: numberOfQuestions }, (_, index) => (
            <Tab
              key={index}
              disabled={isCompilationInProgress || isSubmissionInProgress}
              label={
                <p>
                  Problem {index + 1}{" "}
                  {codingRoundQuestionsData[index]?.submittedThisQuestion && (
                    <FontAwesomeIcon icon={faCheckCircle} className="mx-1" />
                  )}
                </p>
              }
            />
          ))}
        </Tabs>
        <h5
          className="text-center theme-12009d-color"
          ref={timerParagraphRef}
        ></h5>
        <div className="online-test-cam border theme-12009d-border-color rounded">
          <div>
            <CameraComponent
              userAvatar={userProfilePic}
              proctoringData={proctoringData}
              setProctoringData={setProctoringData}
              shouldVideoProctor={shouldVideoProctor}
              proctoringScreenshotGap={proctoringScreenshotGap}
              userUid={userUid}
              firestoreCollectionId={firestoreCollectionId}
              interviewFirestoreID={codingRoundFirestoreID}
            />
            <p className="bg-light w-100 text-center p-1 pb-0 mb-0 theme-12009d-color">
              {convertToTitleCase(userDisplayName)}
            </p>
          </div>
          <p className="bg-light w-100 text-center border mt-1 p-1 pb-0 mb-0 rounded theme-12009d-color">
            <strong>Round ID: </strong>
            {codingRoundFirestoreID}
          </p>
        </div>
      </div>
      <div className="text-center">
        <button
          onClick={() => {
            setShowFinishAlertModal(true);
          }}
          className="btn resume-evaluaitor-btn rounded compiler-finish-btn w-75"
        >
          Finish
        </button>
      </div>
      <div className="my-2">
        <FullscreenControlButtons
          isFullscreen={isFullscreen}
          enterFullscreen={enterFullscreen}
          exitFullscreen={exitFullscreen}
        />
      </div>
      <FullscreenExitModal
        show={showFinishAlertModal}
        onReject={() => {
          setShowFinishAlertModal(false);
        }}
        onConfirm={confirmAlertModal}
        text="Are you sure to finish this round? You can't revisit once submitted"
        heading="Are you sure?"
        confirmBtnText="Submit"
        rejectBtntext="Cancel"
      />
    </div>
  );
};

export default CodingRoundProblemListCoponent;
CodingRoundProblemListCoponent.propTypes = {
  numberOfQuestions: PropTypes.number.isRequired,
  activeProblemIndex: PropTypes.number.isRequired,
  onProblemChange: PropTypes.func.isRequired,
  codingRoundQuestionsData: PropTypes.array.isRequired,
  timerParagraphRef: PropTypes.object.isRequired,
  completeCodingRound: PropTypes.func.isRequired,
  isCompilationInProgress: PropTypes.bool.isRequired,
  isSubmissionInProgress: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  enterFullscreen: PropTypes.func.isRequired,
  exitFullscreen: PropTypes.func.isRequired,
  firestoreCollectionId: PropTypes.string.isRequired,
  codingRoundFirestoreID: PropTypes.string.isRequired,
  proctoringData: PropTypes.array.isRequired,
  setProctoringData: PropTypes.func.isRequired,
  shouldVideoProctor: PropTypes.bool,
  proctoringScreenshotGap: PropTypes.number,
  userProfilePic: PropTypes.string.isRequired,
  userDisplayName: PropTypes.string.isRequired,
  userUid: PropTypes.string.isRequired,
};
