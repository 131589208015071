import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRight,
  faBookmark,
  faChevronLeft,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Zoom } from "@mui/material";
import { red } from "@mui/material/colors";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReportAQuestionModal from "../../Modals/ReportAQuestionModal/ReportAQuestionModal";
import "./OnlineTestQuestionComponent.css";

const OnlineTestQuestionComponent = ({
  currentQuestionId,
  userUid,
  index,
  question,
  options,
  selectedOne,
  onOptionSelect,
  isFirstQuestion,
  isLastQuestion,
  handleNextQuestion,
  handleGoToPreviousQuestion,
  handleToggleReview,
  alreadySavedForReview,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldShowReportModal, setShouldShowReportModal] = useState(false);
  useEffect(() => {
    setIsVisible(false);
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 50);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [question]);

  return (
    <div className={`all-question-container`}>
      <div className="question-and-options-container">
        <div
          className={`question-container ${isVisible ? "visible" : "hidden"}`}
        >
          <div className="d-flex justify-content-between theme-12009d-color mb-3">
            <strong>Question {index + 1}: </strong>{" "}
            <Tooltip
              title="Report this question"
              placement="top"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: red[500],
                    "& .MuiTooltip-arrow": {
                      color: red[500],
                    },
                    color: "white",
                  },
                },
              }}
              TransitionComponent={Zoom}
            >
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="px-2 pointer-cursor btn btn-outline-danger"
                onClick={() => setShouldShowReportModal(true)}
              />
            </Tooltip>
          </div>
          <p>
            <div
              className="mt-2"
              dangerouslySetInnerHTML={{
                __html: question,
              }}
            />
          </p>
        </div>
        <div
          className={`question-options-container ${
            isVisible ? "visible" : "hidden"
          }`}
        >
          {Object.entries(options).map(([key, option], optionIndex) => (
            <div key={optionIndex} className="question-option">
              <input
                type="radio"
                id={`option${optionIndex}`}
                name="options"
                value={option}
                checked={selectedOne === key}
                onChange={() => onOptionSelect(key)}
              />
              <label
                htmlFor={`option${optionIndex}`}
                data-option={String.fromCharCode(65 + optionIndex)}
                dangerouslySetInnerHTML={{
                  __html: option,
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-evenly">
        <button
          className="btn mt-3 mb-4 theme-12009d-color question-btn"
          onClick={handleGoToPreviousQuestion}
          disabled={isFirstQuestion}
        >
          <FontAwesomeIcon icon={faChevronLeft} /> Previous Question
        </button>
        <button
          className="btn mt-3 mb-4 theme-12009d-color question-btn"
          onClick={handleNextQuestion}
          disabled={isLastQuestion}
        >
          Next Question <FontAwesomeIcon icon={faArrowRight} />{" "}
        </button>
        <button
          className="btn mt-3 mb-4 theme-12009d-color question-btn"
          onClick={handleToggleReview}
        >
          <FontAwesomeIcon
            icon={alreadySavedForReview ? faBookmark : faBookmarkRegular}
          />{" "}
          {alreadySavedForReview ? "Remove review" : "Review later"}
        </button>
      </div>
      {shouldShowReportModal && (
        <ReportAQuestionModal
          shouldShowReportModal={shouldShowReportModal}
          setShouldShowReportModal={setShouldShowReportModal}
          questionId={currentQuestionId}
          userUid={userUid}
          dBPath="AllReportedObjectiveQuestionsList"
        />
      )}
    </div>
  );
};

export default OnlineTestQuestionComponent;

OnlineTestQuestionComponent.propTypes = {
  currentQuestionId: PropTypes.string.isRequired,
  userUid: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  selectedOne: PropTypes.string,
  onOptionSelect: PropTypes.func.isRequired,
  isFirstQuestion: PropTypes.bool.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
  handleNextQuestion: PropTypes.func.isRequired,
  handleGoToPreviousQuestion: PropTypes.func.isRequired,
  handleToggleReview: PropTypes.func.isRequired,
  alreadySavedForReview: PropTypes.bool.isRequired,
};
