import PropTypes from "prop-types";
import React from "react";
import Lottie from "react-lottie";
import gradingAnimation from "../../../../Consumer/Assets/Images/LottieFiles/gradingAnimation.json";
import personCoffeAnimation from "../../../../Consumer/Assets/Images/LottieFiles/personCoffeAnimation.json";
import practiceMoreAnimation from "../../../../Consumer/Assets/Images/LottieFiles/practiceMoreAnimation.json";
import challengeCompletedAnimation from "../../../assets/LottieFiles/challengeCompletedAnimation.json";
import codeEvaluatingAnimation from "../../../assets/LottieFiles/codeEvaluatingAnimation.json";
import failureAnimation from "../../../assets/LottieFiles/failure.json";
import findingBotAnimation from "../../../assets/LottieFiles/findingBotAnimation.json";
import miniTimerDoneAlertAnimation from "../../../assets/LottieFiles/miniTimerDoneAlertAnimation.json";
import useIsTabletSize from "../../../customHooks/isTabletSizeHook";
import { interviewStates } from "../../InterviewBot/InterviewBotConstants/InterviewBotConstants";

export default function InterviewModalLottieComponent({
  showMiniTimer,
  currentInterviewState,
  doesRequireResume,
  isLoading,
}) {
  const isTabletSize = useIsTabletSize();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: showMiniTimer
      ? miniTimerDoneAlertAnimation
      : currentInterviewState == interviewStates.interviewNotStarted ||
        currentInterviewState == interviewStates.interviewSettingUp ||
        isLoading
      ? findingBotAnimation
      : currentInterviewState == interviewStates.interviewSettingDone
      ? personCoffeAnimation
      : currentInterviewState == interviewStates.dipAlreadySolved
      ? challengeCompletedAnimation
      : currentInterviewState == interviewStates.dipTimerOut
      ? practiceMoreAnimation
      : currentInterviewState == interviewStates.interviewPaused
      ? codeEvaluatingAnimation
      : currentInterviewState == interviewStates.interviewEnded
      ? challengeCompletedAnimation
      : currentInterviewState == interviewStates.interviewFailed
      ? failureAnimation
      : gradingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const isNotStarted =
    currentInterviewState === interviewStates.interviewNotStarted;
  const height = showMiniTimer
    ? "auto"
    : isNotStarted && doesRequireResume && isTabletSize
    ? 250
    : isNotStarted && doesRequireResume
    ? 125
    : isTabletSize
    ? 400
    : 200;

  const width = showMiniTimer
    ? isTabletSize
      ? 300
      : 150
    : isNotStarted && doesRequireResume && isTabletSize
    ? 250
    : isNotStarted && doesRequireResume
    ? 125
    : currentInterviewState === interviewStates.dipTimerOut
    ? "auto"
    : isTabletSize
    ? 400
    : 200;
  return <Lottie options={defaultOptions} height={height} width={width} />;
}
InterviewModalLottieComponent.propTypes = {
  showMiniTimer: PropTypes.bool.isRequired,
  currentInterviewState: PropTypes.string.isRequired,
  doesRequireResume: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
