import { Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import PropTypes from "prop-types";
import React from "react";
import micPermissionBlocked from "../../../assets/Images/svgs/micPermissionBlocked.svg";
import "./MicCamPermissionModal.css";

export default function MicCamPermissionModal({
  shouldShowNoMicModal,
  source,
}) {
  MicCamPermissionModal.propTypes = {
    shouldShowNoMicModal: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "80vh",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={shouldShowNoMicModal}
      backdrop="static"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={shouldShowNoMicModal}>
        <Box sx={modalStyle}>
          <div className="modal-content feedback-form-main">
            <div className="d-flex justify-content-between pb-1"></div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="w-100"
                  alt="Illustration of browser showing where to fix blocked permissions."
                  src={micPermissionBlocked}
                />
              </div>
              <div className="col-lg-6 d-flex align-items-center">
                <div>
                  <h1 className="mic-heading mb-4">
                    EvaluAItor is blocked from using your {source}
                  </h1>
                  <ol className="ps-2">
                    <li className="my-2 text-muted ps-0">
                      Click the{" "}
                      <span className="e4Ipmf">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height={25}
                          width={25}
                          viewBox="0 -960 960 960"
                        >
                          <path
                            fill="currentColor"
                            d="M700-130q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm-.235-60Q733-190 756.5-213.265q23.5-23.264 23.5-56.5Q780-303 756.735-326.5q-23.264-23.5-56.5-23.5Q667-350 643.5-326.735q-23.5 23.264-23.5 56.5Q620-237 643.265-213.5q23.264 23.5 56.5 23.5ZM120-240v-60h360v60H120Zm140-310q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm-.235-60Q293-610 316.5-633.265q23.5-23.264 23.5-56.5Q340-723 316.735-746.5q-23.264-23.5-56.5-23.5Q227-770 203.5-746.735q-23.5 23.264-23.5 56.5Q180-657 203.265-633.5q23.264 23.5 56.5 23.5ZM480-660v-60h360v60H480Z"
                          />
                        </svg>
                      </span>{" "}
                      page info icon in your browser&apos;s address bar
                    </li>
                    <li className="my-2 text-muted ps-0 fw-bold">
                      Turn on {source} to proceed further
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
