import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import * as React from "react";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { toast } from "react-toastify";
import RecordRTC from "recordrtc";
import { uploadBlobToStorage } from "../../utils/firebaseFunctions/firebaseStorageFunctions";
const ScreenRecorder = forwardRef(
  (
    {
      userUid,
      firestoreCollectionId,
      roundFirestoreID,
      recordAudio = false,
      recordScreen = false,
    },
    ref
  ) => {
    const recorderRef = useRef(null);
    const audioStreamRef = useRef(null);
    const screenStreamRef = useRef(null);
    ScreenRecorder.displayName = "ScreenRecorder";
    useImperativeHandle(ref, () => ({
      startRecording: handleStartRecording,
      stopRecording: handleStopRecording,
    }));
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleStartRecording = async () => {
      console.log("Dwaraka Siri recordAudio: ", recordAudio);
      console.log("Dwaraka Siri recordScreen: ", recordScreen);

      // Create a promise that resolves when the user selects screen or cancels
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // Request both audio and screen permissions simultaneously
          const [audioStr, screenStr] = await Promise.all([
            navigator.mediaDevices.getUserMedia({ audio: true }),
            navigator.mediaDevices.getDisplayMedia({ video: true }),
          ]);

          if (recordAudio) {
            audioStreamRef.current = audioStr;
          }

          let screenStream = null;
          if (recordScreen) {
            screenStreamRef.current = screenStr;
            screenStr.oninactive = () => {
              // If screen sharing is stopped by the user, reject the promise
              reject(new Error("Screen sharing cancelled"));
            };
            screenStream = screenStr;
          }

          // Construct the merged stream
          const audioTracks = recordAudio
            ? audioStreamRef.current.getTracks()
            : [];
          const screenTracks = recordScreen
            ? screenStreamRef.current.getTracks()
            : [];
          const allTracks = [...audioTracks, ...screenTracks];
          const mergedStream = new MediaStream(allTracks);

          // Start recording
          const recorder = RecordRTC(mergedStream, {
            type: "video",
            mimeType: "video/webm",
          });

          recorder.startRecording();
          recorderRef.current = recorder;

          // Resolve the promise
          resolve(screenStream !== null); // Resolves true if screenStream is not null (screen sharing started), false otherwise
        } catch (err) {
          if (err.message == "Permission denied by system") {
            setOpenDialog(true);
          } else {
            toast.error("Error sharing your screen", {
              toastId: "error_sharing_your_screen",
            });
            console.error(typeof err);
            console.error("Error accessing screen:", err);
          }

          reject(err); // Reject the promise if there's an error
        }
      });
    };

    const handleStopRecording = async () => {
      return new Promise((resolve, reject) => {
        if (recorderRef.current) {
          recorderRef.current.stopRecording(async () => {
            const blob = recorderRef.current.getBlob();
            if (recordAudio && audioStreamRef.current) {
              audioStreamRef.current
                .getTracks()
                .forEach((track) => track.stop());
            }
            if (recordScreen && screenStreamRef.current) {
              screenStreamRef.current
                .getTracks()
                .forEach((track) => track.stop());
            }

            try {
              const recordUrl = await uploadBlobToStorage({
                storagePath: `users/screenRecordings/${userUid}/${firestoreCollectionId}`,
                file: blob,
                fileName: `${roundFirestoreID}.webm`,
              });

              resolve(recordUrl);
            } catch (error) {
              reject(error);
            }
          });
        } else {
          reject(new Error("No recording in progress"));
        }
      });
    };

    return (
      <React.Fragment>
        <Dialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Alert variant="filled" severity="error">
              Can&apos;t share your screen
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This browser does not have screen-sharing permissions on your
              computer. Go to <strong>System Preferences</strong> and enable
              screen sharing to continue.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} autoFocus>
              Got it
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
);

export default ScreenRecorder;

ScreenRecorder.propTypes = {
  userUid: PropTypes.string.isRequired,
  firestoreCollectionId: PropTypes.string.isRequired,
  roundFirestoreID: PropTypes.string.isRequired,
  recordAudio: PropTypes.bool,
  recordScreen: PropTypes.bool,
};
