import {
  faCircleCheck,
  faCircleExclamation,
  faHourglassStart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Zoom } from "@mui/material";
import { deepPurple, green, orange } from "@mui/material/colors";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import { roundsToFirestoreCollectionIdMap } from "../../../Consumer/Assets/Constants";
import { didClearRoundWiseCutoff } from "../../../Consumer/Assets/JsonData/HiringCompanySpecificData";
import DIPHomeAnimation from "../../assets/LottieFiles/DIPHomeAnimation.json";
import challengeCompletedAnimation from "../../assets/LottieFiles/challengeCompletedAnimation.json";
import codeEvaluatingAnimation from "../../assets/LottieFiles/codeEvaluatingAnimation.json";
import findingBotAnimation from "../../assets/LottieFiles/findingBotAnimation.json";
import miniTimerDoneAlertAnimation from "../../assets/LottieFiles/miniTimerDoneAlertAnimation.json";
import resumeEvaluationSuccess from "../../assets/LottieFiles/resumeEvaluationSuccess.json";
import useIsTabletSize from "../../customHooks/isTabletSizeHook";
import useBeforeComponentUnload from "../../customHooks/useBeforeComponentUnload";
import useFullscreen from "../../customHooks/useFullscreen";
import { addDataAtL2CollectionFStore } from "../../utils/firebaseFunctions/firestoreFunctions";
import { convertToTitleCase } from "../../utils/stringExtensionFunctions";
import FullscreenControlButtons from "../Buttons/FullscreenControlButtons/FullscreenControlButtons";
import CameraComponent from "../CameraComponent/CameraComponent";
import { updateSeveralFirestoreVariables } from "../InterviewBot/InterviewBotCoreHelperFunctions";
import FullscreenExitModal from "../Modals/ExitFullscreenModal/ExitFullscreenModal";
import FeedbackFormModal from "../Modals/FeedbackFormModal/FeedbackFormModal";
import ReportForASingleOnlineTest from "../ReportComponents/OnlineTestReportComponent/OnlineTestReportComponent";
import ScreenRecorder from "../ScreenRecorder/ScreenRecorder";
import "./OnlineTestComponent.css";
import OnlineTestQuestionComponent from "./OnlineTestQuestionComponent/OnlineTestQuestionComponent";

const OnlineTestComponent = forwardRef(
  (
    {
      onlineTestQuestionsDataArray,
      setThisOnlineTestFirestoreIdForHiringProcess,
      setThisRoundReportForHiringProcess,
      shouldShowReportAtEnd = false,
      firestoreCollectionId = "online_test_submissions",
      firestoreChildCollectionId = "evaluAItor",
      onlineTestSlug,
      shouldVideoProctor = false,
      shouldRecordAudio = false,
      shouldRecordScreen = false,
      proctoringScreenshotGap = 10000,
    },
    ref
  ) => {
    OnlineTestComponent.displayName = "OnlineTestComponent";
    const isTabletSize = useIsTabletSize();
    const { user } = useSelector((state) => ({ ...state }));
    const {
      isFullscreen,
      enterFullscreen,
      exitFullscreen,
      showExitFullscreenAlertModal,
      setShowExitFullscreenAlertModal,
      handleConfirmExit,
    } = useFullscreen();
    const [sets, setSets] = useState(() =>
      onlineTestQuestionsDataArray?.roundsDataArray?.map((set, index) => ({
        currentQuestionId: Object.keys(set.questionsData)[0],
        selectedOptions: {},
        questionsForReview: [],
        correctlyAnswered: [],
        incorrectlyAnswered: [],
        unattemptedQuestions: [],
        isThisRoundTimerRunning: false,
        isThisRoundStarted: false,
        isThisRoundEnded: false,
        testScore: 0,
        setIndex: index,
        roundTitle: set.roundTitle,
      }))
    );
    const [currentSetIndex, setCurrentSetIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isTestDone, setIsTestDone] = useState(false);
    const [showSubmitRoundAlertModal, setShowSubmitRoundAlertModal] =
      useState(false);
    const [showEndTestAlertModal, setShowEndTestAlertModal] = useState(false);
    const [testAlertMsg, setTestAlertMsg] = useState("");
    const [onlineTestFirestoreId, setOnlineTestFirestoreId] = useState("");
    const [reportsDataForAllRounds, setReportsDataForAllRounds] = useState();
    const [shouldShowFeedbackModal, setShouldShowFeedbackModal] =
      useState(false);
    const miniTimerDuration = 5;
    const [startTime, setStartTime] = useState(new Date());
    const [showMiniTimer, setShowMiniTimer] = useState(false);
    const recorderRef = useRef();

    const [
      isOnlineRoundSettingUpInProgress,
      setIsOnlineRoundSettingUpInProgress,
    ] = useState(false);
    const [proctoringData, setProctoringData] = useState([]);
    useBeforeComponentUnload(!reportsDataForAllRounds, () => {
      // Additional logic to execute beforeunload if needed
    });
    const miniTimerDone = () => {
      startTest();
      setShowMiniTimer(false);
    };
    const currentSet = sets[currentSetIndex];

    const {
      currentQuestionId,
      selectedOptions,
      questionsForReview,
      isThisRoundTimerRunning,
      isThisRoundStarted,
      isThisRoundEnded,
    } = currentSet;

    const defaultDIPLottieOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      animationData: isTestDone
        ? challengeCompletedAnimation
        : isLoading
        ? codeEvaluatingAnimation
        : isOnlineRoundSettingUpInProgress
        ? findingBotAnimation
        : showMiniTimer
        ? miniTimerDoneAlertAnimation
        : !isThisRoundEnded
        ? DIPHomeAnimation
        : !shouldShowReportAtEnd
        ? codeEvaluatingAnimation
        : resumeEvaluationSuccess,
    };

    const getTotalTime = () => {
      var time = 0;
      if (onlineTestQuestionsDataArray.isASingleTest) {
        onlineTestQuestionsDataArray.roundsDataArray.map((set) => {
          time = time + set.timeForThisRound;
        });
      } else {
        time =
          onlineTestQuestionsDataArray.roundsDataArray[currentSetIndex]
            .timeForThisRound;
      }
      console.log("Dwaraka time: ", time);
      return time;
    };

    const timerTimeRef = useRef(getTotalTime());
    let timer;

    useEffect(() => {
      const updateTimer = () => {
        if (isThisRoundTimerRunning && timerTimeRef.current > 0) {
          timerTimeRef.current = Math.max(timerTimeRef.current - 1, 0);
        }

        if (isThisRoundTimerRunning && timerTimeRef.current === 0) {
          if (onlineTestQuestionsDataArray.isASingleTest) {
            endTest();
          } else {
            endThisRound();
          }
        }
      };

      timer = setInterval(updateTimer, 1000);

      return () => {
        clearInterval(timer);
      };
    }, [isThisRoundTimerRunning]);

    const goToSet = (setIndex) => {
      setCurrentSetIndex(setIndex);
    };

    const startTest = async () => {
      if (currentSetIndex === 0) {
        setStartTime(new Date());
        setIsOnlineRoundSettingUpInProgress(true);
        if (onlineTestSlug) {
          setOnlineTestFirestoreId(onlineTestSlug);
        } else {
          const thisFirestoreID = await addDataAtL2CollectionFStore({
            data: {
              testForCompany: onlineTestQuestionsDataArray.testForCompany,
              allRoundsReportData: {},
              isThisEntirelyDone: false,
            },
            parent_collection: firestoreCollectionId,
            parent_document: user.uid,
            child_collection: firestoreChildCollectionId,
          });
          if (setThisOnlineTestFirestoreIdForHiringProcess) {
            setThisOnlineTestFirestoreIdForHiringProcess({
              [firestoreCollectionId]: thisFirestoreID,
            });
          }
          setOnlineTestFirestoreId(thisFirestoreID);
        }
        setIsOnlineRoundSettingUpInProgress(false);
      }
      setSets((prevSets) =>
        prevSets.map((prevSet, index) =>
          onlineTestQuestionsDataArray.isASingleTest
            ? {
                ...prevSet,
                isThisRoundTimerRunning: true,
                isThisRoundStarted: true,
              }
            : index === currentSetIndex
            ? {
                ...prevSet,
                isThisRoundTimerRunning: true,
                isThisRoundStarted: true,
              }
            : prevSet
        )
      );
      enterFullscreen();
    };

    useImperativeHandle(ref, () => ({
      startTest,
    }));

    const handleOptionSelect = (option) => {
      setSets((prevSets) =>
        prevSets.map((prevSet, index) =>
          index === currentSetIndex
            ? {
                ...prevSet,
                selectedOptions: {
                  ...prevSet.selectedOptions,
                  [currentQuestionId]: option,
                },
              }
            : prevSet
        )
      );
    };

    const handleNextQuestion = () => {
      const questionIds = Object.keys(
        onlineTestQuestionsDataArray.roundsDataArray[currentSetIndex]
          .questionsData
      );
      const currentIndex = questionIds.indexOf(currentQuestionId);
      if (currentIndex < questionIds.length - 1 && currentIndex !== -1) {
        const nextQuestionId = questionIds[currentIndex + 1];
        setSets((prevSets) =>
          prevSets.map((prevSet, index) =>
            index === currentSetIndex
              ? { ...prevSet, currentQuestionId: nextQuestionId }
              : prevSet
          )
        );
      }
    };

    const handleGoToPreviousQuestion = () => {
      const questionIds = Object.keys(
        onlineTestQuestionsDataArray.roundsDataArray[currentSetIndex]
          .questionsData
      );
      const currentIndex = questionIds.indexOf(currentQuestionId);
      if (currentIndex > 0 && currentIndex !== -1) {
        const nextQuestionId = questionIds[currentIndex - 1];
        setSets((prevSets) =>
          prevSets.map((prevSet, index) =>
            index === currentSetIndex
              ? { ...prevSet, currentQuestionId: nextQuestionId }
              : prevSet
          )
        );
      }
    };

    const handleToggleReview = () => {
      setSets((prevSets) =>
        prevSets.map((prevSet, index) =>
          index === currentSetIndex
            ? {
                ...prevSet,
                questionsForReview: prevSet.questionsForReview.includes(
                  currentQuestionId
                )
                  ? prevSet.questionsForReview.filter(
                      (id) => id !== currentQuestionId
                    )
                  : [...prevSet.questionsForReview, currentQuestionId],
              }
            : prevSet
        )
      );
    };

    const userRoundSubmit = () => {
      var alertMsg =
        "Are you sure to submit this round? You can't revisit this round once submitted";
      if (questionsForReview.length > 0) {
        alertMsg =
          "You have questions saved for Review later. Are you sure to submit this round? You can't revisit this round once submitted";
      }
      setTestAlertMsg(alertMsg);
      setShowSubmitRoundAlertModal(true);
    };

    const userTestSubmit = () => {
      var alertMsg = "Are you sure you want to submit the test?";
      if (questionsForReview.length > 0) {
        alertMsg =
          "You have questions saved for Review later. Are you sure you want to submit the test?";
      }
      setTestAlertMsg(alertMsg);
      setShowEndTestAlertModal(true);
    };

    const confirmAlertModal = () => {
      if (showSubmitRoundAlertModal) {
        endThisRound();
        setShowSubmitRoundAlertModal(false);
      } else {
        endTest();
        setShowEndTestAlertModal(false);
      }
    };

    const endThisRound = () => {
      const nextRoundIndex = currentSetIndex + 1;
      if (
        nextRoundIndex < onlineTestQuestionsDataArray.roundsDataArray.length
      ) {
        setCurrentSetIndex(nextRoundIndex);
        timerTimeRef.current =
          onlineTestQuestionsDataArray.roundsDataArray[
            nextRoundIndex
          ].timeForThisRound;
        setShowMiniTimer(true);
      } else {
        endTest();
      }
    };

    const endTest = async () => {
      handleTestSubmit();
      clearInterval(timer);
      if (shouldShowReportAtEnd) {
        setShouldShowFeedbackModal(true);
      }
    };

    const handleTestSubmit = async () => {
      console.log(":DWaraka Siri setIsLoading(true)");
      setIsLoading(true);
      let totalCorrectAnswers = [];
      let totalIncorrectAnswers = [];
      let totalUnattempted = [];
      // eslint-disable-next-line no-unused-vars
      let totalScore = 0;
      // eslint-disable-next-line no-unused-vars
      let totalNumberOfQuestions = 0;
      let testForCompany = onlineTestQuestionsDataArray.testForCompany;
      let allRoundsReportData = [];
      let recordedUrl = await recorderRef?.current?.stopRecording();
      console.log("DWaraka Siri recordedUrl: ", recordedUrl);
      onlineTestQuestionsDataArray.roundsDataArray.forEach(
        (round, roundIndex) => {
          let correctAnswers = [];
          let incorrectAnswers = [];
          let unattempted = [];
          let score = 0;

          const questionIds = Object.keys(round.questionsData);
          let roundQuestionDataForReport = {};

          questionIds.forEach((questionId) => {
            const selectedOption = sets[roundIndex].selectedOptions[questionId];
            const currentQuestion = round.questionsData[questionId];
            totalNumberOfQuestions += 1;

            roundQuestionDataForReport[questionId] = selectedOption || "NA";

            if (selectedOption === currentQuestion.correctAnswerIndex) {
              correctAnswers.push(questionId);
              score += 1;
            } else if (selectedOption === undefined) {
              unattempted.push(questionId);
            } else {
              incorrectAnswers.push(questionId);
            }
          });

          totalCorrectAnswers = totalCorrectAnswers.concat(correctAnswers);
          totalIncorrectAnswers =
            totalIncorrectAnswers.concat(incorrectAnswers);
          totalUnattempted = totalUnattempted.concat(unattempted);
          totalScore += score;
          const isQualifiedInThisRound = didClearRoundWiseCutoff(
            round.roundTitle,
            Object.keys(roundQuestionDataForReport).length,
            score
          );
          allRoundsReportData.push({
            roundTitle: round.roundTitle,
            roundQuestions: roundQuestionDataForReport,
            roundScore: score,
            unattemptedQuestions: unattempted.length,
            incorrectQuestions: incorrectAnswers.length,
            isQualified: isQualifiedInThisRound,
          });
          setSets((prevSets) =>
            prevSets.map((prevSet, index) =>
              index === roundIndex
                ? {
                    ...prevSet,
                    correctlyAnswered: correctAnswers,
                    incorrectlyAnswered: incorrectAnswers,
                    unattemptedQuestions: unattempted,
                    testScore: score,
                    isThisRoundTimerRunning: false,
                    isThisRoundEnded: true,
                    roundTitle: round.roundTitle,
                    questionsData: round.questionsData,
                  }
                : prevSet
            )
          );
        }
      );
      console.log("DWaraka allRoundsReportData: ", allRoundsReportData);
      let endTime = new Date();
      setReportsDataForAllRounds({
        allRoundsReportData: allRoundsReportData,
        timestamp: new Date(),
        testForCompany: testForCompany,
        isThisEntirelyDone: true,
        totalCorrectAnswers: totalCorrectAnswers.length,
        totalIncorrectAnswers: totalIncorrectAnswers.length,
        totalUnattempted: totalUnattempted.length,
        proctoringData: proctoringData,
        overAllScorePercentage: (totalScore / totalNumberOfQuestions) * 100,
        totalTimeTaken: (endTime - startTime) / 1000,
        recordedUrl: recordedUrl,
      });

      const variablesToUpdate = [
        {
          variableToUpdate: "allRoundsReportData",
          updatedValue: allRoundsReportData,
        },
        {
          variableToUpdate: "isThisEntirelyDone",
          updatedValue: true,
        },
        {
          variableToUpdate: "totalCorrectAnswers",
          updatedValue: totalCorrectAnswers.length,
        },
        {
          variableToUpdate: "totalIncorrectAnswers",
          updatedValue: totalIncorrectAnswers.length,
        },
        {
          variableToUpdate: "totalUnattempted",
          updatedValue: totalUnattempted.length,
        },
        {
          variableToUpdate: "proctoringData",
          updatedValue: proctoringData,
        },
        {
          variableToUpdate: "recordedUrl",
          updatedValue: recordedUrl || "",
        },
        {
          variableToUpdate: "totalTimeTaken",
          updatedValue: (endTime - startTime) / 1000,
        },
        {
          variableToUpdate: "overAllScorePercentage",
          updatedValue: (totalScore / totalNumberOfQuestions) * 100,
        },
      ];

      await updateSeveralFirestoreVariables({
        firestoreCollectionId: firestoreCollectionId,
        firestoreChildCollectionId: firestoreChildCollectionId,
        firestoreDocumentId: user.uid,
        interviewFirestoreID: onlineTestFirestoreId,
        variablesToUpdate: variablesToUpdate,
      });
      if (setThisRoundReportForHiringProcess) {
        setThisRoundReportForHiringProcess({
          firestoreId: onlineTestFirestoreId,
          reportData: {
            allRoundsReportData: allRoundsReportData,
            timestamp: new Date(),
            testForCompany: testForCompany,
            proctoringData: proctoringData,
            isThisEntirelyDone: true,
            totalCorrectAnswers: totalCorrectAnswers.length,
            totalIncorrectAnswers: totalIncorrectAnswers.length,
            totalUnattempted: totalUnattempted.length,
            recordedUrl: recordedUrl || "",
            totalTimeTaken: (endTime - startTime) / 1000,
            overAllScorePercentage: (totalScore / totalNumberOfQuestions) * 100,
          },
        });
      }
      setIsLoading(false);
      setIsTestDone(true);
    };

    const timerParagraphRef = useRef();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      const updateParagraph = () => {
        if (timerParagraphRef.current) {
          timerParagraphRef.current.textContent = `Time Left: ${Math.floor(
            timerTimeRef.current / 60
          )}:${
            timerTimeRef.current % 60 < 10
              ? `0${timerTimeRef.current % 60}`
              : timerTimeRef.current % 60
          }`;
          requestAnimationFrame(updateParagraph);
        }
      };
      updateParagraph(); // Start the update loop
      return () => {
        cancelAnimationFrame(updateParagraph);
      };
    }, [isThisRoundTimerRunning]);
    return (
      <div className="container online-test-container">
        {(shouldRecordAudio || shouldRecordScreen) && (
          <ScreenRecorder
            ref={recorderRef}
            userUid={user.uid}
            firestoreCollectionId={firestoreCollectionId}
            roundFirestoreID={onlineTestFirestoreId}
            recordAudio={shouldRecordAudio}
            recordScreen={shouldRecordScreen}
          />
        )}
        {!isThisRoundStarted || isLoading ? (
          <div className="text-center online-text-lottie-container">
            <h4>
              {
                roundsToFirestoreCollectionIdMap[
                  onlineTestQuestionsDataArray.roundsDataArray[currentSetIndex]
                    .roundTitle
                ]
              }{" "}
              round
            </h4>
            {onlineTestFirestoreId && (
              <p className="bg-light user-name-text text-center p-1 border mt-1 rounded theme-12009d-color">
                <strong>Test ID:</strong> {onlineTestFirestoreId}
              </p>
            )}
            <div className="row justify-content-center align-items-center w-auto p-events-none my-3">
              {showMiniTimer && (
                <div className="d-flex align-items-center w-auto">
                  <CountdownCircleTimer
                    isPlaying={showMiniTimer}
                    duration={miniTimerDuration}
                    colors={["#12009d", "#F7B801", "#A30000", "#A30000"]}
                    colorsTime={[
                      miniTimerDuration / 2,
                      miniTimerDuration / 4,
                      miniTimerDuration / 8,
                      0,
                    ]}
                    onComplete={() => {
                      miniTimerDone();
                    }}
                    strokeWidth={10}
                    size={150}
                  >
                    {({ remainingTime }) => (
                      <div className="text-center">
                        <p className="mb-1">Starts in...</p>
                        <h1 className="d-inline">{remainingTime}</h1>{" "}
                        <small>sec</small>
                      </div>
                    )}
                  </CountdownCircleTimer>
                </div>
              )}
              {isLoading && (
                <div className="text-center loading-indication-text">
                  <h5 className="mb-1">EvaluAIting your performance</h5>
                  <h6 className="d-inline">Please wait....</h6>
                </div>
              )}
              <Lottie
                options={defaultDIPLottieOptions}
                height="auto"
                margin="0"
                width={
                  isOnlineRoundSettingUpInProgress || isLoading
                    ? "40vw"
                    : showMiniTimer
                    ? "400px"
                    : isTabletSize
                    ? "50vw"
                    : "80vw"
                }
              />
            </div>
            {currentSetIndex === 0 &&
              !isOnlineRoundSettingUpInProgress &&
              !isLoading && (
                <button
                  className="resume-evaluaitor-btn px-4 py-2"
                  onClick={async () => {
                    if (shouldRecordAudio || shouldRecordScreen) {
                      recorderRef.current
                        .startRecording()
                        .then((screenShared) => {
                          if (screenShared) {
                            // Screen sharing started
                            console.log("User has selected a screen to share.");
                            startTest();
                          } else {
                            // Screen sharing cancelled
                            console.log("User has cancelled screen sharing.");
                          }
                        })
                        .catch((error) => {
                          console.error("Error:", error);
                        });
                    } else {
                      startTest();
                    }
                  }}
                >
                  Start test
                </button>
              )}
          </div>
        ) : (
          <>
            {!isThisRoundEnded ? (
              <>
                <div className="text-muted text-center mb-1 d-flex justify-content-evenly w-75">
                  {sets.map((set, index) => (
                    <div key={index}>
                      <button
                        key={set.setIndex}
                        disabled={
                          !onlineTestQuestionsDataArray.isASingleTest &&
                          set.setIndex != currentSetIndex
                        }
                        className={`btn online-test-rounds-btn resume-evaluaitor-btn rounded mx-2 w-100 ${
                          set.setIndex === currentSetIndex ? "active" : ""
                        } ${
                          !onlineTestQuestionsDataArray.isASingleTest &&
                          set.setIndex < currentSetIndex
                            ? "complete"
                            : ""
                        }`}
                        onClick={() => goToSet(set.setIndex)}
                      >
                        {roundsToFirestoreCollectionIdMap[set.roundTitle]}
                      </button>
                    </div>
                  ))}
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: "1" }}>
                    <div>
                      <OnlineTestQuestionComponent
                        currentQuestionId={currentQuestionId}
                        userUid={user.uid}
                        index={Object.keys(
                          onlineTestQuestionsDataArray.roundsDataArray[
                            currentSetIndex
                          ].questionsData
                        ).indexOf(currentQuestionId)}
                        question={
                          onlineTestQuestionsDataArray.roundsDataArray[
                            currentSetIndex
                          ].questionsData[currentQuestionId].question
                        }
                        options={
                          onlineTestQuestionsDataArray.roundsDataArray[
                            currentSetIndex
                          ].questionsData[currentQuestionId].options
                        }
                        selectedOne={
                          selectedOptions[currentQuestionId] !== undefined
                            ? selectedOptions[currentQuestionId]
                            : null
                        }
                        onOptionSelect={handleOptionSelect}
                        isFirstQuestion={
                          currentQuestionId ===
                          Object.keys(
                            onlineTestQuestionsDataArray.roundsDataArray[
                              currentSetIndex
                            ].questionsData
                          )[0]
                        }
                        isLastQuestion={
                          currentQuestionId ===
                          Object.keys(
                            onlineTestQuestionsDataArray.roundsDataArray[
                              currentSetIndex
                            ].questionsData
                          ).slice(-1)[0]
                        }
                        handleNextQuestion={handleNextQuestion}
                        handleGoToPreviousQuestion={handleGoToPreviousQuestion}
                        handleToggleReview={handleToggleReview}
                        alreadySavedForReview={questionsForReview.includes(
                          currentQuestionId
                        )}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <button
                        className="btn resume-evaluaitor-btn"
                        onClick={userTestSubmit}
                      >
                        Submit Test
                      </button>
                      {currentSetIndex <
                        onlineTestQuestionsDataArray.roundsDataArray.length -
                          1 &&
                        !onlineTestQuestionsDataArray.isASingleTest && (
                          <button
                            className="btn resume-evaluaitor-btn"
                            onClick={userRoundSubmit}
                          >
                            Submit this round
                          </button>
                        )}
                    </div>
                  </div>
                  <div className="mx-auto align-items-center d-flex p-2 flex-column justify-content-between">
                    <div>
                      <div className="online-test-cam border theme-12009d-border-color rounded">
                        <CameraComponent
                          userAvatar={user.profilepic}
                          proctoringData={proctoringData}
                          firestoreCollectionId={firestoreCollectionId}
                          interviewFirestoreID={onlineTestFirestoreId}
                          setProctoringData={setProctoringData}
                          shouldVideoProctor={shouldVideoProctor}
                          proctoringScreenshotGap={proctoringScreenshotGap}
                          userUid={user.uid}
                        />
                        <p className="bg-light w-100 text-center p-1 pb-0 mb-0 theme-12009d-color rounded">
                          {convertToTitleCase(user.displayName)}
                        </p>
                      </div>
                      <p className="bg-light w-100 text-center p-1 pb-0 mb-0 mt-1 theme-12009d-color border rounded">
                        <strong>Test ID: </strong>
                        {onlineTestFirestoreId}
                      </p>
                    </div>
                    <div className="w-100 text-center p-1">
                      <h4
                        ref={timerParagraphRef}
                        className="theme-12009d-color border-bottom"
                      ></h4>
                      <div className="mb-2 w-100 pt-2">
                        <div className="align-items-center my-1 d-flex justify-content-evenly">
                          <Tooltip
                            title={
                              <>
                                Answered
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  className="px-1 "
                                />
                              </>
                            }
                            placement="bottom"
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: green[500],
                                  "& .MuiTooltip-arrow": {
                                    color: green[500],
                                  },
                                  color: "white",
                                },
                              },
                            }}
                            TransitionComponent={Zoom}
                          >
                            <span
                              className=" m-0 me-1 theme-12009d-color px-2 help-cursor border rounded theme-12009d-border-color"
                              style={{ backgroundColor: "#2ed6c4" }}
                            >
                              Q
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={
                              <>
                                Marked for review
                                <FontAwesomeIcon
                                  icon={faCircleExclamation}
                                  className="px-1 "
                                />
                              </>
                            }
                            placement="bottom"
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: orange[500],
                                  "& .MuiTooltip-arrow": {
                                    color: orange[500],
                                  },
                                  color: "white",
                                },
                              },
                            }}
                            TransitionComponent={Zoom}
                          >
                            <span className=" m-0 me-1 bg-warning theme-12009d-color px-2 help-cursor  border rounded theme-12009d-border-color">
                              Q
                            </span>{" "}
                          </Tooltip>
                          <Tooltip
                            title={
                              <>
                                Unattempted
                                <FontAwesomeIcon
                                  icon={faHourglassStart}
                                  className="px-1 "
                                />
                              </>
                            }
                            placement="bottom"
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: deepPurple[50],
                                  "& .MuiTooltip-arrow": {
                                    color: deepPurple[50],
                                  },
                                  color: "#12009d",
                                },
                              },
                            }}
                            TransitionComponent={Zoom}
                          >
                            <span className=" m-0 me-1 theme-12009d-color px-2 help-cursor  border rounded theme-12009d-border-color">
                              Q
                            </span>{" "}
                          </Tooltip>
                        </div>
                      </div>
                      <h6 className="text-muted">Question Palette</h6>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(5, 1fr)",
                          gap: "10px",
                        }}
                      >
                        {Object.keys(
                          onlineTestQuestionsDataArray.roundsDataArray[
                            currentSetIndex
                          ].questionsData
                        ).map((questionId, index) => (
                          <div
                            key={index}
                            style={{
                              cursor: "pointer",
                              border:
                                questionId === currentQuestionId
                                  ? "3px solid #12009d"
                                  : "1px solid #000",
                              background: questionsForReview.includes(
                                questionId
                              )
                                ? "#f0ad4e"
                                : selectedOptions[questionId]
                                ? "#2ed6c4"
                                : "#fff",
                              color: "#12009d",
                              borderRadius: "5px",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setSets((prevSets) =>
                                prevSets.map((prevSet, index) =>
                                  index === currentSetIndex
                                    ? {
                                        ...prevSet,
                                        currentQuestionId: questionId,
                                      }
                                    : prevSet
                                )
                              );
                            }}
                          >
                            {index + 1}
                          </div>
                        ))}
                      </div>
                    </div>
                    <span className="mt-5">
                      <FullscreenControlButtons
                        isFullscreen={isFullscreen}
                        enterFullscreen={enterFullscreen}
                        exitFullscreen={exitFullscreen}
                      />
                    </span>
                  </div>
                </div>
              </>
            ) : shouldShowReportAtEnd ? (
              <ReportForASingleOnlineTest
                onlineTestRecord={reportsDataForAllRounds}
                onlineTestId={onlineTestFirestoreId}
                showShare={false}
                showBackBtn={true}
              />
            ) : (
              <div className="text-center">
                <div className="p-events-none m-auto mt-4">
                  {isTestDone ? (
                    <strong>
                      You have completed the test. <br />
                      Thank you for your time. Have a nice day ahead.
                    </strong>
                  ) : (
                    <>
                      <h3>Evaluating your performance...</h3>
                      <p>It will take few moments...</p>
                    </>
                  )}
                  <Lottie
                    options={defaultDIPLottieOptions}
                    height="auto"
                    width={
                      isTestDone
                        ? "40vw"
                        : showMiniTimer
                        ? "300"
                        : isThisRoundEnded
                        ? "30vw"
                        : isTabletSize
                        ? "50vw"
                        : "90vw"
                    }
                  />
                </div>
              </div>
            )}
            <FeedbackFormModal
              shouldShowFeedbackModal={shouldShowFeedbackModal}
              setShouldShowFeedbackModal={setShouldShowFeedbackModal}
              roundType="Objective_test"
              userUid={user.uid}
            />
            <FullscreenExitModal
              show={showExitFullscreenAlertModal}
              onReject={() => setShowExitFullscreenAlertModal(false)}
              onConfirm={handleConfirmExit}
              text={"It is recommended to not exit fullscreen during the test."}
              heading={"Exit Fullscreen?"}
              confirmBtnText={"Exit"}
              rejectBtntext={"Cancel"}
            />
            <FullscreenExitModal
              show={showSubmitRoundAlertModal || showEndTestAlertModal}
              onReject={() => {
                setShowSubmitRoundAlertModal(false);
                setShowEndTestAlertModal(false);
              }}
              onConfirm={confirmAlertModal}
              text={testAlertMsg}
              heading={"Are you sure?"}
              confirmBtnText={"Submit"}
              rejectBtntext={"Cancel"}
            />
          </>
        )}
      </div>
    );
  }
);

export default OnlineTestComponent;

OnlineTestComponent.propTypes = {
  onlineTestQuestionsDataArray: PropTypes.object,
  setThisOnlineTestFirestoreIdForHiringProcess: PropTypes.func,
  setThisRoundReportForHiringProcess: PropTypes.func,
  shouldShowReportAtEnd: PropTypes.bool,
  firestoreCollectionId: PropTypes.string,
  firestoreChildCollectionId: PropTypes.string,
  onlineTestSlug: PropTypes.string,
  shouldVideoProctor: PropTypes.bool,
  shouldRecordAudio: PropTypes.bool,
  shouldRecordScreen: PropTypes.bool,
  proctoringScreenshotGap: PropTypes.number,
};
