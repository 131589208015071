import { getAnalytics, logEvent } from "@firebase/analytics";
import { child, get, ref as ref1, update } from "firebase/database";
import { db } from "../../../firebase";
const analytics = getAnalytics();

const logAnalyticsClickEvent = ({ api_call_type, error_msg }) => {
  logEvent(analytics, "firebase_failure", {
    api_call_type: api_call_type,
    error_message: error_msg,
  });
};

export async function increaseSubscriptionInterviews(
  path,
  variableToChange,
  incrementBy,
  userId
) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (!userId) {
        reject(new Error("User ID is required to edit data."));
        return;
      }
      const keyRef = child(ref1(db, path), variableToChange);
      const snapshot = await get(keyRef);
      const variablePathObject = {};
      if (snapshot.exists()) {
        variablePathObject[variableToChange] = snapshot.val() + incrementBy;
        await update(ref1(db, path), variablePathObject);
      } else {
        variablePathObject[variableToChange] = incrementBy;
        await update(ref1(db, path), variablePathObject);
      }
      resolve(userId);
    } catch (error) {
      console.error("Error editing value:", error);
      logAnalyticsClickEvent({
        api_call_type: `${path} _ ${variableToChange}`,
        error_msg: error.message,
      });
    }
  });
}
